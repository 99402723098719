import React, { useContext, useEffect, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Button from "../../../components/bootstrap/Button";
import { useLocation, useNavigate } from "react-router-dom";
import ViewHardwareSpecific from "../hardware_specific/ViewHardwareSpecific";
import { Localstorage, authToken, getDataUser } from "../../../core/auth.service";
import { CONSTANT } from "../../../core/static-constant";
import { ROUTING_CONSTANT } from "../../../core/constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import { useFormik } from "formik";
import { UserHardwareSpecificService } from "../../../services/hardwareSpecific.service";
import { toast } from "react-toastify";
import Loader from "../../Loader/loader";
import DataContext from "../../../contexts/dataContext";
import Select from "react-select";

const HardwareSpecific = () => {
    const { userDevicesData, setUserDevicesData }: any = useContext(DataContext);
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = React.useState<number>(5);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [hardwareSpecific, setHardwareSpecificData] = useState([]);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { state } = useLocation();
    const UserEmail = state?.userEmail;
    const [selectedDeviceName, setSelectedDeviceName] = useState("");
    const [selectedDeviceId, setSelectedDeviceId] =
        useState("");
    const HardwareUserId = Localstorage(CONSTANT.GET, CONSTANT.HARDWARE_SPECIFIC_USER_ID);

    const navigate = useNavigate();
    const decodedJwt = authToken();
    const dataUsers = getDataUser();

    useEffect(() => {
        setUserDevicesData([]);
        getAllListOfDevice();
        return () => {
            setUserDevicesData([]);
        };
    }, [HardwareUserId, setUserDevicesData])

    const handleChangePage = (newPage: any) => {
        let obj = {
            limit: perPage,
            pageCount: newPage,
            userId: HardwareUserId,
            hardwareType: selectedDeviceName,
            hardwareDate: values.hardwareDate
        };
        getAllUserHardwareSpecificData(obj);
        setCurrentPage(parseInt(newPage));
    };

    const handleChangeRowsPerPage = async (event: any) => {
        let obj = {
            limit: parseInt(event),
            pageCount: 1,
            userId: HardwareUserId,
            hardwareType: selectedDeviceName,
            hardwareDate: values.hardwareDate
        };
        await setCurrentPage(1);
        await setPerPage(parseInt(event));
        await getAllUserHardwareSpecificData(obj);
    };

    useEffect(() => {
        if (!isInitialized) {
            setIsInitialized(true);
        } else {
            let obj = {
                userId: HardwareUserId,
                limit: perPage,
                pageCount: currentPage,
            };
            getAllUserHardwareSpecificData(obj);
        }
    }, [isInitialized]);


    const getAllUserHardwareSpecificData = async (obj: any) => {
        try {
            setIsLoading(true);
            let data: any = await UserHardwareSpecificService.getAllUserHardwareSpecificData(obj);
            setIsLoading(false);
            if (data && data?.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.hardwareSpecificDetails;
                setHardwareSpecificData(responseData);
                setTotalRecords(data?.data?.totalRecords);
            } else {
                if (
                    data.message === CONSTANT.AUTHENTICATION_FAIL ||
                    data.statusCode === 403
                ) {
                    navigate("/auth-pages/login");
                    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN);
                }
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setHardwareSpecificData([]);
            setTotalRecords(0);
            setIsLoading(false);
        }
    };

    const getAllListOfDevice = async () => {
        try {
            let data: any = await UserHardwareSpecificService.getAllListOfDevice();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.device;
                setUserDevicesData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error) {
            // toast.error(CONSTANT.ERROR_OCCURRED);
        }
    };

    const { values, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: {
            hardwareType: "",
            hardwareDate: "",
            pdfId: ""
        },
        onSubmit: async (values, newPage: any) => {
            try {
                let obj = {
                    limit: perPage,
                    pageCount: 1,
                    userId: HardwareUserId,
                    hardwareType: selectedDeviceName,
                    hardwareDate: values.hardwareDate,
                    pdfId: values.pdfId
                };

                await getAllUserHardwareSpecificData(obj);
                setCurrentPage(1);
            } catch (error: any) {
                toast.error(error.response.data.error);
            }

        },
    });
    useEffect(() => {
        if (selectedDeviceName || values.hardwareDate || values.pdfId) {
            setIsSearchDisabled(false);
        } else {
            setIsSearchDisabled(true);
        }
    }, [selectedDeviceName, values.hardwareDate, values.pdfId]);

    const handleClearFilter = () => {
        setFieldValue("hardwareType", "");
        setFieldValue("hardwareDate", "");
        setFieldValue("pdfId", "");
        setSelectedDeviceName("");
        handleSubmit();
    }

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <PageWrapper>
            <Page container="fluid">
                {isLoading && (
                    <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                        <Loader />
                    </div>
                )}
                <h3 className="text-center mt-3 mb-5 text-primary">
                    <strong>Hardware Data</strong>
                </h3>
                <p className="fw-bolder">
                    UserEmail: {UserEmail}
                </p>
                <div className="row">
                    <div className="d-flex justify-content-center align-items-center col-sm-6 mt-4 col-md-6 col-lg-3 xxl:w-[300px] mb-2">
                        <Input
                            id="trainer"
                            className="order input-form"
                            placeholder="Search PDF by PdfId"
                            name="pdfId"
                            type='text'
                            value={values.pdfId}
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-sm-3 col-md-3 mt-4 mb-2">
                        <FormGroup id="categoryName">
                            <Select
                                styles={customStyles}
                                onChange={(selectedOption) => {
                                    setSelectedDeviceId(
                                        selectedOption ? selectedOption.value : ""
                                    );
                                    setSelectedDeviceName(
                                        selectedOption ? selectedOption.label : ""
                                    );
                                }}
                                options={
                                    userDevicesData &&
                                    userDevicesData.map((devices: any) => ({
                                        value: devices._id,
                                        label: devices.deviceName,
                                    }))
                                }
                                placeholder="Select Hardware Device"
                                value={
                                    selectedDeviceName
                                        ? {
                                            value: selectedDeviceId,
                                            label: selectedDeviceName,
                                        }
                                        : null
                                }
                                className={`${"border border rounded-circle"
                                    }`}
                                isSearchable={true}
                                isClearable={true}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-sm-6 mt-4 col-md-6 col-lg-3 mb-2">
                        <FormGroup label="Enter Date" isFloating>
                            <Input
                                className="orderinput-form form-selet-lg"
                                placeholder="Enter Hardware Date"
                                name="hardwareDate"
                                type='date'
                                value={values.hardwareDate}
                                onChange={handleChange}
                            />
                        </FormGroup>
                    </div>

                    <div className="col-sm-6 mt-4 col-md-6 col-lg-3 d-flex justify-content-between gap-4 mx-auto align-items-center">
                        <div className="col-sm-6 col-md-6 col-lg-3 d-flex gap-4 align-items-center">
                            <div>
                                <Button
                                    type="button"
                                    className="btn btn-info mt-2"
                                    onClick={handleSubmit}
                                    isDisable={isSearchDisabled}
                                >
                                    Search
                                </Button>
                            </div>

                            <div>
                                <Button
                                    type="button"
                                    className="btn-danger text-nowrap mt-2"
                                    onClick={handleClearFilter}
                                    isDisable={isSearchDisabled}
                                >
                                    Clear Filter
                                </Button>
                            </div>
                        </div>

                        <div>
                            <div>

                                <Button
                                    type="button"
                                    className="btn-dark text-nowrap mt-2"
                                    onClick={() => {
                                        navigate(-1);
                                    }}
                                >
                                    Back
                                </Button>
                            </div>
                        </div>
                    </div>

                    <div className="col-xxl-12">
                        <ViewHardwareSpecific
                            decodedJwt={decodedJwt}
                            TotalRecords={totalRecords}
                            getAllUserHardwareSpecificData={getAllUserHardwareSpecificData}
                            HardwareSpecific={hardwareSpecific}
                            CurrentPage={currentPage}
                            perPage={perPage}
                            handleChangePage={handleChangePage}
                            handleChangeRowsPerPage={handleChangeRowsPerPage} />
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default HardwareSpecific;

