import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { NuggetsTagService } from "../../../services/nuggetsTag.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";
import Textarea from "../../../components/bootstrap/forms/Textarea";

const AddEditNuggetsTag = () => {
    const { nuggetTagData, setNuggetTagData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [NuggetTagId, setNuggetTagId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedNuggetTagName, setSelectedNuggetTagName] = useState("");
    const [selectedNuggetTagId, setSelectedNuggetTagId] = useState<any>("");
    const [NuggetTagAllData, setNuggetTagAllData] = useState({
        tagName: "",
        tagType: "",
        tagLevel: "",
        categoryId: "",
        primaryId: "",
        tagDescription: ""
    });

    const validationSchema = yup.object().shape({
        tagName: yup.string().required("required"),
        tagType: yup.string().required("required"),
        tagLevel: yup.string().required("required"),
    });

    const tagTypeMapping = {
        [CONSTANT.NUGGET_TAG.CATEGORY]: CONSTANT.NUGGET_TAG.CATEGORY,
        [CONSTANT.NUGGET_TAG.PRIMARY_TAG]: CONSTANT.NUGGET_TAG.CATEGORY,
        [CONSTANT.NUGGET_TAG.SECONDARY_TAG]: CONSTANT.NUGGET_TAG.PRIMARY_TAG
    };

    const getAllNuggetTagByType = async (selectedType: any, field: 'tagLevel' | 'tagType' | 'record' = 'tagLevel', recordTagType?: string) => {
        try {
            let tagLevel, tagType;

            if (field === 'record') {
                if (selectedType === CONSTANT.NUGGET_TAG.PRIMARY_TAG) {
                    tagLevel = CONSTANT.NUGGET_TAG.CATEGORY;
                } else if (selectedType === CONSTANT.NUGGET_TAG.SECONDARY_TAG) {
                    tagLevel = CONSTANT.NUGGET_TAG.PRIMARY_TAG;
                } else {
                    return;
                }
                tagType = recordTagType || values.tagType;
            } else if (field === 'tagLevel') {
                tagLevel = tagTypeMapping[selectedType] || selectedType;
                tagType = values.tagType;
            } else {
                tagLevel = tagTypeMapping[values.tagLevel] || values.tagLevel;
                tagType = selectedType;
            }
            const requestBody = {
                tagLevel,
                tagType
            };
            let data = await NuggetsTagService.getAllNuggetTagByType(requestBody);
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.nuggetTag;
                setNuggetTagData(responseData);
                // setSelectedNuggetTagName("");
            } else {
                setSelectedNuggetTagName("");
                setNuggetTagData([]);
                toast.error(data.message);
            }
        } catch (error: any) {
            setSelectedNuggetTagName("");
            setNuggetTagData([]);
            toast.error(error?.response?.data?.message);
        }
    };

    const handleChangeNugget = (event: any) => {
        const { name, value } = event.target;
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));

        if (name === 'tagLevel') {
            if (value !== CONSTANT.NUGGET_TAG.CATEGORY) {
                getAllNuggetTagByType(value, 'tagLevel');
                setSelectedNuggetTagName("");
            }
        } else if (name === 'tagType') {
            getAllNuggetTagByType(value, 'tagType');
            setSelectedNuggetTagName("");
        }
    };

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...NuggetTagAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    let filteredValues: any = { ...values };

                    if (values.tagLevel === CONSTANT.NUGGET_TAG.PRIMARY_TAG) {
                        filteredValues.categoryId = selectedNuggetTagId;
                        // delete filteredValues.primaryId;
                    } else if (values.tagLevel === CONSTANT.NUGGET_TAG.SECONDARY_TAG) {
                        let category = nuggetTagData.find((ele: any) => ele._id == selectedNuggetTagId);
                        filteredValues.primaryId = selectedNuggetTagId;
                        filteredValues.categoryId = category.categoryId;
                    }
                    setIsLoading(true);
                    const data = NuggetTagId ? await NuggetsTagService.updateNuggetTagById(NuggetTagId, filteredValues) : await NuggetsTagService.addNuggetTag(filteredValues);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const NuggetTagName = await NuggetsTagService.getAllNuggetTagName();
                        setNuggetTagData(NuggetTagName?.data?.nuggetTag);
                        navigate(ROUTING_CONSTANT.NUGGETS_TAG_VIEW);
                        action.resetForm();

                        const { _id, name } = data.data;

                        if (NuggetTagId) {
                            const existingIndex = nuggetTagData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setNuggetTagData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setNuggetTagId(decryptData(params.id));
                getNuggetTagById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getNuggetTagById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await NuggetsTagService.getNuggetTagById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setNuggetTagAllData(response?.data?.nuggetTag);
                setValues({ ...response?.data?.nuggetTag });
                setSelectedNuggetTagName(response?.data?.nuggetTag?.tagLevel == CONSTANT.NUGGET_TAG.PRIMARY_TAG ? response?.data?.nuggetTag?.categoryId.tagName : response?.data?.nuggetTag?.primaryId.tagName);
                setSelectedNuggetTagId(response?.data?.nuggetTag?.tagLevel == CONSTANT.NUGGET_TAG.PRIMARY_TAG ? response?.data?.nuggetTag?.categoryId._id : response?.data?.nuggetTag?.primaryId._id);
                if (response?.data?.nuggetTag?.tagLevel === CONSTANT.NUGGET_TAG.PRIMARY_TAG) {
                    getAllNuggetTagByType(CONSTANT.NUGGET_TAG.PRIMARY_TAG, 'record', response?.data?.nuggetTag?.tagType);
                } else if (response?.data?.nuggetTag?.tagLevel === CONSTANT.NUGGET_TAG.SECONDARY_TAG) {
                    getAllNuggetTagByType(CONSTANT.NUGGET_TAG.SECONDARY_TAG, 'record', response?.data?.nuggetTag?.tagType);
                } else {
                    return;
                }
            } else {
                setSelectedNuggetTagName('');
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.NUGGETS_TAG_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {NuggetTagId ? CONSTANT.UPDATE : CONSTANT.ADD} Nugget Tag
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Tag Type*</label>
                                <FormGroup id="tagType">
                                    <select
                                        className={`${isInvalidForm && values.tagType.length === 0
                                            ? "border border-danger  form-select form-selet-lg input-form"
                                            : "form-select form-selet-lg input-form"
                                            }`}
                                        name="tagType"
                                        value={values.tagType}
                                        onChange={handleChangeNugget}
                                    >
                                        <option value="">Select Tag Type</option>
                                        <option value={CONSTANT.TAG_LEVEL.CONTENT_TAG}>
                                            {CONSTANT.TAG_LEVEL.CONTENT_TAG}
                                        </option>
                                        <option value={CONSTANT.TAG_LEVEL.CONTEXT_TAG}>
                                            {CONSTANT.TAG_LEVEL.CONTEXT_TAG}
                                        </option>
                                    </select>
                                </FormGroup>

                                {isInvalidForm && values.tagType.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TYPE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Tag Name*</label>

                                <FormGroup id="tagName">
                                    <Input
                                        placeholder="Tag Name"
                                        className={`${isInvalidForm && !values.tagName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="tagName"
                                        value={values.tagName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.tagName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Nugget Tag Level*</label>
                                <FormGroup id="tagLevel">
                                    <select
                                        className={`${isInvalidForm && values.tagLevel.length === 0
                                            ? "border border-danger  form-select form-selet-lg input-form"
                                            : "form-select form-selet-lg input-form"
                                            }`}
                                        name="tagLevel"
                                        value={values.tagLevel}
                                        onChange={handleChangeNugget}
                                    >
                                        <option value="">Select Nugget Tag Level</option>
                                        <option value={CONSTANT.NUGGET_TAG.CATEGORY}>
                                            {CONSTANT.NUGGET_TAG.CATEGORY}
                                        </option>
                                        <option value={CONSTANT.NUGGET_TAG.PRIMARY_TAG}>
                                            {CONSTANT.NUGGET_TAG.PRIMARY_TAG}
                                        </option>
                                        <option value={CONSTANT.NUGGET_TAG.SECONDARY_TAG}>
                                            {CONSTANT.NUGGET_TAG.SECONDARY_TAG}
                                        </option>
                                    </select>
                                </FormGroup>

                                {isInvalidForm && values?.tagLevel?.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NUGGETS_TYPE}</small>
                                    </p>
                                )}
                            </div>
                            {(values.tagLevel === CONSTANT.NUGGET_TAG.PRIMARY_TAG ||
                                values.tagLevel === CONSTANT.NUGGET_TAG.SECONDARY_TAG) && (
                                    <div className="col-sm-12 col-md-6" key="NuggetTag">
                                        <label className="form-labels">
                                            {values.tagLevel === CONSTANT.NUGGET_TAG.PRIMARY_TAG ? (
                                                values.tagType === CONSTANT.TAG_LEVEL.CONTENT_TAG ?
                                                    "Select Category Tag For Content Tag" :
                                                    "Select Category Tag For Context Tag"
                                            ) : (
                                                values.tagType === CONSTANT.TAG_LEVEL.CONTENT_TAG ?
                                                    "Select Primary For Content Tag" :
                                                    "Select Primary For Context Tag"
                                            )}
                                        </label>

                                        <FormGroup id="NuggetTag">
                                            <Select
                                                styles={customStyles}
                                                onChange={(selectedOption) => {
                                                    setSelectedNuggetTagId(selectedOption ? selectedOption.value : "");
                                                    setSelectedNuggetTagName(selectedOption ? selectedOption.label : "");
                                                }}
                                                options={nuggetTagData?.map((NuggetTag: any) => ({
                                                    value: NuggetTag._id,
                                                    label: NuggetTag.tagName,
                                                }))}
                                                placeholder={values.tagLevel === CONSTANT.NUGGET_TAG.PRIMARY_TAG ? (
                                                    values.tagType === CONSTANT.TAG_LEVEL.CONTENT_TAG ?
                                                        "Select Category Tag For Content Tag" :
                                                        "Select Category Tag For Context Tag"
                                                ) : (
                                                    values.tagType === CONSTANT.TAG_LEVEL.CONTENT_TAG ?
                                                        "Select Primary For Content Tag" :
                                                        "Select Primary For Context Tag"
                                                )}
                                                value={
                                                    selectedNuggetTagName
                                                        ? { value: selectedNuggetTagId, label: selectedNuggetTagName }
                                                        : null
                                                }
                                                isSearchable={true}
                                                isClearable
                                                className={
                                                    isInvalidForm && !selectedNuggetTagName ? "border border-danger" : ""
                                                }
                                            />
                                        </FormGroup>
                                        {isInvalidForm && !selectedNuggetTagName && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.REQUIRED}</small>
                                            </p>
                                        )}
                                    </div>
                                )
                            }

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Nugget Tag Description</label>

                                <FormGroup id="tagDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Nugget Tag Description"
                                        className={`${isInvalidForm && !values.tagDescription
                                            ? "border"
                                            : ""
                                            }`}
                                        name="tagDescription"
                                        value={values.tagDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {NuggetTagId ? CONSTANT.UPDATE : CONSTANT.ADD} Nugget Tag
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditNuggetsTag;
