import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { UserService } from "../../../services/user.service";
import { toast } from "react-toastify";
import { decryptData } from "../../../core/auth.service";

const VerifyUser: any = () => {
    const { id } = useParams()
    useEffect(() => {
        user()
    }, [])

    let obj = {
        verifyToken: decryptData(id)
    };

    const navigate = useNavigate()
    const user = async () => {
        const verifyUser = await UserService.verifyAdminUser(obj);
        if (verifyUser && verifyUser.status === "success") {
            toast.success(verifyUser.message);
            const resetPasswordLink = "/resetPassword" + "/" + verifyUser.data._id;
            navigate(resetPasswordLink);
        }

        else {
            toast.error(verifyUser.message)
        }
    }
}


export default VerifyUser