import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { useFormik } from "formik";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import { LabTestService } from "../../../services/labTest.service";

const AddEditLabTest = () => {
    const { labTestData, setLabTestData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [labTestId, setLabTestId] = useState("");
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [labTestAllData, setLabTestAllData] = useState({
        labTestName: "",
        overviewContent: "",
        purposeContent: "",
        prepContent: "",
        turnaroundContent: "",
        duration: "",
        followUp: "",
        testPrice: ""
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setLabTestId(decryptData(params.id));
                getLabTestById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const validationSchema = yup.object().shape({
        labTestName: yup.string().required(" required"),
        overviewContent: yup.string().required(" required"),
        duration: yup.string().required("required"),
        testPrice: yup.number().required("required"),
    });

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...labTestAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = labTestId ? await LabTestService.updateLabTest(labTestId, { ...values }) : await LabTestService.createLabTest({ ...values });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const LabTestName = await LabTestService.getAllLabTestName();
                        setLabTestData(LabTestName.data?.labTest);

                        action.resetForm();

                        navigate(ROUTING_CONSTANT.LAB_TEST_VIEW);

                        const { _id, labTestName } = data.data;

                        if (labTestId) {
                            const existingIndex = labTestData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setLabTestData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, labTestName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getLabTestById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await LabTestService.getLabTestById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setLabTestAllData(response?.data?.labTest);
                setValues({ ...response?.data?.labTest });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.LAB_TEST_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {labTestId ? CONSTANT.UPDATE : CONSTANT.ADD} Lab Test
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Lab Test Name*</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Lab Test Name"
                                        className={`${isInvalidForm && !values.labTestName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="labTestName"
                                        value={values.labTestName}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.labTestName && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.NAME}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Overview Content*</label>

                                <FormGroup id="ProductHowImage">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for Lab Test Overview Content"
                                        className={`${isInvalidForm && !values.overviewContent
                                            ? "border border-danger "
                                            : ""
                                            }`}
                                        name="overviewContent"
                                        value={values.overviewContent}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.overviewContent && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.CONTENT}</small>
                                    </p>
                                )}
                                <a
                                    href="https://codebeautify.org/htmlviewer"
                                    target="_blank"
                                >
                                    HTML Viewer
                                </a>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Purpose of Test Content</label>

                                <FormGroup id="shortDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for Purpose of Test Content"
                                        className={`${isInvalidForm && !values.purposeContent
                                            ? "border"
                                            : ""
                                            }`}
                                        name="purposeContent"
                                        value={values.purposeContent}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Preparation of Test Content</label>

                                <FormGroup id="shortDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for Preparation of Test Content"
                                        className={`${isInvalidForm && !values.prepContent
                                            ? "border"
                                            : ""
                                            }`}
                                        name="prepContent"
                                        value={values.prepContent}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Result Turn Around Content</label>

                                <FormGroup id="shortDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for Result Turn Around Content"
                                        className={`${isInvalidForm && !values.turnaroundContent
                                            ? "border"
                                            : ""
                                            }`}
                                        name="turnaroundContent"
                                        value={values.turnaroundContent}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Duration</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Lab Test Duration"
                                        className={`${isInvalidForm && !values.duration
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="duration"
                                        value={values.duration}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Follow Up</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Lab Test Follow Up"
                                        className={`${isInvalidForm && !values.followUp
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="followUp"
                                        value={values.followUp}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Lab Test Price*</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Lab Test Price"
                                        className={`${isInvalidForm && !values.testPrice
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="testPrice"
                                        type="number"
                                        value={values.testPrice}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.testPrice && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.PRICE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={handleSave}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {labTestId ? CONSTANT.UPDATE : CONSTANT.ADD} Lab Test
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditLabTest;