import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { DeviceModelService } from "../../../services";
import { Switch } from "@material-ui/core";

const AddEditDeviceModel = () => {
    const { setDeviceModelData, deviceModelData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [deviceModelId, setDeviceModelId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [deviceModelAllData, setDeviceModelAllData] = useState({
        deviceModelName: "",
        deviceModelImage: "",
        deviceModelDesc: "",
        deviceModelPrice: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setDeviceModelId(decryptData(params.id));
                getDeviceModelById(decryptData(params.id));
                setFieldsDisabled(true);
            }
        };
        init();
    }, []);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        deviceModelName: yup.string().required(" required"),
        deviceModelImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        deviceModelPrice: yup.string().required("required"),
    });

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...deviceModelAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const payload = {
                        ...values,
                    }
                    const data = deviceModelId ? await DeviceModelService.updateDeviceModelById(deviceModelId, payload) : await DeviceModelService.addDeviceModel(payload);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const devicename = await DeviceModelService.getAllDeviceModelName();
                        setDeviceModelData(devicename.data.deviceModel);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.MODEL_VIEW);

                        const { _id, deviceModelName } = data.data;
                        if (deviceModelId) {
                            const existingIndex = deviceModelData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setDeviceModelData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, deviceModelName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getDeviceModelById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await DeviceModelService.getDeviceModelById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setDeviceModelAllData(response?.data?.deviceModel);
                setValues({ ...response?.data?.deviceModel });
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.MODEL_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{deviceModelId ? CONSTANT.UPDATE : CONSTANT.ADD} Device Model </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Device Model Name*</label>
                                <FormGroup id="deviceModelName">
                                    <Input
                                        className={`${isInvalidForm && !values.deviceModelName
                                            ? "border border-danger input-form "
                                            : "input-form"
                                            } ${fieldsDisabled ? "user-input" : ""}`}
                                        name="deviceModelName"
                                        placeholder="Device Model Name"
                                        value={values.deviceModelName}
                                        onChange={handleChange}
                                        disabled={fieldsDisabled}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.deviceModelName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Device Model Image URL*</label>

                                <FormGroup id="deviceModelImage">
                                    <Input
                                        placeholder="Device Model Image URL"
                                        className={`${isInvalidForm && !values.deviceModelImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="deviceModelImage"
                                        value={values.deviceModelImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && isValidHttpUrl(values.deviceModelImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Device Model Price*</label>

                                <FormGroup id="deviceModelPrice">
                                    <Input
                                        placeholder="Device Model Price"
                                        className={`${isInvalidForm && !values.deviceModelPrice
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="deviceModelPrice"
                                        value={values.deviceModelPrice}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.deviceModelPrice && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.PRICE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Device Model Description</label>

                                <FormGroup id="deviceModelDesc">
                                    <Input
                                        placeholder="Device Model Description"
                                        className={`${isInvalidForm && !values.deviceModelDesc
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="deviceModelDesc"
                                        value={values.deviceModelDesc}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {deviceModelId ? CONSTANT.UPDATE : CONSTANT.ADD} Device Model
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditDeviceModel;
