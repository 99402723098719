import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { ProductService, SkuService } from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { isValidHttpUrl, ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditSku = () => {
    const { taggingLabel, setTaggingLabel }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [skuId, setSkuId] = useState("");
    const [imageArray, setImageArray] = useState<any>([]);
    const [saveCardChecked, setSaveCardChecked] = useState(false);
    const [isDefault, setIsDefault] = useState<any>(CONSTANT.NO);
    const [isLoading, setIsLoading] = useState(false);

    const [categoryData, setCategoryData] = useState({
        productVariant: "",
        taggingLabel: "",
        productWeight: "",
        productQty: "",
        productImage: [""],
        productServingSize: "",
        productServingJar: "",
        productCaution: "",
        productIngredients: "",
        productUsage: "",
        productAdvice: "",
        productCode: "",
        productSubscribeAmount: "",
        productSingleAmount: "",
        isDefault: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        productVariant: yup.string().required(" required"),
        taggingLabel: yup.string().required(" required"),
        productWeight: yup.string().required("required"),
        productQty: yup.string().required("required"),
        productAdvice: yup.string().required(" required"),
        productSubscribeAmount: yup.string().required("required"),
        productSingleAmount: yup.string().required("required"),
    });

    const handleAddClick = () => {
        if (values.productImage[0] !== "" && isValidHttpUrl(values.productImage)) {
            setImageArray((prevArray: any) => [...prevArray, values.productImage]);
            setValues({ ...values, productImage: [""] });
        } else {
            toast.error(CONSTANT.VALID_IMAGE);
        }
    };

    const handleRemoveClick = (index: any) => {
        setImageArray((prevArray: any) => {
            const newArray = [...prevArray];
            newArray.splice(index, 1);
            return newArray;
        });
    };

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...categoryData, productImage: [""] },
        validationSchema,

        onSubmit: async (values, action) => {
            const filteredImageArray = imageArray.filter(
                (image: any) => image.trim() !== ""
            );
            try {
                setIsLoading(true);
                const data = skuId ? await SkuService.EditSku(skuId, { ...values, isDefault: isDefault, productImage: filteredImageArray }) : await SkuService.addSku({ ...values, isDefault: isDefault, productImage: filteredImageArray, });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.SKU_VIEW);
                    const skuName = await ProductService.getAllSkuVariant();
                    setTaggingLabel(skuName?.data?.skus);

                    const { _id, productVariant } = data.data;
                    if (skuId) {
                        const existingIndex = taggingLabel.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setTaggingLabel((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, productVariant };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.response.data.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setSkuId(decryptData(params.id));
                getSkuById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getSkuById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await SkuService.getSkuById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setCategoryData(response?.data?.sku);
                setValues({ ...response?.data?.sku });
                setImageArray(response?.data?.sku?.productImage);
                setSaveCardChecked(response?.data?.sku?.isDefault === CONSTANT.YES);
                setIsDefault(response?.data?.sku?.isDefault);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };
    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.SKU_VIEW);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{skuId ? CONSTANT.UPDATE : CONSTANT.ADD} Sku</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="taggingLabel" label="Tagging Label*" isFloating>
                                    <Input
                                        placeholder="taggingLabel"
                                        className={`${isInvalidForm && !values.taggingLabel
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="taggingLabel"
                                        value={values.taggingLabel}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.taggingLabel && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TAGGING_LABEL}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productVariant"
                                    label="Product Variant*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="productVariant"
                                        className={`${isInvalidForm && !values.productVariant
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="productVariant"
                                        value={values.productVariant}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productVariant && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productWeight"
                                    label="Product Weight*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="productWeight"
                                        className={`${isInvalidForm && !values.productWeight
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="productWeight"
                                        value={values.productWeight}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.productWeight && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.WEIGHT}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="productQty" label="Product Quantity*" isFloating>
                                    <Input
                                        type="number"
                                        placeholder="productQty"
                                        className={`${isInvalidForm && !values.productQty
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="productQty"
                                        value={values.productQty}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.productWeight && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.QUANTITY}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6 ">
                                <div className="d-flex justify-content-between">
                                    <FormGroup id="productImage" label="Product Image" isFloating>
                                        <Input
                                            placeholder="productImage"
                                            className={`${isInvalidForm && imageArray.length === 0
                                                ? "border border-danger"
                                                : ""
                                                }`}
                                            name="productImage"
                                            value={values.productImage}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-plus-circle sku-icon cursor-pointer"
                                        viewBox="0 0 16 16"
                                        onClick={handleAddClick}
                                    >
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </div>

                                {imageArray.length > 0 && (
                                    <div className="mt-3 ">
                                        <FormGroup isFloating>
                                            <ul
                                                className="list-unstyled d-flex gap-2 flex-wrap 
"
                                            >
                                                {imageArray.map((image: any, index: any) => (
                                                    <li key={index}>
                                                        <img src={image} className="sku-img" />
                                                        <div className="mt-2">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-x-circle sku-icon cursor-pointer"
                                                                viewBox="0 0 16 16"
                                                                onClick={()=>{handleRemoveClick(index)}}
                                                            >
                                                                <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                                                            </svg>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ul>
                                        </FormGroup>
                                    </div>
                                )}

                                {isInvalidForm && imageArray.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.IMAGE_VALID}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productServingSize"
                                    label="Product Serving Size"
                                    isFloating
                                >
                                    <Input
                                        placeholder="productServingSize"
                                        name="productServingSize"
                                        value={values.productServingSize}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productServingJar"
                                    label="Product Serving Jar"
                                    isFloating
                                >
                                    <Input
                                        placeholder="productServingJar"
                                        name="productServingJar"
                                        value={values.productServingJar}
                                        onChange={handleChange}
                                        type="number"
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productCaution"
                                    label="Product Caution"
                                    isFloating
                                >
                                    <Input
                                        placeholder="productCaution"
                                        name="productCaution"
                                        value={values.productCaution}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productIngredients"
                                    label="Product Ingredients"
                                    isFloating
                                >
                                    <Input
                                        placeholder="productIngredients"
                                        name="productIngredients"
                                        value={values.productIngredients}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="productUsage" label="Product Usage" isFloating>
                                    <Input
                                        placeholder="productUsage"
                                        name="productUsage"
                                        value={values.productUsage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="longDescription"
                                    label="Product Advice*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="SubCategory Name"
                                        className={`${isInvalidForm && !values.productAdvice
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="productAdvice"
                                        value={values.productAdvice}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.productAdvice && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.ADVICE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="productCode" label="Product Code" isFloating>
                                    <Input
                                        placeholder="productCode"
                                        name="productCode"
                                        value={values.productCode}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="productSubscribeAmount"
                                    label="Product Subscribe Amount*"
                                    isFloating
                                >
                                    <Input
                                        type="number"
                                        placeholder="productSubscribeAmount"
                                        className={`${isInvalidForm && !values.productSubscribeAmount
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="productSubscribeAmount"
                                        value={values.productSubscribeAmount}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productSubscribeAmount && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUBSCRIBE_AMOUNT}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="longDescription"
                                    label="Product Single Amount*"
                                    isFloating
                                >
                                    <Input
                                        type="number"
                                        placeholder="productSingleAmount"
                                        className={`${isInvalidForm && !values.productSingleAmount
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="productSingleAmount"
                                        value={values.productSingleAmount}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.productSingleAmount && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SINGLE_AMOUNT}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div className="form-check mt-4">
                                    <input
                                        type="checkbox"
                                        id="isDefault"
                                        className="form-check-input cursor-pointer"
                                        checked={saveCardChecked}
                                        onChange={(event) => {
                                            setSaveCardChecked(event.target.checked);
                                            setIsDefault(
                                                event.target.checked ? CONSTANT.YES : CONSTANT.NO
                                            );
                                            handleChange(event);
                                        }}
                                    />
                                    <label
                                        className="form-check-label cursor-pointer"
                                        htmlFor="isDefault"
                                    >
                                        Is Default?
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {skuId ? CONSTANT.UPDATE : CONSTANT.ADD} Sku
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditSku;
