import { CONSTANT } from './static-constant';
import { toast } from 'react-toastify';
import CryptoJS from "crypto-js";
const secretCryptoJS : any = process.env.REACT_APP_CRYPTO_JS_SECRET_KEY;

export default function authHeader() {
  const userInfo: any =  Localstorage(CONSTANT.GET, CONSTANT.USER_PROFILE);
  const user: any = JSON.parse(userInfo);
  if (user && user?.token) {
    return { Authorization: 'Bearer ' + user.token };
  } else {
    return {};
  }
}

export const Localstorage = (methodType: string,key:string, data?: string) => {
  if (methodType === CONSTANT.SET) {
      localStorage.setItem(key, data!);
  } else if (methodType === CONSTANT.GET) {
      return localStorage.getItem(key);
  } else if (methodType === CONSTANT.REMOVE) {
      localStorage.removeItem(key);
  }
};

export const getCurrentUser = () => {
  const userInfo: any = Localstorage(CONSTANT.GET, CONSTANT.USER_PROFILE);
  const userData: any = JSON.parse(userInfo);
  if (userData) {
    const decodedJwt = parseJwt(userData.token);
    if (decodedJwt.exp * 1000 < Date.now()) {
      return false;
    }
  }
  return userData;
};


export const getDataUser = () => {
    const userInfo: any = Localstorage(CONSTANT.GET, CONSTANT.USER_DATA);
    const userData: any = JSON.parse(userInfo);
    return userData;
  };


  export const authToken = () => {
    const userInfo: any = Localstorage(CONSTANT.GET, CONSTANT.AUTH_TOKEN);
    const decodedJwt = parseJwt(userInfo);
    return decodedJwt;
  };


const parseJwt = (token: string) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const isLoggedIn = () => {
  const userInfo: any = Localstorage(CONSTANT.GET, CONSTANT.USER_PROFILE);
  if (userInfo) {
    return true;
  }
  return false;
};

export const logoutUser = ({ error }: { error: any }) => {
    window.location.href = "/auth-pages/login"
    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN);
    toast.error(error.response.data.error);
};

const aesKey = CryptoJS.enc.Utf8.parse(secretCryptoJS);
const aesIv = CryptoJS.enc.Utf8.parse('0123456789abcdef');
const aesOptions = {
    iv: aesIv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
};

export const encryptData = (text: any) => {
  return CryptoJS.AES.encrypt(text || "", aesKey, aesOptions).ciphertext.toString();
}

export const decryptData = (text: any) => {
  const encoded : any = {ciphertext: CryptoJS.enc.Hex.parse(text|| "")};
  return CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(encoded, aesKey, aesOptions));
}