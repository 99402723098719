import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import "../User/user.scss";
import { GoalService } from "../../../services";

const AddEditGoal = () => {
    const { setGoalData, goalData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [goalId, setGoalId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [goalAllData, setGoalAllData] = useState({
        name: "",
        title: "",
        subtitle: "",
        description: "",
        status:""
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setGoalId(decryptData(params.id));
                getGoalById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        name: yup.string().required(" required"),
        title: yup.string().required(" required"),
        status: yup.string().required(" required"),
    });

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...goalAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = goalId ? await GoalService.updateGoalById(goalId, { ...values, }) : await GoalService.createGoal({ ...values, });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const goalName = await GoalService.getAllGoalName();
                        setGoalData(goalName?.data?.Goal);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.GOAL_VIEW);

                        const { _id, name } = data.data;
                        if (goalId) {
                            const existingIndex = goalData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setGoalData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getGoalById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await GoalService.getGoalById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setGoalAllData(response?.data?.Goal);
                setValues({ ...response?.data?.Goal });
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.GOAL_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{goalId ? CONSTANT.UPDATE : CONSTANT.ADD} Goal</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Goal Name*</label>

                                <FormGroup id="name">
                                    <Input
                                        placeholder="Goal Name"
                                        className={`${isInvalidForm && !values.name
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.name && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Goal Title*</label>

                                <FormGroup id="faqImg">
                                    <Input
                                        placeholder="Goal Title"
                                        className={`${isInvalidForm && !values.title
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.title && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Goal Subtitle</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Goal Subtitle"
                                        className={`${isInvalidForm && !values.subtitle
                                            ? "border input-form "
                                            : "input-form"
                                            }`}
                                        name="subtitle"
                                        value={values.subtitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select Goal Status*</label>
                                <FormGroup id="status">
                                        <select
                                            className={`${isInvalidForm && values.status.length === 0
                                                ? "border border-danger  form-select form-selet-lg input-form"
                                                : "form-select form-selet-lg input-form"
                                                }`}
                                            name="status"
                                            value={values.status}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Goal Status</option>
                                            <option value={CONSTANT.STATUS.ACTIVE}>
                                                {CONSTANT.STATUS.ACTIVE}
                                            </option>
                                            <option value={CONSTANT.STATUS.INACTIVE}>
                                                {CONSTANT.STATUS.INACTIVE}
                                            </option>
                                        </select>
                                </FormGroup>

                                {isInvalidForm && values.status.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.GOAL_STATUS}</small>
                                    </p>
                                )}
                            </div>
                            
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Goal Description</label>

                                <FormGroup id="longDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Goal Description"
                                        className={`${isInvalidForm && !values.description
                                            ? "border  "
                                            : ""
                                            }`}
                                        name="description"
                                        value={values.description}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {goalId ? CONSTANT.UPDATE : CONSTANT.ADD} Goal
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditGoal;
