import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { UserProfileSubCategoryService } from "../../../services/userSubCategory.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import { decryptData } from "../../../core/auth.service";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import "../User/user.scss";
import Select from "react-select";

const AddEditUserProfileSubCategory = () => {
    const { userProfileCategoryData, userProfileSubCategoryData, setUserProfileSubCategoryData,setUserProfileCategoryData }: any =
        useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const [userProfileCategoryId, setUserSubCategoryId] = useState("");
    const [UserProfileSubCategoryAllData, setUserProfileSubCategoryAllData] = useState({
        subCategoryName: "",
        userProfileCategoryId: "",
    });

    const [selectedCategoryName, setSelectedCategoryName] = useState("");
    const [selectedUserProfileSubCategoryId, setSelectedUserProfileSubCategoryId] =
        useState("");

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        subCategoryName: yup.string().required("Name is required"),
    });
    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...UserProfileSubCategoryAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = userProfileCategoryId ? await UserProfileSubCategoryService.editUserProfileSubCategory(userProfileCategoryId, { ...values,userProfileCategoryId:selectedUserProfileSubCategoryId  }) : await UserProfileSubCategoryService.addUserProfileSubCategory({ ...values,userProfileCategoryId:selectedUserProfileSubCategoryId });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY_VIEW);

                    const { _id, subCategoryName } = data.data;
                    if (userProfileCategoryId) {
                        const existingIndex = userProfileSubCategoryData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setUserProfileSubCategoryData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, subCategoryName };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setUserSubCategoryId(decryptData(params.id));
                getCategoryById(decryptData(params.id));
            }
        };
        init();
        if(userProfileCategoryData?.length === 0){
            getAllUserProfileCategoryName();
        }
    }, []);

    const getAllUserProfileCategoryName = async () => {
        try {
          let data: any = await UserProfileSubCategoryService.getAllUserProfileCategoryName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.userProfileCategory;
            setUserProfileCategoryData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
      };

    const getCategoryById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await UserProfileSubCategoryService.getUserProfileSubCategoryById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setUserProfileSubCategoryAllData(response?.data?.userProfileCategory);
                setValues({ ...response?.data?.userProfileCategory });
                setSelectedCategoryName(
                    response?.data?.userProfileCategory?.userProfileCategoryId?.name
                );
                setSelectedUserProfileSubCategoryId(
                    response?.data?.userProfileCategory?.userProfileCategoryId?._id
                );
            }
        } catch (error: any) {
            toast.error(error.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    
    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {userProfileCategoryId ? CONSTANT.UPDATE : CONSTANT.ADD} User Profile Subcategory
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select User Profile Category*</label>

                                <FormGroup id="categoryName">
                                     <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedUserProfileSubCategoryId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedCategoryName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            userProfileCategoryData &&
                                            userProfileCategoryData.map((dosageAssistance: any) => ({
                                                value: dosageAssistance._id,
                                                label: dosageAssistance.name,
                                            }))
                                        }
                                        placeholder="Select User Profile Category"
                                        value={
                                            selectedCategoryName
                                                ? {
                                                    value: selectedUserProfileSubCategoryId,
                                                    label: selectedCategoryName,
                                                }
                                                : null
                                        }
                                        className={`${isInvalidForm && !selectedCategoryName
                                                ? "border border-danger"
                                                : "border rounded-circle"
                                            }`}
                                        isSearchable={true}
                                    /> 
                                </FormGroup>
                                {isInvalidForm && selectedCategoryName.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.CATEGORY_ID}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">User Profile Subcategory Name*</label>

                                <FormGroup id="subcategoryname">
                                    <Input
                                        placeholder="Subcategory Name"
                                        className={`${isInvalidForm && !values.subCategoryName
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="subCategoryName"
                                        value={values.subCategoryName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.subCategoryName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {userProfileCategoryId ? CONSTANT.UPDATE : CONSTANT.ADD} User Profile SubCategory
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditUserProfileSubCategory;
