import React, {
  createContext,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useContext,
  useState,
} from "react";
import PropTypes from "prop-types";
import jwt_decode from "jwt-decode";
import { Localstorage, authToken } from "../core/auth.service";
import { CONSTANT } from "../core/static-constant";

export interface IAuthContextProps {
  user: string;
  setUser?(...args: unknown[]): unknown;
  userData: {
    email: string;
    userType: string; 
  };
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

interface IAuthContextProviderProps {
  children: ReactNode;
}

interface JwtPayload {
  email: string;
  userType?: string;
}

export const AuthContextProvider: FC<IAuthContextProviderProps> = ({
  children,
}) => {
  const [user, setUser] = useState<string>(
    Localstorage(CONSTANT.GET, CONSTANT.AUTH_TOKEN) || "",
  );
  const [userData, setUserData] = useState({
    email: "",
    userType: "", 
  });

  useEffect(() => {
    Localstorage(CONSTANT.SET, CONSTANT.AUTH_TOKEN, user)
  }, [user]);

  useEffect(() => {
    if (user !== "") {
      
      const token = authToken()

      if (token) {
        setUserData({ email: token.email, userType: token.userType || "" });
      } else {
        setUserData({ email: "", userType: "" });      
      }
    } else {
      setUserData({ email: "", userType: "" });
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      setUser,
      userData,
    }),
    [user, userData]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

AuthContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;



export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthContextProvider');
  }
  return context;
};
