import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { CategoryService, ProductService } from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { BenefitService } from "../../../services/benefit.service";
import * as yup from "yup";

import { any } from "prop-types";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import "../User/user.scss";
import { decryptData } from "../../../core/auth.service";

const AddEditBenefit = () => {
    const { benefitData, setBenefitData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [benefitId, setBenefitId] = useState("");
    const [benefitAllData, setBenefitAllData] = useState({
        benefitName: "",
        benefitImage: "",
        benefitIcon: "",
        shortDescription: "",
        longDescription: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setBenefitId(decryptData(params.id));
                getBenefitById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = yup.object().shape({
        benefitName: yup.string().required("required"),
        shortDescription: yup.string().required(" required"),
        benefitImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        benefitIcon: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),

        longDescription: yup.string().required("required"),
    });

    const {
        values,
        errors,
        touched,
        handleBlur,
        handleChange,
        handleSubmit,
        setValues,
    } = useFormik({
        initialValues: { ...benefitAllData },
        validationSchema,

        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = benefitId ? await BenefitService.editBenefit(benefitId,{...values}) : await BenefitService.addBenefit({...values});
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    const BenefitName = await ProductService.getAllBenefitName();
                    setBenefitData(BenefitName?.data?.benefits);
                    action.resetForm();

                    navigate(ROUTING_CONSTANT.BENEFIT_VIEW);

                    const { _id, benefitName } = data.data;

                    if (benefitId) {
                        const existingIndex = benefitData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setBenefitData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, benefitName };
                                return newData;
                            });
                        }
                    } 
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    const getBenefitById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await BenefitService.getBenefitById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setBenefitAllData(response?.data?.benefit);
                setValues({ ...response?.data?.benefit });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.BENEFIT_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {benefitId ? CONSTANT.UPDATE : CONSTANT.ADD} Benefit
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="benefitName" label="Benefit Name*" isFloating>
                                    <Input
                                        placeholder="Benefit Name"
                                        className={`${isInvalidForm && !values.benefitName
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="benefitName"
                                        value={values.benefitName}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.benefitName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="longDescription"
                                    label="Benefit Long Description*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="Benefit Long Description"
                                        className={`${isInvalidForm && !values.longDescription
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="longDescription"
                                        value={values.longDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.longDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="shortDescription"
                                    label="Benefit Short Description*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="Benefit Short Description"
                                        className={`${isInvalidForm && !values.shortDescription
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="shortDescription"
                                        value={values.shortDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.shortDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="benefitImage"
                                    label="Benefit Image URL*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="Benefit Image URL"
                                        className={`${isInvalidForm && !values.benefitImage
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="benefitImage"
                                        value={values.benefitImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.benefitImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="benefitIcon"
                                    label="Benefit Icon URL*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="Benefit Icon URL"
                                        className={`${isInvalidForm && !values.benefitIcon
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="benefitIcon"
                                        value={values.benefitIcon}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm &&
                                    isValidHttpUrl(values.benefitIcon) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.ICON}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={handleSave}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {benefitId ? CONSTANT.UPDATE : CONSTANT.ADD} Benefit
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditBenefit;
