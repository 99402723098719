import httpCommon from '../core/http-common';
import { logoutUser } from '../core/auth.service';
import { API_URL } from '../core/constant';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const CourseFaqService = {
    getAllCourseFaq,
    deleteCourseFaqById,
    getCourseFaqById,
    updateCourseFaqId,
    createCourseFaq,
};

async function getAllCourseFaq(data: any) {
    return httpCommon.post(API_URL.GET_ALL_COURSE_FAQ, data).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function deleteCourseFaqById(id: any) {
    return httpCommon.get(API_URL.DELETE_COURSE_FAQ_BY_ID + id).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function getCourseFaqById(id: any) {
    return httpCommon.get(API_URL.GET_COURSE_FAQ_BY_ID + id).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function updateCourseFaqId(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_COURSE_FAQ_ID + id, data).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function createCourseFaq(data: any) {
    return httpCommon.post(API_URL.CREATE_COURSE_FAQ, data).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
