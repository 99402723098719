import React from "react";
import { createRoot } from "react-dom/client"; // For React 18
import { BrowserRouter as Router } from "react-router-dom";
import "./styles/styles.scss";
import App from "./App/App";
import reportWebVitals from "./reportWebVitals";
import { ThemeContextProvider } from "./contexts/themeContext";
import { AuthContextProvider } from "./contexts/authContext";
import { DataContextProvider } from "./contexts/dataContext";
import { authToken } from "./core/auth.service";

const container = document.getElementById("root");
const isAuthenticated: any = authToken() || "";

const children = (
  <>
    <AuthContextProvider>
      <ThemeContextProvider>
        <Router>
          {isAuthenticated ? (
            <DataContextProvider>
              <App />
            </DataContextProvider>
          ) : (
            <App />
          )}
        </Router>
      </ThemeContextProvider>
    </AuthContextProvider>
  </>
);

createRoot(container as Element).render(children);

reportWebVitals();
