import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { CourseFaqService } from "../../../services/courseFaq.service";
import Select from "react-select";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { CourseService } from "../../../services";

const AddEditCourseFaq = () => {
    const { setCourseFaqData, courseFaqData,setCourseData,courseData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [courseFaqId, setCourseFaqId] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [selectedCourseName, setSelectedCourseName] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState<any>([]);
    const [selectedCourseIdArr, setSelectedCourseIdArr] = useState<any>([]);

    const [classFaqAllData, setClassFaqAllData] = useState({
        faqTitle: "",
        faqDescription: "",
        courses: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setCourseFaqId(decryptData(params.id));
                getCourseFaqById(decryptData(params.id));
            }
        };
        init();
        if(courseData?.length === 0){
            getAllCourse();
        }
    }, []);

    const getAllCourse = async () => {
        try {
          let data: any = await CourseService.getAllCourseByName()
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.courses;
            setCourseData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
      };


    useEffect(() => {
        const data = selectedCourseName
            ? selectedCourseName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedCourseId(data);
    }, [selectedCourseName]);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        faqTitle: yup.string().required(" required"),
        faqDescription: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...classFaqAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = courseFaqId ? await CourseFaqService.updateCourseFaqId(courseFaqId, { ...values,courses: selectedCourseIdArr },) : await CourseFaqService.createCourseFaq({ ...values,courses: selectedCourseIdArr, });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        action.resetForm();

                        navigate(ROUTING_CONSTANT.COURSE_FAQ_VIEW);

                        const { _id, courseName } = data.data;

                        if (courseFaqId) {
                            const existingIndex = courseFaqData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setCourseFaqData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, courseName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getCourseFaqById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await CourseFaqService.getCourseFaqById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setClassFaqAllData(response?.data?.courseFaq);
                setValues({ ...response?.data?.courseFaq });
                const selectedFaqNamesss = response?.data?.courseFaq?.courses.map(
                    (faq: any) => ({
                        id: faq._id,
                        label: faq.courseTitle,
                    })
                );

                setSelectedCourseName(selectedFaqNamesss);
                const faqs = response?.data?.courseFaq?.faqIds.map(
                    (faq: any) => faq._id
                );
                setSelectedCourseIdArr(faqs);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.COURSE_FAQ_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {courseFaqId ? CONSTANT.UPDATE : CONSTANT.ADD} Course Faq
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Faq Title*</label>

                                <FormGroup id="classTitle">
                                    <Input
                                        placeholder="Enter Course Faq Title"
                                        className={`${isInvalidForm && !values.faqTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="faqTitle"
                                        value={values.faqTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.faqTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Faq Description*</label>
                                <FormGroup id="classDescription">
                                    <Input
                                        placeholder="Enter Course Faq Description"
                                        className={`${isInvalidForm && !values.faqDescription
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="faqDescription"
                                        value={values.faqDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.faqDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="courses">
                                <label className="form-labels">Select Courses</label>

                                <FormGroup id="faq">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const course = selectedOptions.map((courses: any) => ({
                                                id: courses.value,
                                                label: courses.label,
                                            }));

                                            const courses = selectedOptions.map(
                                                (course: any) => course.value
                                            );
                                            setSelectedCourseIdArr(courses);
                                            setSelectedCourseName(course);
                                        }}
                                        options={
                                            courseData &&
                                            courseData.map((course: any) => ({
                                                value: course._id,
                                                label: course.courseTitle,
                                            }))
                                        }
                                        placeholder="Select Courses"
                                        value={selectedCourseId}
                                        isSearchable={true}
                                        isMulti
                                    />
                                </FormGroup>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {courseFaqId ? CONSTANT.UPDATE : CONSTANT.ADD} Course Faq
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditCourseFaq;
