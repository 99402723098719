import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import {
  DeviceCategoryService,
} from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
  isValidHttpUrl,
  isValidUrl,
  ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditDeviceCategory = () => {
  const { setDeviceCategoryData, deviceCategoryData }: any = useContext(DataContext);
  const params = useParams();
  const navigate = useNavigate();
  const [deviceCategoryId, setDeviceCategoryId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deviceCategoryAllData, setDeviceCategoryAllData] = useState({
    deviceCategoryName: "",
    deviceCategoryImg: "",
    deviceCategoryShortDesc: "",
    deviceCategoryLongDesc: "",
  });

  const validationSchema = yup.object().shape({
    deviceCategoryName: yup.string().required("required"),
    deviceCategoryLongDesc: yup.string().required("required"),
    deviceCategoryShortDesc: yup.string().required("required"),
    deviceCategoryImg: yup
      .string()
      .matches(isValidUrl(), "required")
      .required("required"),
  });

  const [isInvalidForm, setIsInvalidForm] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues: { ...deviceCategoryAllData },
      validationSchema,

      onSubmit: async (values, action) => {
        try {
            setIsLoading(true);
            const data = deviceCategoryId ? await DeviceCategoryService.updateDeviceCategoryById(deviceCategoryId,{...values}) : await DeviceCategoryService.createDeviceCategory({...values});
            setIsLoading(false);

            if (data && data.status === CONSTANT.SUCCESS) {
                toast.success(data.message);
                const CategoryName = await DeviceCategoryService.getAllDeviceCategoryName();
                setDeviceCategoryData(CategoryName?.data?.deviceCategory);
                action.resetForm();

                navigate(ROUTING_CONSTANT.CATEGORY_VIEW);

                const { _id, deviceCategoryName } = data.data;

                if (deviceCategoryId) {
                    const existingIndex = deviceCategoryData.findIndex((item: any) => item._id === _id);
                    if (existingIndex !== -1) {
                        setDeviceCategoryData((prevData: any) => {
                            const newData = [...prevData];
                            newData[existingIndex] = { _id, deviceCategoryName };
                            return newData;
                        });
                    }
                }
            } else {
                toast.error(data.message);
            }
        } catch (error:any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
          }
      },
    });

  useEffect(() => {
    const init = async () => {
      if (params && decryptData(params.id)) {
        setDeviceCategoryId(decryptData(params.id));
        getDeviceCategoryById(decryptData(params.id));
      }
    };
    init();
  }, []);

  const getDeviceCategoryById = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await DeviceCategoryService.getDeviceCategoryById(id);
      setIsLoading(false);
      if (response.status === CONSTANT.SUCCESS) {
        setDeviceCategoryAllData(response?.data?.deviceCategory);
        setValues({ ...response?.data?.deviceCategory });
      }
    } catch (error:any) {
        toast.error(error?.response?.data?.error);
        setIsLoading(false);
      }
  };

  const onCancelClick = () => {
    navigate(ROUTING_CONSTANT.CATEGORY_VIEW);
  };

  const handleSave = () => {
    setIsInvalidForm(true);
  };

  return (
    <div className="page container-xxl align-items-center">
      {isLoading && (
        <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
          <Loader />
        </div>
      )}
      <Card className="w-100">
        <CardBody>
          <h3 className="text-center mt-3 mb-5 text-primary">
            <strong>
              {deviceCategoryId ? CONSTANT.UPDATE : CONSTANT.ADD} Device Category
            </strong>
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="row g-4">
              <div className="col-sm-12 col-md-6">
                <label className="form-labels">Device Category Name*</label>

                <FormGroup id="categoryName">
                  <Input
                    placeholder="Device Category Name"
                    className={`${
                      isInvalidForm && !values.deviceCategoryName
                        ? "border border-danger input-form"
                        : "input-form"
                    }`}
                    name="deviceCategoryName"
                    value={values.deviceCategoryName}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm && !values.deviceCategoryName && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.NAME}</small>
                  </p>
                )}
              </div>
              <div className="col-sm-12 col-md-6">
                <label className="form-labels">Device Category Image URL*</label>

                <FormGroup id="deviceCategoryImg">
                  <Input
                    placeholder="Device Category Image URL"
                    className={`${
                      isInvalidForm && !values.deviceCategoryImg
                        ? "border border-danger input-form"
                        : "input-form"
                    }`}
                    name="deviceCategoryImg"
                    value={values.deviceCategoryImg}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm &&
                isValidHttpUrl(values.deviceCategoryImg) === false ? (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.IMAGE_VALID}</small>
                  </p>
                ) : (
                  ""
                )}
              </div>

              <div className="col-sm-12 col-md-6">
                <label className="form-labels">Device Category Long Description*</label>

                <FormGroup id="deviceCategoryLongDesc">
                  <Textarea
                    rows={5}
                    placeholder="Device Category Long Description"
                    className={`${
                      isInvalidForm && !values.deviceCategoryLongDesc
                        ? "border border-danger  rounded-2  w-100  "
                        : " rounded-2 w-100  "
                    }`}
                    name="deviceCategoryLongDesc"
                    value={values.deviceCategoryLongDesc}
                    onChange={handleChange}
                  />
                  <div>
                    {isInvalidForm && !values.deviceCategoryLongDesc && (
                      <p className="text-danger ms-2">
                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                      </p>
                    )}
                  </div>
                </FormGroup>
              </div>

              <div className="col-sm-12 col-md-6">
                <label className="form-labels">Device Category Short Description*</label>

                <FormGroup id="deviceCategoryShortDesc">
                  <Textarea
                    rows={5}
                    placeholder="Device Category Short Description"
                    className={`${
                      isInvalidForm && !values.deviceCategoryShortDesc
                        ? "border border-danger "
                        : ""
                    }`}
                    name="deviceCategoryShortDesc"
                    value={values.deviceCategoryShortDesc}
                    onChange={handleChange}
                  />
                  <div>
                    {isInvalidForm && !values.deviceCategoryShortDesc && (
                      <p className="text-danger ms-2">
                        <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                      </p>
                    )}
                  </div>
                </FormGroup>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className={`w-25 mt-5 process-btn ${
                  isLoading ? "disabled-button" : "bg-info text-white"
                }`}
                onClick={handleSave}
                isDisable={isLoading}
              >
                {deviceCategoryId ? CONSTANT.UPDATE : CONSTANT.ADD} Device Category
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-25 mt-5 cancel-btn float-end"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddEditDeviceCategory;
