import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { ChapterService, CourseService } from "../../../services";
import * as yup from "yup";
import Select from "react-select";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditChapter = () => {

    const { setChapterData, chapterData,setClassData,classData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [chapterId, setChapterId] = useState("");

    const [isLoading, setIsLoading] = useState(false);

    const [selectedClassName, setSelectedClassName] = useState<
        { id: string; label: string; value: string }[]
    >([]);
    const [selectedClassId, setSelectedClassId] = useState<any>([]);
    const [selectedClassArr, setSelectedClassArr] = useState<any>([]);

    const [chapterAllData, setChapterAllData] = useState({
        chapterTitle: "",
        chapterShortDescription: "",
        chapterLongDescription: "",
        chapterCount: "",
        classes: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setChapterId(decryptData(params.id));
                getChapterById(decryptData(params.id));
            }
        };
        init();
        if(classData.length === 0){
            getAllClassName();
        }
    }, []);

    const getAllClassName = async () => {
        try {
          let data: any = await ChapterService.getAllClassName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.classes;
            setClassData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
      };

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        chapterTitle: yup.string().required(" required"),
        chapterShortDescription: yup.string().required("required"),
        chapterLongDescription: yup.string().required("required"),
        chapterCount: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...chapterAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = chapterId ? await ChapterService.editChapter(chapterId, { ...values,classes: selectedClassArr, }) : await ChapterService.addChapter({ ...values,classes: selectedClassArr, });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const chapterName = await CourseService.getAllChapterName();
                        setChapterData(chapterName?.data?.chapters);
                        action.resetForm();

                        navigate(ROUTING_CONSTANT.CHAPTER_VIEW);

                        const { _id, chapterTitle } = data.data;

                        if (chapterId) {
                            const existingIndex = chapterData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setChapterData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, chapterTitle };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    useEffect(() => {
        const data = selectedClassName
            ? selectedClassName.map((item: any) => ({
                value: item.value,
                label: item.label,
            }))
            : [];
        setSelectedClassId(data);
    }, [selectedClassName]);

    const getChapterById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await ChapterService.getChapterById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setChapterAllData(response?.data?.chapter);
                setValues({ ...response?.data?.chapter });

                const selectedClassNames = response.data.chapter.classes.map(
                    (classe: any) => ({
                        id: classe._id,
                        label: classe.classTitle,
                    })
                );

                setSelectedClassName(selectedClassNames);

                const classIds = selectedClassNames.map((classe: any) => classe.id);
                setSelectedClassArr(classIds);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.CHAPTER_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {chapterId ? CONSTANT.UPDATE : CONSTANT.ADD} Chapter
                        </strong>{" "}
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Chapter Count*</label>

                                <FormGroup id="chapterCount">
                                    <Input
                                        placeholder="Chapter Count"
                                        className={`${isInvalidForm && !values.chapterCount
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="chapterCount"
                                        type="number"
                                        value={values.chapterCount}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.chapterCount && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.COUNT}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Chapter Title*</label>

                                <FormGroup id="chapterTitle">
                                    <Input
                                        placeholder="Chapter Title"
                                        className={`${isInvalidForm && !values.chapterTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="chapterTitle"
                                        value={values.chapterTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.chapterTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">
                                    {" "}
                                    Chapter Long Description*
                                </label>

                                <FormGroup id="longDescription">
                                    <Input
                                        placeholder="Enter content for long description"
                                        className={`${isInvalidForm && !values.chapterLongDescription
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="chapterLongDescription"
                                        value={values.chapterLongDescription}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.chapterLongDescription && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">
                                    Chapter Short Description*
                                </label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter content for short description"
                                        className={`${isInvalidForm && !values.chapterShortDescription
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="chapterShortDescription"
                                        value={values.chapterShortDescription}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.chapterShortDescription && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6" key="class">
                                <label className="form-labels">Select Classes</label>
                                <FormGroup id="class">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const classes = selectedOptions.map((classe: any) => ({
                                                id: classe.value,
                                                label: classe.label,
                                            }));
                                            const classIds = selectedOptions.map(
                                                (classe: any) => classe.value
                                            );

                                            setSelectedClassArr(classIds);
                                            setSelectedClassName(classes);
                                        }}
                                        options={
                                            classData &&
                                            classData.map((classe: any) => ({
                                                value: classe._id,
                                                label: classe.classTitle,
                                                key: classe._id,
                                            }))
                                        }
                                        value={selectedClassId}
                                        placeholder="Select Classes"
                                        isSearchable={true}
                                        isMulti
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6"></div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {chapterId ? CONSTANT.UPDATE : CONSTANT.ADD} Chapter
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditChapter;
