import httpCommon from '../core/http-common';
import { logoutUser } from '../core/auth.service';
import { API_URL } from '../core/constant';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const AssessmentQuestionService = {
    getAllAssessmentQuestion,
    deleteAssessmentQuestionById,
    getAssessmentQuestionById,
    updateAssessmentQuestionById,
    createAssessmentQuestion,
    getAllAssessmentQuestionName
};

function getAllAssessmentQuestion(data: any) {
    return httpCommon.post(API_URL.GET_ALL_ASSESSMENT_QUESTION, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteAssessmentQuestionById(id: any) {
    return httpCommon.post(API_URL.DELETE_ASSESSMENT_QUESTION_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAssessmentQuestionById(id: any) {
    return httpCommon.get(API_URL.GET_ASSESSMENT_QUESTION_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function updateAssessmentQuestionById(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_ASSESSMENT_QUESTION_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function createAssessmentQuestion(data: any) {
    return httpCommon.post(API_URL.CREATE_ASSESSMENT_QUESTION, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllAssessmentQuestionName() {
    return httpCommon.get(API_URL.GET_ALL_ASSESSMENT_QUESTION_NAME).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
