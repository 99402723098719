import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
    SHOW_CONFIRM_DIALOG,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { PrivacyPolicyService } from "../../../services/privacyPolicy.service";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { Draggable, Droppable, DragDropContext } from "@hello-pangea/dnd";

const AddEditPrivacyPolicy = () => {
    const { privacyPolicyData, setPrivacyPolicyData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [privacyPolicyId, setPrivacyPolicyId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [inputData, setInputData] = useState({ title: "", subtitle: "", paragraph: "" });
    const [editIndex, setEditIndex] = useState(null);

    const [privacyPolicyAllData, setPrivacyPolicyAllData] = useState({
        policyName: "",
        policyShortDescription: "",
        policyLongDescription: "",
        options: [{ title: "", subtitle: "", paragraph: "" }]
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setPrivacyPolicyId(decryptData(params.id));
                getPrivacyPolicyById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const validationSchema = yup.object().shape({
        policyName: yup.string().required("Name is required"),
        policyShortDescription: yup.string().required("required"),
        policyLongDescription: yup.string().required("required"),
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...privacyPolicyAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = privacyPolicyId ? await PrivacyPolicyService.updatePrivacyPolicyById(privacyPolicyId, { ...values, }) : await PrivacyPolicyService.createPrivacyPolicy({ ...values, });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.PRIVACY_POLICY_VIEW);

                    const PrivacyPolicyName = await PrivacyPolicyService.getAllPrivacyPolicyName();
                    setPrivacyPolicyData(PrivacyPolicyName?.data?.PrivacyPolicy);

                    const { _id, name } = data.data;
                    if (privacyPolicyId) {
                        const existingIndex = privacyPolicyData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setPrivacyPolicyData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, name };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                setIsLoading(false);
                toast.error(error.response.data.error);
            }
        },
    });

    const getPrivacyPolicyById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await PrivacyPolicyService.getPrivacyPolicyById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setPrivacyPolicyAllData(response?.data?.PrivacyPolicy);
                setValues({ ...response?.data?.PrivacyPolicy });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.PRIVACY_POLICY_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    const addOrUpdateTerm = () => {
        if (inputData.title.trim() === "" || inputData.subtitle.trim() === "" || inputData.paragraph.trim() === "") {
            toast.error(CONSTANT.FILL_OPTIONS);
            return;
        } else {
            const removeEmptyObjects = (obj: any) => {
                const filtered = Object.fromEntries(
                    Object.entries(obj).filter(([_, value]: any) => value.trim() !== "")
                );
                return Object.keys(filtered).length > 0 ? filtered : null;
            };

            const newTerm = removeEmptyObjects(inputData);
            if (newTerm === null) {
                toast.error(CONSTANT.FILL_OPTIONS);
                return;
            }
            const updatedOptions = editIndex !== null
                ? values.options.map((option: any, index: any) => {
                    return (
                        index === editIndex
                            ? { ...option, ...newTerm }
                            : option
                    );
                })
                : [...values.options, newTerm];

            const filteredOptions = updatedOptions.filter((option: any) => {
                return option.title !== "" || option.subtitle !== "" || option.paragraph !== "";
            });

            setValues({
                ...values,
                options: filteredOptions,
            });

            setInputData({
                title: "",
                subtitle: "",
                paragraph: ""
            });

            setEditIndex(null);
        }
    };

    const editTerm = (index: any) => {
        setInputData(values.options[index]);
        setEditIndex(index);
    };

    const removeTerm = (index: any) => {
        SHOW_CONFIRM_DIALOG(
            CONSTANT.DELETE_CONFIRMATION,
            async (confirmed: any) => {
                if (confirmed) {
                    try {
                        const updatedOptions = values.options.filter((_: any, i: any) => i !== index);
                        setValues({
                            ...values,
                            options: updatedOptions,
                        });
                    } catch (error: any) {
                        toast.error(error?.response?.data?.error);
                    }
                }
            }
        );
    };

    const handleDragEnd = (result: any) => {
        if (!result.destination) return;

        const reorderedOptions = Array.from(values.options);
        const [removed] = reorderedOptions.splice(result.source.index, 1);
        reorderedOptions.splice(result.destination.index, 0, removed);

        setValues({
            ...values,
            options: reorderedOptions,
        });
    };

    const icon = editIndex !== null ? 'edit' : 'add';
    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{privacyPolicyId ? CONSTANT.UPDATE : CONSTANT.ADD} Privacy Policy</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Privacy Policy Name*</label>

                                <FormGroup id="classTitle">
                                    <Input
                                        placeholder="Enter Privacy Policy Name"
                                        className={`${isInvalidForm && !values.policyName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="policyName"
                                        value={values.policyName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.policyName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Privacy Policy Short Description*</label>

                                <FormGroup id="classDescription">
                                    <Input
                                        placeholder="Enter Privacy Policy Short Description"
                                        className={`${isInvalidForm && !values.policyShortDescription
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="policyShortDescription"
                                        value={values.policyShortDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.policyShortDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <label className="form-labels">
                                    Privacy Policy Long Description*
                                </label>

                                <FormGroup id="classDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter Privacy Policy Long Description"
                                        className={`${isInvalidForm && !values.policyLongDescription
                                            ? "border border-danger "
                                            : ""
                                            }`}
                                        name="policyLongDescription"
                                        value={values.policyLongDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.policyLongDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <div>
                                    <>
                                        <label className="form-labels">Privacy Policy Items</label>
                                        <Button
                                            icon={icon}
                                            className="edit-icon"
                                            data-bs-toggle="tooltip"
                                            data-bs-placement="bottom"
                                            title="Edit"
                                            onClick={addOrUpdateTerm}
                                        ></Button>
                                    </>
                                </div>
                                <div>
                                    <div className="d-flex gap-2 mb-2">
                                        <Input
                                            type="text"
                                            name="title"
                                            placeholder="Title"
                                            value={inputData.title}
                                            onChange={(e: any) => setInputData({ ...inputData, title: e.target.value })}
                                            className="input-form"
                                        />
                                        <Input
                                            type="text"
                                            name="subtitle"
                                            placeholder="Subtitle"
                                            value={inputData.subtitle}
                                            onChange={(e: any) => setInputData({ ...inputData, subtitle: e.target.value })}
                                            className="input-form"
                                        />
                                    </div>
                                    <Textarea
                                        rows={5}
                                        name="paragraph"
                                        placeholder="Paragraph"
                                        value={inputData.paragraph}
                                        onChange={(e: any) => setInputData({ ...inputData, paragraph: e.target.value })}
                                    // className="input-form"
                                    />
                                </div>
                            </div>
                            <div className="col-md-12 col-sm-12">
                                <DragDropContext onDragEnd={handleDragEnd}>
                                    <Droppable droppableId="droppable">
                                        {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                                {values.options.map((term: any, index: any) => {
                                                    return (
                                                        <Draggable key={index} draggableId={`draggable-${index}`} index={index}>
                                                            {(provided) => (
                                                                <div
                                                                    ref={provided.innerRef}
                                                                    {...provided.draggableProps}
                                                                    {...provided.dragHandleProps}
                                                                    className="col-md-10"
                                                                >
                                                                    {term.title?.trim() !== "" || term.subtitle?.trim() !== "" || term.paragraph?.trim() !== "" ?
                                                                        <div className="d-flex col-sm-12 col-md-12 text-muted justify-content-between m-2 font-weight-bold gap-3">
                                                                            <th className="text-align font-weight-bold text-dark">{index + 1}.</th>
                                                                            <th className="text-muted font-weight-bold col-md-2 col-sm-2 text-align">{term?.title ? term?.title : "N/A"}</th>
                                                                            <th className="text-muted font-weight-bold col-md-3 col-sm-3 text-align">{term.subtitle ? term.subtitle : "N/A"}</th>
                                                                            <th className="text-muted font-weight-bold col-md-8 col-sm-8 text-align">{term.paragraph ? term.paragraph : "N/A"}</th>
                                                                            <th className="text-end d-flex">
                                                                                <Button
                                                                                    icon="Edit"
                                                                                    className="edit-icon"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="bottom"
                                                                                    title="Edit"
                                                                                    onClick={() => editTerm(index)}
                                                                                ></Button>
                                                                                <Button
                                                                                    icon="Delete"
                                                                                    className="delete-icon"
                                                                                    data-bs-toggle="tooltip"
                                                                                    data-bs-placement="bottom"
                                                                                    title="Delete"
                                                                                    onClick={() => removeTerm(index)}
                                                                                ></Button>

                                                                            </th>
                                                                        </div> : null}
                                                                </div>
                                                            )}
                                                        </Draggable>
                                                    )
                                                }
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </DragDropContext>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {privacyPolicyId ? CONSTANT.UPDATE : CONSTANT.ADD} Privacy Policy
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn  float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditPrivacyPolicy;
