import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { KeywordService } from "../../../services/keyword.service";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { ProductService } from "../../../services";

const AddEditKeyword = () => {
    const { keywordData, setKeywordData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [keywordId, setKeywordId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [keywordAllData, setKeywordAllData] = useState({
        name: "",
        keyWordIconUrl: "",
        keywordDescription: "",
        keyWordIcon: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setKeywordId(decryptData(params.id));
                getKeywordById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const validationSchema = yup.object().shape({
        name: yup.string().required("Name is required"),
        keyWordIconUrl: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        keyWordIcon: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        keywordDescription: yup.string().required("Short Description is required"),
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...keywordAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                    try {
                        setIsLoading(true);
                        const data = keywordId ? await KeywordService.editKeyword(keywordId, { ...values,}) : await KeywordService.addKeyword({ ...values, });
                        setIsLoading(false);
    
                        if (data && data.status === CONSTANT.SUCCESS) {
                            toast.success(data.message);
                            const keywordName = await ProductService.getAllKeywordName();
                            setKeywordData(keywordName.data?.keywords);
                            action.resetForm();
                            navigate(ROUTING_CONSTANT.KEYWORD_VIEW);
    
                            const { _id, name } = data.data;
                            if (keywordId) {
                                const existingIndex = keywordData.findIndex((item: any) => item._id === _id);
                                if (existingIndex !== -1) {
                                    setKeywordData((prevData: any) => {
                                        const newData = [...prevData];
                                        newData[existingIndex] = { _id, name };
                                        return newData;
                                    });
                                }
                            } 
                        } else {
                            toast.error(data.message);
                        }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getKeywordById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await KeywordService.getKeywordById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setKeywordAllData(response?.data?.keyWord);
                setValues({ ...response?.data?.keyWord });
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.KEYWORD_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {keywordId ? CONSTANT.UPDATE : CONSTANT.ADD} Keyword
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="keyWordName" label="KeyWord Name*" isFloating>
                                    <Input
                                        placeholder="KeyWord Name"
                                        className={`${isInvalidForm && !values.name
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="name"
                                        value={values.name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.name && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="keywordDescription"
                                    label="KeyWord Description*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="KeyWord Description"
                                        className={`${isInvalidForm && !values.keywordDescription
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="keywordDescription"
                                        value={values.keywordDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.keywordDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="keyWordImg" label="KeyWord Icon URL*" isFloating>
                                    <Input
                                        placeholder="KeyWord Icon URL"
                                        className={`${isInvalidForm && !values.keyWordIconUrl
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="keyWordIconUrl"
                                        value={values.keyWordIconUrl}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.keyWordIconUrl) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.ICON}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="keyWordImg" label="KeyWord Icon *" isFloating>
                                    <Input
                                        placeholder="KeyWord Icon "
                                        className={`${isInvalidForm && !values.keyWordIcon
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        name="keyWordIcon"
                                        value={values.keyWordIcon}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.keyWordIcon) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.ICON}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={handleSave}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {keywordId ? CONSTANT.UPDATE : CONSTANT.ADD} Keyword
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5  cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditKeyword;
