import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";
import { AssessmentQuestionService } from "../../../services";
import { Switch } from "@material-ui/core";
import Swal, { SweetAlertOptions } from "sweetalert2";

const AddEditAssessmentQuestion = () => {
    const { setAssessmentQuestionData, assessmentQuestionData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [assessmentQuestionId, setAssessmentQuestionId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedAssessmentName, setSelectedAssessmentName] = useState("");
    const [selectedAssessmentId, setSelectedAssessmentId] = useState("");
    const [assessmentQuestionAllData, setAssessmentQuestionAllData] = useState({
        assessmentQuestionName: "",
        assessmentQuestionNumber: "",
        assessmentQuestionPrompt: "",
        assessmentAnswerType: "",
        followUpQuestionId: "",
        followUpToggle: CONSTANT.YES,
        options: [{ key: "", value: "" }],
    });
    const [toggleState, setToggleState] = useState(assessmentQuestionAllData.followUpToggle === CONSTANT.YES);

    const handleToggleChange = (event: any) => {
        const isChecked = event.target.checked;
        setAssessmentQuestionAllData((prevState: any) => ({
            ...prevState,
            followUpToggle: isChecked ? CONSTANT.YES : CONSTANT.NO
        }));
        setToggleState(isChecked);
    };    

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setAssessmentQuestionId(decryptData(params.id));
                getAssessmentQuestionById(decryptData(params.id));
            }
        };
        init();
        if (assessmentQuestionData?.length === 0) {
            getAllAssessmentQuestionName();
        }
    }, []);

    const filteredOptions = assessmentQuestionData?.filter((assessmentQuestionName: any) => {
        return assessmentQuestionName._id !== assessmentQuestionId;
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        assessmentQuestionName: yup.string().required(" required"),
        assessmentQuestionNumber: yup.number().required(" required"),
        assessmentQuestionPrompt: yup.string().required(" required"),
        assessmentAnswerType: yup.string().required(" required"),
    });

    const getAllAssessmentQuestionName = async () => {
        try {
            let data: any = await AssessmentQuestionService.getAllAssessmentQuestionName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.AssessmentQuestion;
                setAssessmentQuestionData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const SHOW_CONFIRM_DIALOG = (message: any, callback: any) => {
        Swal.fire({
            iconColor: "black",
            className: "alert",
            title: message,
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#c12424",
            cancelButtonColor: "black",
            confirmButtonText: "Yes",
            width: "30rem",
            height: "18rem",
        } as SweetAlertOptions).then((result: any) => {
            if (result.value) {
                callback(result.value);
            } else {
                callback(false);
            }
        });
    };

    const handleAddOption = () => {
        const isValidOption = (option: any, index: any) => {
            if (values.assessmentAnswerType === CONSTANT.DROPDOWN && index === 0) {
                return option.value !== "";
            }
            return option.key !== "" && option.value !== "";
        };

        if (values?.options?.every(isValidOption)) {
            const newOption = { key: "", value: "" };
            setValues((prevValues) => ({
                ...prevValues,
                options: [...prevValues?.options, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };


    const handleRemoveOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.options];
            if (assessmentQuestionId && updatedOptions[index].key === CONSTANT.PLACEHOLDER) {
                return { ...prevValues };
            }
            updatedOptions.splice(index, 1);
            return { ...prevValues, options: updatedOptions };
        });
    };

    const handleChangeField = (e: any) => {
        const { name, value } = e.target;
        setValues((prevValues) => ({
            ...prevValues,
            [name]: value,
            ...(name === 'assessmentAnswerType' && { options: [{ key: "", value: "" }] })
        }));
    
        if (name === 'assessmentAnswerType') {
            setSelectedAssessmentName("");
            setSelectedAssessmentId("");
            setToggleState(assessmentQuestionAllData.followUpToggle === CONSTANT.YES);
        }
    };

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...assessmentQuestionAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    if (values.assessmentAnswerType !== CONSTANT.YESNO) {
                        if (!values.options || values.options.length === 0) {
                            toast.error(CONSTANT.VALID_OPTIONS);
                            setIsLoading(false);
                            return false;
                        }
                        if (values.assessmentAnswerType === CONSTANT.DROPDOWN) {
                            if (values.options[0]?.value === "") {
                                toast.error(CONSTANT.VALID_OPTIONS);
                                setIsLoading(false);
                                return false;
                            }
                            if (values.options[0]?.key === "") {
                                values.options[0].key = CONSTANT.PLACEHOLDER;
                            }
                        } else {
                            for (const option of values.options) {
                                if (!option.key || !option.value) {
                                    toast.error(CONSTANT.VALID_OPTIONS);
                                    setIsLoading(false);
                                    return false;
                                }
                            }
                        }
                    } else {
                        values.options = [];
                    }
                    const followUpToggleValue = values.assessmentAnswerType === CONSTANT.YESNO
                        ? (toggleState ? CONSTANT.YES : CONSTANT.NO)
                        : (values.assessmentAnswerType === CONSTANT.MULTIOPTIONS || values.assessmentAnswerType === CONSTANT.DROPDOWN
                            ? ''
                            : assessmentQuestionAllData.followUpToggle);
                    const followUpQuestionId = (values.assessmentAnswerType === CONSTANT.MULTIOPTIONS || values.assessmentAnswerType === CONSTANT.DROPDOWN)
                        ? ''
                        : selectedAssessmentId;

                    const payload = {
                        ...values,
                        followUpToggle: followUpToggleValue,
                        followUpQuestionId: followUpQuestionId,
                        options: values.options
                    };
                    const data = assessmentQuestionId
                        ? await AssessmentQuestionService.updateAssessmentQuestionById(assessmentQuestionId, payload)
                        : await AssessmentQuestionService.createAssessmentQuestion(payload);

                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        navigate(ROUTING_CONSTANT.ASSESSMENT_QUESTION_VIEW);
                        const AssessmentQuestionName = await AssessmentQuestionService.getAllAssessmentQuestionName();
                        setAssessmentQuestionData(AssessmentQuestionName?.data?.AssessmentQuestion);
                        action.resetForm();

                        const { _id, assessmentQuestionName } = data.data;
                        if (assessmentQuestionId) {
                            const existingIndex = assessmentQuestionData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setAssessmentQuestionData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, assessmentQuestionName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();
    const getAssessmentQuestionById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await AssessmentQuestionService.getAssessmentQuestionById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setAssessmentQuestionAllData(response?.data?.AssessmentQuestion);
                setValues({ ...response?.data?.AssessmentQuestion });
                setSelectedAssessmentId(response?.data?.AssessmentQuestion?.followUpQuestionId);
                setSelectedAssessmentName(response?.data?.AssessmentQuestion?.followUpQuestionDetails?.assessmentQuestionName);
                setToggleState(response?.data?.AssessmentQuestion.followUpToggle === CONSTANT.YES);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.ASSESSMENT_QUESTION_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{assessmentQuestionId ? CONSTANT.UPDATE : CONSTANT.ADD} Assessment Question</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment Question Name*</label>

                                <FormGroup id="assessmentQuestionName">
                                    <Input
                                        placeholder="Assessment Question Name"
                                        className={`${isInvalidForm && !values.assessmentQuestionName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentQuestionName"
                                        value={values.assessmentQuestionName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentQuestionName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment Question Number*</label>

                                <FormGroup id="assessmentQuestionNumber">
                                    <Input
                                        placeholder="Assessment Question Number"
                                        className={`${isInvalidForm && !values.assessmentQuestionNumber
                                            ? "border input-form border-danger"
                                            : "input-form"
                                            }`}
                                        name="assessmentQuestionNumber"
                                        value={values.assessmentQuestionNumber}
                                        type='number'
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentQuestionNumber && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.INTRO}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Assessment Question Prompt*</label>

                                <FormGroup id="assessmentQuestionPrompt">
                                    <Input
                                        placeholder="Assessment Question Prompt"
                                        className={`${isInvalidForm && !values.assessmentQuestionPrompt
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentQuestionPrompt"
                                        value={values.assessmentQuestionPrompt}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentQuestionPrompt && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Assessment Question Answer Type*</label>

                                <FormGroup id="assessmentAnswerType">
                                    <select
                                        className={`${isInvalidForm && !values.assessmentAnswerType ? "border border-danger form-select form-select-lg input-form" : "form-select form-select-lg input-form"}`}
                                        name="assessmentAnswerType"
                                        value={values.assessmentAnswerType}
                                        onChange={(e) => {
                                            const newValue = e.target.value;
                                            if (assessmentQuestionId && values.assessmentAnswerType !== newValue) {
                                                e.preventDefault();
                                                SHOW_CONFIRM_DIALOG(
                                                    CONSTANT.TYPE_CHANGE_CONFIRMATION,
                                                    async (confirmed: any) => {
                                                        if (confirmed) {
                                                            handleChangeField({
                                                                target: {
                                                                    name: e.target.name,
                                                                    value: newValue
                                                                }
                                                            });
                                                        }
                                                    }
                                                );
                                            } else {
                                                handleChangeField(e);
                                            }
                                        }}>
                                        <option value="" disabled>Select Answer Type</option>
                                        <option value={CONSTANT.YESNO}>Yes/No</option>
                                        <option value={CONSTANT.MULTIOPTIONS}>Multiple Option</option>
                                        <option value={CONSTANT.DROPDOWN}>Dropdown</option>
                                    </select>
                                    <div>
                                        {isInvalidForm && !values.assessmentAnswerType && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.TYPE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>
                            {values.assessmentAnswerType !== CONSTANT.YESNO ? <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <label className="form-labels">Options</label>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                        </div>
                                    </>
                                    <div className="mt-2">
                                        {values?.options?.map((option, index) => (
                                            <div key={index} className="mt-2">
                                                <div className="d-flex gap-2 justify-content-center align-items-center">
                                                    {values.assessmentAnswerType !== CONSTANT.DROPDOWN ? (
                                                        <Input
                                                            placeholder="Option Name"
                                                            className={`${isInvalidForm && !option.key ? "border border-danger input-form" : "input-form"}`}
                                                            value={option.key}
                                                            name={`options[${index}].key`}
                                                            onChange={handleChange}
                                                        />
                                                    ) : (
                                                        <Input
                                                            placeholder={index === 0 ? CONSTANT.PLACEHOLDER : "Option Name"}
                                                            className={`${isInvalidForm && !option.key && index !== 0 ? "border border-danger input-form" : "input-form"}`}
                                                            value={option.key === "" && index === 0 ? CONSTANT.PLACEHOLDER : option.key}
                                                            name={`options[${index}].key`}
                                                            onChange={handleChange}
                                                            disabled={index === 0 && !!assessmentQuestionId}
                                                        />
                                                    )}
                                                    <Input
                                                        placeholder="Option Value"
                                                        className={`${isInvalidForm && !option.value ? "border border-danger input-form" : "input-form"}`}
                                                        value={option.value}
                                                        name={`options[${index}].value`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className={`bi bi-dash-circle sku-icon cursor-pointer ${
                                                            option.key === CONSTANT.PLACEHOLDER ? "disabled" : ""
                                                        } ${values.assessmentAnswerType === CONSTANT.DROPDOWN && index === 0 ? "text-muted" : ""}`}
                                                        viewBox="0 0 16 16"
                                                        onClick={() => {
                                                            if (index === 0 && values.assessmentAnswerType === CONSTANT.DROPDOWN) {
                                                                return;
                                                            }
                                                            handleRemoveOption(index);
                                                        }}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                                {isInvalidForm && values?.options?.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.OPTION}</small>
                                    </p>
                                )}
                            </div> :
                                <>
                                    <div className="col-sm-12 col-md-6 d-flex flex-column">
                                        <label className="form-labels">Follow-up Question</label>
                                        <Switch
                                            checked={toggleState}
                                            onChange={handleToggleChange}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            color='primary'
                                        />
                                    </div>
                                    <div className="col-sm-12 col-md-6" key="questions">
                                        <label className="form-labels">Select Follow-up Question</label>
                                        <FormGroup id="followupquestion">
                                            <Select
                                                styles={customStyles}
                                                onChange={(selectedOption: any) => {
                                                    setSelectedAssessmentId(
                                                        selectedOption ? selectedOption.value : ""
                                                    );
                                                    setSelectedAssessmentName(
                                                        selectedOption ? selectedOption.label : ""
                                                    );
                                                }}
                                                options={filteredOptions?.map((questions: any) => ({
                                                    value: questions._id,
                                                    label: questions.assessmentQuestionName,
                                                }))}
                                                placeholder="Select Follow-up Question"
                                                value={selectedAssessmentName
                                                    ? {
                                                        value: selectedAssessmentId,
                                                        label: selectedAssessmentName,
                                                    }
                                                    : null}
                                                className={`${isInvalidForm && !selectedAssessmentName
                                                    ? "border"
                                                    : ""}`}
                                                isSearchable={true} />
                                        </FormGroup>
                                    </div>
                                </>
                            }
                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {assessmentQuestionId ? CONSTANT.UPDATE : CONSTANT.ADD} Assessment Question
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditAssessmentQuestion;
