import { useContext, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import "../User/user.scss";

import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { MarketingService } from "../../../services/marketing.service";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import { ProductService } from "../../../services";

const AddEditMarketing = () => {
    const { marketData, setMarketData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [marketingId, setMarketingId] = useState("");
    const [marketingData, setMarketingData] = useState({
        marketingName: "",
        marketingImage: "",
        marketingTitle: "",
        marketingIntro: "",
        marketingDescription: "",
        marketingHelps: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const validationSchema = yup.object().shape({
        marketingName: yup.string().required("required"),
        marketingImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        marketingTitle: yup.string().required(" required"),
        marketingIntro: yup.string().required("required"),
        marketingDescription: yup.string().required(" required"),
        marketingHelps: yup.string().required("required"),
    });

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...marketingData },
        validationSchema,

        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = marketingId ? await MarketingService.EditMarketing(marketingId, { ...values, }) : await MarketingService.addMarketing({ ...values, });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    const marketName = await ProductService.getAllMarketingName();
                    setMarketData(marketName.data?.markets);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.MARKETING_VIEW);

                    const { _id, marketingName } = data.data;
                    if (marketingId) {
                        const existingIndex = marketData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setMarketData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, marketingName };
                                return newData;
                            });
                        }
                    } 
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.response.data.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setMarketingId(decryptData(params.id));
                getMarketingById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getMarketingById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await MarketingService.getMarketingById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setMarketingData(response?.data?.market);
                setValues({ ...response?.data?.market });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.MARKETING_VIEW);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {marketingId ? CONSTANT.UPDATE : CONSTANT.ADD} Marketing
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Marketing Name*</label>

                                <FormGroup id="marketingName">
                                    <Input
                                        placeholder="Marketing Name"
                                        className={`${isInvalidForm && !values.marketingName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="marketingName"
                                        value={values.marketingName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.marketingName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Marketing Image*</label>

                                <FormGroup id="marketingImage">
                                    <Input
                                        placeholder="Marketing Image"
                                        className={`${isInvalidForm && !values.marketingImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="marketingImage"
                                        value={values.marketingImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.marketingImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Marketing Title*</label>

                                <FormGroup id="marketingTitle">
                                    <Input
                                        placeholder="Marketing Title"
                                        className={`${isInvalidForm && !values.marketingTitle
                                            ? "border border-danger  input-form"
                                            : "input-form"
                                            }`}
                                        name="marketingTitle"
                                        value={values.marketingTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.marketingTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Marketing Intro*</label>

                                <FormGroup id="marketingIntro">
                                    <Input
                                        placeholder="Marketing Intro"
                                        className={`${isInvalidForm && !values.marketingIntro
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="marketingIntro"
                                        value={values.marketingIntro}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.marketingIntro && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.INTRO}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Marketing Description*</label>

                                <FormGroup id="longDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for long description"
                                        className={`${isInvalidForm && !values.marketingDescription
                                            ? "border border-danger  "
                                            : ""
                                            }`}
                                        name="marketingDescription"
                                        value={values.marketingDescription}
                                        onChange={handleChange}
                                    />
                                    <div className="mt-3">
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                                {isInvalidForm && !values.marketingDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Marketing Help*</label>

                                <FormGroup id="marketingHelps">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for marketing helps"
                                        className={`${isInvalidForm && !values.marketingHelps
                                            ? "border border-danger  "
                                            : ""
                                            }`}
                                        name="marketingHelps"
                                        value={values.marketingHelps}
                                        onChange={handleChange}
                                    />
                                    <div className="mt-3">
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                                {isInvalidForm && !values.marketingHelps && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {marketingId ? CONSTANT.UPDATE : CONSTANT.ADD} Marketing
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditMarketing;
