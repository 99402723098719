import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    ROUTING_CONSTANT,
    isValidVideoUrl,
    isValidUrl,
} from "../../../core/constant";
import { CourseService } from "../../../services/course.service";
import * as yup from "yup";
import Select from "react-select";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { TrainerService } from "../../../services";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditCourse = () => {
    const { trainerData, chapterData,setTrainerData,setCourseData,courseData,setChapterData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [courseId, setCourseId] = useState("");

    const [selectedTrainerName, setSelectedTrainerName] = useState("");
    const [selectedTrainerId, setSelectedTrainerId] = useState("");

    const [selectedChapterName, setSelectedChapterName] = useState<
        { id: string; label: string }[]
    >([]);
    const [selectedChapterId, setSelectedChapterId] = useState<any>([]);
    const [selectedChapterArr, setSelectedChapterArr] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);


    const [courseAllData, setCourseAllData] = useState({
        courseTitle: "",
        courseSubtitle: "",
        coursePrice: "",
        allCoursePrice: "",
        courseThumbImage: "",
        courseHoverVideo: "",
        courseIntroVideo: "",
        courseCardTitle: "",
        coursePriceText: "",
        allCoursePriceText: "",
        courseExpertId: "",
        courseBenefit1Icon: "",
        courseBenefit1Name: "",
        courseBenefit1Description: "",
        courseBenefit2Icon: "",
        courseBenefit2Name: "",
        courseBenefit2Description: "",
        courseLongTitle: "",
        courseShortTitle: "",
        courseBenefits: "",
        courseWhoTitleText: "",
        courseWhoTitleSubtext: "",
        courseWhoText1: "",
        courseWhoText2: "",
        courseWhoText3: "",
        courseWhoText4: "",
        courseWhyTitle: "",
        courseText1: "",
        courseText2: "",
        courseText3: "",
        courseWatchTitle: "",
        courseWatchSubtitle: "",
        courseWatchImage: "",
        chapters: "",
        tags: "",
        hours: "",
        minutes: "",
        seconds: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setCourseId(decryptData(params.id));
                getCourseById(decryptData(params.id));
            }
        };
        init();
        if(trainerData?.length === 0){
            getAllTrainerName();
        }
        if(chapterData?.length === 0){
            getAllChapterName();
        }
    }, []);

    const getAllChapterName = async () => {
        try {
          let data: any = await CourseService.getAllChapterName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.chapters;
            setChapterData(responseData);
        } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
      };
    
      const getAllTrainerName = async () => {
        try {
          let data: any = await CourseService.getAllTrainerName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.trainersWithTotalClasses;
            setTrainerData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
      };

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        courseTitle: yup.string().required(" required"),
        courseSubtitle: yup.string().required("required"),
        coursePrice: yup.number().required("required"),
        allCoursePrice: yup.number().required("required"),
        courseBenefit2Name: yup.string().required("required"),
        courseBenefit2Description: yup.string().required("required"),
        courseLongTitle: yup.string().required("required"),
        courseShortTitle: yup.string().required("required"),
        courseHoverVideo: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),

        courseIntroVideo: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...courseAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = courseId ? await CourseService.editCourse(courseId, {
                        ...values, courseExpertId: selectedTrainerId,
                        chapters: selectedChapterArr,
                    }) : await CourseService.addCourse({
                        ...values, courseExpertId: selectedTrainerId,
                        chapters: selectedChapterArr,
                    });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const CourseName = await CourseService.getAllCourseByName();
                        setCourseData(CourseName.data?.courses);
                        action.resetForm();

                        navigate(ROUTING_CONSTANT.COURSE_VIEW);

                        const { _id, courseName } = data.data;

                        if (courseId) {
                            const existingIndex = courseData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setCourseData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, courseName };
                                    return newData;
                                });
                            }
                        } 
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);

                }
            },
        });

    useEffect(() => {
        const data = selectedChapterName
            ? selectedChapterName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];
        setSelectedChapterId(data);
    }, [selectedChapterName]);

    const getCourseById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await CourseService.getCourseById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setCourseAllData(response?.data?.course);
                setValues({ ...response?.data?.course });

                setSelectedTrainerName(
                    response?.data?.course?.courseExpertId?.trainerName
                );
                setSelectedTrainerId(response?.data?.course?.courseExpertId._id);

                const selectedChapterNames = response?.data?.course?.chapters.map(
                    (chapter: any) => ({
                        id: chapter._id,
                        label: chapter.chapterTitle,
                    })
                );
                setSelectedChapterName(selectedChapterNames);

                const chapterss = response?.data?.course?.chapters.map(
                    (chapterss: any) => chapterss._id
                );
                setSelectedChapterArr(chapterss);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.COURSE_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{courseId ? CONSTANT.UPDATE : CONSTANT.ADD} Course</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Course Title*</label>

                                <FormGroup id="courseTitle">
                                    <Input
                                        placeholder="Course Title"
                                        className={`${isInvalidForm && !values.courseTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseTitle"
                                        value={values.courseTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Sub title*</label>

                                <FormGroup id="courseSubtitle">
                                    <Input
                                        placeholder="Enter content for Sub Title"
                                        className={`${isInvalidForm && !values.courseSubtitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseSubtitle"
                                        value={values.courseSubtitle}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.courseSubtitle && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.SUB_TITLE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Price*</label>

                                <FormGroup id="coursePrice">
                                    <Input
                                        placeholder="Enter Course Price"
                                        className={`${isInvalidForm && !values.coursePrice
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="coursePrice"
                                        type="number"
                                        value={values.coursePrice}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.coursePrice && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.PRICE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">All Course Price*</label>

                                <FormGroup id="allCoursePrice">
                                    <Input
                                        placeholder="Enter All Course Price"
                                        className={`${isInvalidForm && !values.allCoursePrice
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="allCoursePrice"
                                        type="number"
                                        value={values.allCoursePrice}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.allCoursePrice && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.PRICE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Course Thumb Image URL*</label>

                                <FormGroup id="courseThumbImage">
                                    <Input
                                        placeholder="Course Thumb Image URL"
                                        className={`${isInvalidForm && !values.courseThumbImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseThumbImage"
                                        value={values.courseThumbImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.courseThumbImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Course Hover Video URL*</label>

                                <FormGroup id="courseHoverVideo">
                                    <Input
                                        placeholder="Course Hover Video URL"
                                        className={`${isInvalidForm && !values.courseHoverVideo
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseHoverVideo"
                                        value={values.courseHoverVideo}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidVideoUrl(values.courseHoverVideo) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_VIDEO}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Course Intro Video URL*</label>

                                <FormGroup id="courseIntroVideo">
                                    <Input
                                        placeholder="Course Intro Video URL"
                                        className={`${isInvalidForm && !values.courseIntroVideo
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseIntroVideo"
                                        value={values.courseIntroVideo}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidVideoUrl(values.courseIntroVideo) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_VIDEO}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Course Card Title*</label>

                                <FormGroup id="courseCardTitle">
                                    <Input
                                        placeholder="Enter content for card title"
                                        className={`${isInvalidForm && !values.courseCardTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseCardTitle"
                                        value={values.courseCardTitle}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.courseCardTitle && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.CARD_TITLE}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Course Price Text*</label>

                                <FormGroup id="coursePriceText">
                                    <Input
                                        placeholder="Course Price Text"
                                        className={`${isInvalidForm && !values.coursePriceText
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="coursePriceText"
                                        value={values.coursePriceText}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.coursePriceText && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">All Course Price Text*</label>

                                <FormGroup id="allCoursePriceText">
                                    <Input
                                        placeholder="All Course Price"
                                        className={`${isInvalidForm && !values.allCoursePriceText
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="allCoursePriceText"
                                        value={values.allCoursePriceText}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.allCoursePriceText && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="Trainers">
                                <label className="form-labels">Select Trainer*</label>

                                <FormGroup id="	Trainers" className="">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedTrainerId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedTrainerName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            trainerData &&
                                            trainerData?.map((Trainers: any) => ({
                                                value: Trainers._id,
                                                label: Trainers.trainerName,
                                            }))
                                        }
                                        placeholder="Select Trainer"
                                        value={
                                            selectedTrainerName
                                                ? {
                                                    value: selectedTrainerId,
                                                    label: selectedTrainerName,
                                                }
                                                : null
                                        }
                                        className={`${isInvalidForm && !selectedTrainerName
                                            ? "border border-danger"
                                            : "border rounded-circle"
                                            }`}
                                        isSearchable={true}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedTrainerId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TRAINER_ID}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Benefit1 Icon*</label>

                                <FormGroup id="courseBenefit1Icon">
                                    <Input
                                        placeholder="Course Benefit1 Icon"
                                        className={`${isInvalidForm && !values.courseBenefit1Icon
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefit1Icon"
                                        value={values.courseBenefit1Icon}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit1Icon && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Benefit1 Name*</label>

                                <FormGroup id="courseBenefit1Name">
                                    <Input
                                        placeholder="Course Benefit1 Name"
                                        className={`${isInvalidForm && !values.courseBenefit1Name
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefit1Name"
                                        value={values.courseBenefit1Name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit1Name && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">
                                    Course Benefit1 Description*
                                </label>

                                <FormGroup id="courseBenefit1Description">
                                    <Input
                                        placeholder="Course Benefit1 Description"
                                        className={`${isInvalidForm && !values.courseBenefit1Description
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefit1Description"
                                        value={values.courseBenefit1Description}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit1Description && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Benefit2 Icon*</label>

                                <FormGroup id="courseBenefit2Icon">
                                    <Input
                                        placeholder="Course Benefit2 Icon"
                                        className={`${isInvalidForm && !values.courseBenefit2Icon
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefit2Icon"
                                        value={values.courseBenefit2Icon}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit2Icon && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Benefit2 Name*</label>

                                <FormGroup id="courseBenefit2Name">
                                    <Input
                                        placeholder="Course Benefit2 Name"
                                        className={`${isInvalidForm && !values.courseBenefit2Name
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefit2Name"
                                        value={values.courseBenefit2Name}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit2Name && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">
                                    Course Benefit2 Description*
                                </label>

                                <FormGroup id="courseBenefit2Description">
                                    <Input
                                        placeholder="Course Benefit2 Description"
                                        className={`${isInvalidForm && !values.courseBenefit2Description
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefit2Description"
                                        value={values.courseBenefit2Description}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit2Description && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Long Title*</label>

                                <FormGroup id="courseLongTitle">
                                    <Input
                                        placeholder="Course Long Title"
                                        className={`${isInvalidForm && !values.courseLongTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseLongTitle"
                                        value={values.courseLongTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseLongTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Short Title*</label>

                                <FormGroup id="courseShortTitle">
                                    <Input
                                        placeholder="Course Short Title"
                                        className={`${isInvalidForm && !values.courseShortTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseShortTitle"
                                        value={values.courseShortTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefit2Description && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SHORT_TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Benefits*</label>

                                <FormGroup id="courseBenefits">
                                    <Input
                                        placeholder="Course Benefits"
                                        className={`${isInvalidForm && !values.courseBenefits
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseBenefits"
                                        value={values.courseBenefits}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseBenefits && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.BENEFITS}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Who Title Text*</label>

                                <FormGroup id="courseWhoTitleText">
                                    <Input
                                        placeholder="Course Who Title Text"
                                        className={`${isInvalidForm && !values.courseWhoTitleText
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhoTitleText"
                                        value={values.courseWhoTitleText}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhoTitleText && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Who Title SubText*</label>

                                <FormGroup id="courseWhoTitleSubtext">
                                    <Input
                                        placeholder="Course Who Title SubText"
                                        className={`${isInvalidForm && !values.courseWhoTitleSubtext
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhoTitleSubtext"
                                        value={values.courseWhoTitleSubtext}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhoTitleSubtext && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUB_TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Who Text1*</label>

                                <FormGroup id="courseWhoText1">
                                    <Input
                                        placeholder="Course Who Text1"
                                        className={`${isInvalidForm && !values.courseWhoText1
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhoText1"
                                        value={values.courseWhoText1}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhoText1 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Who Text2*</label>

                                <FormGroup id="courseWhoText2">
                                    <Input
                                        placeholder="Course Who Text2"
                                        className={`${isInvalidForm && !values.courseWhoText2
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhoText2"
                                        value={values.courseWhoText2}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhoText2 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Who Text3*</label>

                                <FormGroup id="courseWhoText3">
                                    <Input
                                        placeholder="Course Who Text3"
                                        className={`${isInvalidForm && !values.courseWhoText3
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhoText3"
                                        value={values.courseWhoText3}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhoText3 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Who Text4*</label>

                                <FormGroup id="courseWhoText4">
                                    <Input
                                        placeholder="Course Who Text4"
                                        className={`${isInvalidForm && !values.courseWhoText4
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhoText4"
                                        value={values.courseWhoText4}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhoText4 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Why Title*</label>

                                <FormGroup id="courseWhyTitle">
                                    <Input
                                        placeholder="Course Why Title"
                                        className={`${isInvalidForm && !values.courseWhyTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWhyTitle"
                                        value={values.courseWhyTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWhyTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Text1*</label>

                                <FormGroup id="courseText1">
                                    <Input
                                        placeholder="Course Text1"
                                        className={`${isInvalidForm && !values.courseText1
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseText1"
                                        value={values.courseText1}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseText1 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Text2*</label>

                                <FormGroup id="courseText2">
                                    <Input
                                        placeholder="Course Text2"
                                        className={`${isInvalidForm && !values.courseText2
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseText2"
                                        value={values.courseText2}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseText2 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Text3*</label>

                                <FormGroup id="courseText3">
                                    <Input
                                        placeholder="Course Text3"
                                        className={`${isInvalidForm && !values.courseText3
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseText3"
                                        value={values.courseText3}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseText3 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Watch Title*</label>

                                <FormGroup id="courseWatchTitle">
                                    <Input
                                        placeholder="Course Watch Title"
                                        className={`${isInvalidForm && !values.courseWatchTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWatchTitle"
                                        value={values.courseWatchTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWatchTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Watch SubTitle*</label>

                                <FormGroup id="courseWatchSubtitle">
                                    <Input
                                        placeholder="Course Watch SubTitle"
                                        className={`${isInvalidForm && !values.courseWatchSubtitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWatchSubtitle"
                                        value={values.courseWatchSubtitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWatchSubtitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUB_TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Watch Image*</label>

                                <FormGroup id="courseWatchImage">
                                    <Input
                                        placeholder="Course Watch Image"
                                        className={`${isInvalidForm && !values.courseWatchImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="courseWatchImage"
                                        value={values.courseWatchImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.courseWatchImage && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6" key="chapters">
                                <label className="form-labels">Select Chapters</label>
                                <FormGroup id="chapters">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions) => {
                                            const chapterss = selectedOptions.map((chapter) => ({
                                                id: chapter.value,
                                                label: chapter.label,
                                            }));
                                            const chapterIds = selectedOptions.map(
                                                (chapter) => chapter.value
                                            );
                                            setSelectedChapterArr(chapterIds);
                                            setSelectedChapterName(chapterss);
                                        }}
                                        options={
                                            chapterData?.map((chapter: any) => ({
                                                value: chapter._id,
                                                label: chapter.chapterTitle,
                                            })) ?? []
                                        }
                                        value={selectedChapterId}
                                        placeholder="Select Chapters"
                                        className={`${isInvalidForm && selectedChapterId.length === 0
                                            ? "border"
                                            : ""
                                            }`}
                                        isSearchable={true}
                                        isMulti
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Tags</label>

                                <FormGroup id="tags">
                                    <Input
                                        placeholder="Course Tag"
                                        className={`${isInvalidForm && !values.tags
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="tags"
                                        value={values.tags}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Hours</label>

                                <FormGroup id="hours">
                                    <Input
                                        placeholder="Course Hours"
                                        className={`${isInvalidForm && !values.hours
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="hours"
                                        value={values.hours}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Minutes</label>

                                <FormGroup id="minutes">
                                    <Input
                                        placeholder="Course Minutes"
                                        className={`${isInvalidForm && !values.minutes
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="minutes"
                                        value={values.minutes}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Course Seconds</label>

                                <FormGroup id="seconds">
                                    <Input
                                        placeholder="Course Seconds"
                                        className={`${isInvalidForm && !values.seconds
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="seconds"
                                        value={values.seconds}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {courseId ? CONSTANT.UPDATE : CONSTANT.ADD} Course
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditCourse;
