import httpCommon from "../core/http-common";
import { API_URL } from "../core/constant";
import { logoutUser } from "../core/auth.service";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";

export const UserDetailScreenService = {
    updateUserDetailScreenById,
    createUserDetailScreen,
    getUserDetailScreenById,
    getAllUserDetailScreen,
    deleteUserDetailScreenById,
    dragUserOrderByOrder,
};

function createUserDetailScreen(data: any) {
    return httpCommon
        .post(API_URL.ADD_USER_DETAIL_SCREEN, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function updateUserDetailScreenById(id: any, data: any) {
    return httpCommon
        .post(API_URL.UPDATE_USER_DETAIL_SCREEN_BY_ID + id, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getUserDetailScreenById(id: any) {
    return httpCommon
        .get(API_URL.GET_USER_DETAIL_SCREEN_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getAllUserDetailScreen() {
    let url = API_URL.GET_ALL_USER_DETAIL_SCREEN;
    return httpCommon.get(url).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteUserDetailScreenById(id: any) {
    return httpCommon
        .post(API_URL.DELETE_USER_DETAIL_SCREEN_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function dragUserOrderByOrder(data: any) {
    return httpCommon
        .post(API_URL.USER_DETAIL_SCREEN_DRAG_ORDER, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}
