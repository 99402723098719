import React, { lazy } from "react";
import { RouteProps } from "react-router-dom";
import { dashboardPagesMenu } from "../menu";
import Login from "../pages/presentation/auth/Login";
import AddEditProduct from "../pages/presentation/product/AddEditProduct";
import AddEditSku from "../pages/presentation/sku/AddEditSku";
import AddEditIngredient from "../pages/presentation/ingredient/AddEditIngredient";
import AddEditMarketing from "../pages/presentation/marketing/AddEditMarketing";
import AddEditDosageAssistance from "../pages/presentation/dosageAssistance/AddEditDosageAssistance";
import AddEditBenefit from "../pages/presentation/benefit/AddEditBenefit";
import AddEditFaq from "../pages/presentation/faq/AddEditFaq";
import AddEditTrainer from "../pages/presentation/trainer/AddEditTrainer";
import AddEditClass from "../pages/presentation/class/AddEditClass";
import AddEditChapter from "../pages/presentation/chapter/AddEditChapter";
import AddEditCourse from "../pages/presentation/course/AddEditCourse";
import AddEditBot from "../pages/presentation/bot/AddEditBot";
import AddEditHowItWork from "../pages/presentation/howItWork/AddEditHowItWork";
import AddEditKeyword from "../pages/presentation/keyword/AddEditKeyword";
import AddEditUser from "../pages/presentation/User/AddEditUser";
import AddEditDeliveryOptions from "../pages/presentation/deliveryOptions/AddEditDeliveryOptions";
import AddEditPromoCode from "../pages/presentation/promoCode/AddEditPromoCode";
import ResetPassword from "../pages/presentation/User/ResetPassword";
import { Dashboard } from "../components/icon/material-icons";
import VerifyUser from "../pages/presentation/User/VerifyUser";
import AddEditCourseFaq from "../pages/presentation/course_faqs/AddEditCourseFaq";
import AddEditMaster from "../pages/presentation/master/AddEditMaster";
import AddEditUserProfileCategory from "../pages/presentation//userCategory/AddEditUserProfileCategory";
import AddEditMetric from "../pages/presentation//metric/AddEditMetric";
import AddEditUserProfileSubCategory from "../pages/presentation/userSubCategory/AddEditUserProfileSubCategory";
import AddEditUserQuestion from "../pages/presentation/userProfileQuestion/AddEditUserProfileQuestion";
import AddEditCronJob from "../pages/presentation/cronJob/AddEditCronJob";
import ViewHardwareSpecific from "../pages/presentation/User/HardwareSpecific";
import ViewMetricSpecific from "../pages/presentation/User/MetricSpecific";
import ViewMedicalSpecific from "../pages/presentation/User/MedicalSpecific";
import AddEditIntro from "../pages/presentation/Mobile-intro/AddEditMobileIntro";
import AddEditUserDetailScreen from "../pages/presentation/userDetailScreen/AddEditUserDetailScreen";
import AddEditInterest from "../pages/presentation/interests/AddEditInterest";
import AddEditInterestQuestion from "../pages/presentation/interestQuestion/AddEditInterestQuestion";
import AddEditLevelOneMapping from "../pages/presentation/levelOneMapping/AddEditLevelOneMapping";
import AddEditGoal from "../pages/presentation/goal/AddEditGoal";
import { ROUTING_CONSTANT } from "../core/constant";
import AddEditLevelZeroMapping from "../pages/presentation/levelZeroMapping/AddEditLevelZeroMapping";
import AddEditLabTest from "../pages/presentation/labTest/AddEditLabTest";
import AddEditDevice from "../pages/presentation/device/AddEditDevice";
import AddEditAssessment from "../pages/presentation/assessment/AddEditAssessment";
import AddEditAssessmentQuestion from "../pages/presentation/assessmentQuestion/AddEditAssessmentQuestion";
import AddEditRecommendationRule from "../pages/presentation/recommendationRule/AddEditRecommendationRule";
import AddEditRecipe from "../pages/presentation/recipe/AddEditRecipe";
import AddEditRecipeBundle from "../pages/presentation/recipeBundle/AddEditRecipeBundle";
import AddEditNuggetsTag from "../pages/presentation/nuggetsTag/AddEditNuggetsTag";
import AddEditNuggets from "../pages/presentation/nuggets/AddEditNuggets";
import AddEditPrivacyPolicy from "../pages/presentation/privacyPolicy/AddEditPrivacyPolicy";
import AddEditTermsOfUsePolicy from "../pages/presentation/termsOfUse/AddEditTermsOfUsePolicy";
import AddEditDeviceCategory from "../pages/presentation/deviceCategory/AddEditDeviceCategory";
import AddEditDeviceSubCategory from "../pages/presentation/deviceSubCategory/AddEditDeviceSubCategory";
import AddEditDeviceModel from "../pages/presentation/deviceModel/AddEditDeviceModel";

const LANDING = {
  DASHBOARD: lazy(
    () => import("../pages/presentation/dashboard/DashboardPage")
  ),
  PRODUCT: lazy(() => import("../pages/presentation/product/Product")),
  CATEGORY: lazy(() => import(`../pages/presentation/deviceCategory/DeviceCategory`)),
  SUB_CATEGORY: lazy(
    () => import("../pages/presentation/deviceSubCategory/DeviceSubCategory")
  ),
  MODEL: lazy(
    () => import("../pages/presentation/deviceModel/DeviceModel")
  ),
  SKU: lazy(() => import("../pages/presentation/sku/Sku")),
  INGREDIANTS: lazy(
    () => import("../pages/presentation/ingredient/Ingredient")
  ),
  MARKETING: lazy(() => import("../pages/presentation/marketing/Marketing")),
  FAQ: lazy(() => import(`../pages/presentation/faq/Faq`)),
  TRAINER: lazy(() => import(`../pages/presentation/trainer/Trainer`)),
  CLASS: lazy(() => import(`../pages/presentation/class/Class`)),
  CHAPTER: lazy(() => import(`../pages/presentation/chapter/Chapter`)),
  COURSE: lazy(() => import(`../pages/presentation/course/Course`)),
  COURSE_FAQ: lazy(() => import(`../pages/presentation/course_faqs/CourseFaq`)),
  MASTER: lazy(() => import(`../pages/presentation/master/Master`)),
  DOSAGEASSISTANCE: lazy(
    () => import("../pages/presentation/dosageAssistance/DosageAssistance")
  ),
  BENEFIT: lazy(() => import(`../pages/presentation/benefit/Benefit`)),
  KEYWORD: lazy(() => import(`../pages/presentation/keyword/Keyword`)),
  HOW_IT_WORK: lazy(() => import(`../pages/presentation/howItWork/HowItWork`)),
  USER: lazy(() => import(`../pages/presentation/User/User`)),
  DELIVERY: lazy(
    () => import(`../pages/presentation/deliveryOptions/DeliveryOptions`)
  ),
  ORDERS: lazy(() => import(`../pages/presentation/orders/Order`)),
  CHATGPT: lazy(() => import(`../pages/presentation/chatGpt/ChatGpt`)),
  PROMO_CODE: lazy(() => import(`../pages/presentation/promoCode/PromoCode`)),
  BOT: lazy(() => import(`../pages/presentation/bot/Bot`)),
  USER_PROFILE_CATEGORY: lazy(
    () => import(`../pages/presentation/userCategory/UserProfileCategory`)
  ),
  SUB_METRIC: lazy(() => import(`../pages/presentation/metric/Metric`)),
  USER_PROFILE_SUB_CATEGORY: lazy(
    () => import(`../pages/presentation/userSubCategory/UserProfileSubCategory`)
  ),
  USER_PROFILE_QUESTION: lazy(
    () =>
      import(`../pages/presentation/userProfileQuestion/UserProfileQuestion`)
  ),
  DEFAULT_CONVERSATION: lazy(
    () =>
      import(
        `../pages/presentation/userDefaultConversation/userDefaultConversation`
      )
  ),
  ROLE_PERMISSION: lazy(
    () => import(`../pages/presentation/roleManagement/roleManagement`)
  ),
  CRON_JOB: lazy(() => import(`../pages/presentation/cronJob/AddEditCronJob`)),
  MOBILE_SCREEN_VIEW: lazy(
    () => import(`../pages/presentation/Mobile-intro/MobileIntro`)
  ),
  USER_DETAIL_SCREEN_VIEW: lazy(
    () => import(`../pages/presentation/userDetailScreen/UserDetailScreen`)
  ),
  INTERESTS_VIEW: lazy(
    () => import(`../pages/presentation/interests/Interests`)
  ),
  INTERESTS_QUESTIONS_VIEW: lazy(
    () => import(`../pages/presentation/interestQuestion/InterestQuestion`)
  ),
  LEVEL_ONE_MAPPING: lazy(
    () => import(`../pages/presentation/levelOneMapping/LevelOneMapping`)
  ),
  LEVEL_ZERO_MAPPING: lazy(
    () => import(`../pages/presentation/levelZeroMapping/LevelZeroMapping`)
  ),
  GOAL: lazy(() => import(`../pages/presentation/goal/Goal`)),
  LAB_TEST: lazy(() => import(`../pages/presentation/labTest/LabTest`)),
  DEVICE: lazy(() => import(`../pages/presentation/device/Device`)),
  ASSESSMENT: lazy(() => import(`../pages/presentation/assessment/Assessment`)),
  ASSESSMENT_QUESTION: lazy(() => import(`../pages/presentation/assessmentQuestion/AssessmentQuestion`)),
  RECOMMENDATION_RULE: lazy(() => import(`../pages/presentation/recommendationRule/RecommendationRule`)),
  RECIPE: lazy(() => import(`../pages/presentation/recipe/Recipe`)),
  RECIPE_BUNDLE: lazy(() => import(`../pages/presentation/recipeBundle/RecipeBundle`)),
  NUGGETS_TAG: lazy(() => import(`../pages/presentation/nuggetsTag/NuggetsTag`)),
  NUGGETS: lazy(() => import(`../pages/presentation/nuggets/Nuggets`)),
  PRIVACY_POLICY: lazy(() => import(`../pages/presentation/privacyPolicy/PrivacyPolicy`)),
  TERMS_OF_USE_POLICY: lazy(() => import(`../pages/presentation/termsOfUse/TermsOfUsePolicy`)),
};

const presentation: RouteProps[] = [
  /**
   * Landing
   */
  {
    path: dashboardPagesMenu.dashboard?.path,
    element: <LANDING.DASHBOARD />,
  },
  {
    path: dashboardPagesMenu.Users?.subMenu[0]?.path,
    element: <LANDING.USER />,
  },
  {
    path: dashboardPagesMenu.Users?.subMenu[1]?.path,
    element: <LANDING.ASSESSMENT />,
  },
  {
    path: dashboardPagesMenu.Users?.subMenu[2]?.path,
    element: <LANDING.ASSESSMENT_QUESTION />,
  },
  {
    path: dashboardPagesMenu.interests?.subMenu[0]?.path,
    element: <LANDING.INTERESTS_VIEW />,
  },
  {
    path: dashboardPagesMenu.interests?.subMenu[1]?.path,
    element: <LANDING.INTERESTS_QUESTIONS_VIEW />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[0]?.path,
    element: <LANDING.DEVICE />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[1]?.path,
    element: <LANDING.CATEGORY />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[2]?.path,
    element: <LANDING.SUB_CATEGORY />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[3]?.path,
    element: <LANDING.MODEL />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[4]?.path,
    element: <LANDING.PRODUCT />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[5]?.path,
    element: <LANDING.RECIPE />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[6]?.path,
    element: <LANDING.RECIPE_BUNDLE />,
  },
  {
    path: dashboardPagesMenu.Store?.subMenu[7]?.path,
    element: <LANDING.LAB_TEST />,
  },
  {
    path: dashboardPagesMenu.Admin?.subMenu[0]?.path,
    element: <LANDING.USER_PROFILE_CATEGORY />,
  },
  {
    path: dashboardPagesMenu.Data?.subMenu[0]?.path,
    element: <LANDING.SUB_METRIC />,
  },
  {
    path: dashboardPagesMenu.Data?.subMenu[1]?.path,
    element: <LANDING.LEVEL_ZERO_MAPPING />,
  },
  {
    path: dashboardPagesMenu.Data?.subMenu[2]?.path,
    element: <LANDING.LEVEL_ONE_MAPPING />,
  },
  {
    path: dashboardPagesMenu.Data?.subMenu[3]?.path,
    element: <LANDING.CRON_JOB />,
  },
  {
    path: dashboardPagesMenu.Data?.subMenu[5]?.path,
    element: <LANDING.RECOMMENDATION_RULE />,
  },
  {
    path: dashboardPagesMenu.Data?.subMenu[4]?.path,
    element: <LANDING.GOAL />,
  },
  {
    path: dashboardPagesMenu.Admin?.subMenu[1]?.path,
    element: <LANDING.USER_PROFILE_SUB_CATEGORY />,
  },
  {
    path: dashboardPagesMenu.Admin?.subMenu[2]?.path,
    element: <LANDING.USER_PROFILE_QUESTION />,
  },
  {
    path: dashboardPagesMenu.Admin?.subMenu[3]?.path,
    element: <LANDING.DEFAULT_CONVERSATION />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[0]?.path,
    element: <LANDING.CATEGORY />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[1]?.path,
    element: <LANDING.SUB_CATEGORY />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[2]?.path,
    element: <LANDING.PRODUCT />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[3]?.path,
    element: <LANDING.SKU />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[4]?.path,
    element: <LANDING.INGREDIANTS />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[5]?.path,
    element: <LANDING.MARKETING />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[6]?.path,
    element: <LANDING.DOSAGEASSISTANCE />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[7]?.path,
    element: <LANDING.BENEFIT />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[8]?.path,
    element: <LANDING.FAQ />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[9]?.path,
    element: <LANDING.KEYWORD />,
  },
  {
    path: dashboardPagesMenu.shop?.subMenu[10]?.path,
    element: <LANDING.HOW_IT_WORK />,
  },
  {
    path: dashboardPagesMenu.nuggets?.subMenu[0]?.path,
    element: <LANDING.NUGGETS_TAG />,
  },
  {
    path: dashboardPagesMenu.nuggets?.subMenu[1]?.path,
    element: <LANDING.NUGGETS />,
  },
  {
    path: dashboardPagesMenu.selfTrainer?.subMenu[0]?.path,
    element: <LANDING.CLASS />,
  },
  {
    path: dashboardPagesMenu.selfTrainer?.subMenu[1]?.path,
    element: <LANDING.CHAPTER />,
  },
  {
    path: dashboardPagesMenu.selfTrainer?.subMenu[2]?.path,
    element: <LANDING.COURSE />,
  },
  {
    path: dashboardPagesMenu.selfTrainer?.subMenu[3]?.path,
    element: <LANDING.COURSE_FAQ />,
  },
  {
    path: dashboardPagesMenu.selfTrainer?.subMenu[4]?.path,
    element: <LANDING.MASTER />,
  },
  {
    path: dashboardPagesMenu.selfTrainer?.subMenu[5]?.path,
    element: <LANDING.TRAINER />,
  },
  {
    path: dashboardPagesMenu.policy?.subMenu[0]?.path,
    element: <LANDING.PRIVACY_POLICY />,
  },
  {
    path: dashboardPagesMenu.policy?.subMenu[1]?.path,
    element: <LANDING.TERMS_OF_USE_POLICY />,
  },
//   {
//     path: dashboardPagesMenu.user?.path,
//     element: <LANDING.USER />,
//   },
  {
    path: dashboardPagesMenu.deliveryOptions?.path,
    element: <LANDING.DELIVERY />,
  },
  {
    path: dashboardPagesMenu.bot?.path,
    element: <LANDING.BOT />,
  },
  {
    path: dashboardPagesMenu.chatGpt?.path,
    element: <LANDING.CHATGPT />,
  },
  {
    path: dashboardPagesMenu.settings?.subMenu[0]?.path,
    element: <LANDING.MOBILE_SCREEN_VIEW />,
  },
  {
    path: dashboardPagesMenu.settings?.subMenu[1]?.path,
    element: <LANDING.USER_DETAIL_SCREEN_VIEW />,
  },
  // {
  //     path: dashboardPagesMenu.mobileScreen?.path,
  //     element: <LANDING.MOBILE_SCREEN_VIEW />,
  // },

  {
    path: dashboardPagesMenu.order?.path,
    element: <LANDING.ORDERS />,
  },
  {
    path: dashboardPagesMenu.promoCode?.path,
    element: <LANDING.PROMO_CODE />,
  },

  {
    path: dashboardPagesMenu.roles_permission?.path,
    element: <LANDING.ROLE_PERMISSION />,
  },

  /** ************************************************** */

  /**
   * Auth Page
   */

  {
    path: "/auth-pages/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Dashboard />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_PROFILE_CATEGORY}`,
    element: <AddEditUserProfileCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.SUB_METRIC}`,
    element: <AddEditMetric />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_HARDWARE_SPECIFIC}/:id`,
    element: <ViewHardwareSpecific />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_METRIC_SPECIFIC}/:id`,
    element: <ViewMetricSpecific />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_MEDICAL_SPECIFIC}/:id`,
    element: <ViewMedicalSpecific />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_PROFILE_CATEGORY}/:id`,
    element: <AddEditUserProfileCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.SUB_METRIC}/:id`,
    element: <AddEditMetric />,
  },
  {
    path: `${ROUTING_CONSTANT.CRON_JOB}`,
    element: <AddEditCronJob />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY}`,
    element: <AddEditUserProfileSubCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_PROFILE_SUB_CATEGORY}/:id`,
    element: <AddEditUserProfileSubCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.RECIPE_BUNDLE}`,
    element: <AddEditRecipeBundle />,
  },
  {
    path: `${ROUTING_CONSTANT.RECIPE_BUNDLE}/:id`,
    element: <AddEditRecipeBundle />,
  },
  {
    path: `${ROUTING_CONSTANT.RECOMMENDATION_RULE}`,
    element: <AddEditRecommendationRule />,
  },
  {
    path: `${ROUTING_CONSTANT.RECIPE}/:id`,
    element: <AddEditRecipe />,
  },
  {
    path: `${ROUTING_CONSTANT.RECIPE}`,
    element: <AddEditRecipe />,
  },
  {
    path: `${ROUTING_CONSTANT.RECOMMENDATION_RULE}/:id`,
    element: <AddEditRecommendationRule />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_PROFILE_QUESTION}`,
    element: <AddEditUserQuestion />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_PROFILE_QUESTION}/:id`,
    element: <AddEditUserQuestion />,
  },
  {
    path: `${ROUTING_CONSTANT.PRODUCT}`,
    element: <AddEditProduct />,
  },
  {
    path: `${ROUTING_CONSTANT.PRODUCT}/:id`,
    element: <AddEditProduct />,
  },
  {
    path: `${ROUTING_CONSTANT.CATEGORY}`,
    element: <AddEditDeviceCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.CATEGORY}/:id`,
    element: <AddEditDeviceCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.SUB_CATEGORY}`,
    element: <AddEditDeviceSubCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.SUB_CATEGORY}/:id`,
    element: <AddEditDeviceSubCategory />,
  },
  {
    path: `${ROUTING_CONSTANT.MODEL}`,
    element: <AddEditDeviceModel />,
  },
  {
    path: `${ROUTING_CONSTANT.MODEL}/:id`,
    element: <AddEditDeviceModel />,
  },
  {
    path: `${ROUTING_CONSTANT.SKU}`,
    element: <AddEditSku />,
  },
  {
    path: `${ROUTING_CONSTANT.SKU}/:id`,
    element: <AddEditSku />,
  },
  {
    path: `${ROUTING_CONSTANT.INTERESTS}`,
    element: <AddEditInterest />,
  },
  {
    path: `${ROUTING_CONSTANT.INTERESTS}/:id`,
    element: <AddEditInterest />,
  },
  {
    path: `${ROUTING_CONSTANT.INTERESTS_QUESTIONS}`,
    element: <AddEditInterestQuestion />,
  },
  {
    path: `${ROUTING_CONSTANT.INTERESTS_QUESTIONS}/:id`,
    element: <AddEditInterestQuestion />,
  },
  {
    path: `${ROUTING_CONSTANT.LEVEL_ZERO_MAPPING}`,
    element: <AddEditLevelZeroMapping  />,
  },
  {
    path: `${ROUTING_CONSTANT.LEVEL_ZERO_MAPPING}/:id`,
    element: <AddEditLevelZeroMapping />,
  },
  {
    path: `${ROUTING_CONSTANT.LEVEL_ONE_MAPPING}`,
    element: <AddEditLevelOneMapping />,
  },
  {
    path: `${ROUTING_CONSTANT.LEVEL_ONE_MAPPING}/:id`,
    element: <AddEditLevelOneMapping />,
  },
  {
    path: `${ROUTING_CONSTANT.GOAL}`,
    element: <AddEditGoal />,
  },
  {
    path: `${ROUTING_CONSTANT.GOAL}/:id`,
    element: <AddEditGoal />,
  },
  {
    path: `${ROUTING_CONSTANT.LAB_TEST}`,
    element: <AddEditLabTest />,
  },
  {
    path: `${ROUTING_CONSTANT.LAB_TEST}/:id`,
    element: <AddEditLabTest />,
  },
  {
    path: `${ROUTING_CONSTANT.INGREDIENT}`,
    element: <AddEditIngredient />,
  },
  {
    path: `${ROUTING_CONSTANT.INGREDIENT}/:id`,
    element: <AddEditIngredient />,
  },
  {
    path: `${ROUTING_CONSTANT.MARKETING}`,
    element: <AddEditMarketing />,
  },
  {
    path: `${ROUTING_CONSTANT.MARKETING}/:id`,
    element: <AddEditMarketing />,
  },
  {
    path: `${ROUTING_CONSTANT.DOSAGE_ASSISTANCE}`,
    element: <AddEditDosageAssistance />,
  },
  {
    path: `${ROUTING_CONSTANT.DOSAGE_ASSISTANCE}/:id`,
    element: <AddEditDosageAssistance />,
  },
  {
    path: `${ROUTING_CONSTANT.FAQ}`,
    element: <AddEditFaq />,
  },
  {
    path: `${ROUTING_CONSTANT.FAQ}/:id`,
    element: <AddEditFaq />,
  },
  {
    path: `${ROUTING_CONSTANT.CLASS}`,
    element: <AddEditClass />,
  },
  {
    path: `${ROUTING_CONSTANT.CLASS}/:id`,
    element: <AddEditClass />,
  },
  {
    path: `${ROUTING_CONSTANT.CHAPTER}`,
    element: <AddEditChapter />,
  },
  {
    path: `${ROUTING_CONSTANT.CHAPTER}/:id`,
    element: <AddEditChapter />,
  },
  {
    path: `${ROUTING_CONSTANT.COURSE}`,
    element: <AddEditCourse />,
  },
  {
    path: `${ROUTING_CONSTANT.COURSE}/:id`,
    element: <AddEditCourse />,
  },
  {
    path: `${ROUTING_CONSTANT.COURSE_FAQ}`,
    element: <AddEditCourseFaq />,
  },
  {
    path: `${ROUTING_CONSTANT.COURSE_FAQ}/:id`,
    element: <AddEditCourseFaq />,
  },
  {
    path: `${ROUTING_CONSTANT.MASTER}`,
    element: <AddEditMaster />,
  },
  {
    path: `${ROUTING_CONSTANT.MASTER}/:id`,
    element: <AddEditMaster />,
  },
  {
    path: `${ROUTING_CONSTANT.BENEFIT}`,
    element: <AddEditBenefit />,
  },
  {
    path: `${ROUTING_CONSTANT.BENEFIT}/:id`,
    element: <AddEditBenefit />,
  },
  {
    path: `${ROUTING_CONSTANT.KEYWORD}`,
    element: <AddEditKeyword />,
  },
  {
    path: `${ROUTING_CONSTANT.KEYWORD}/:id`,
    element: <AddEditKeyword />,
  },
  {
    path: `${ROUTING_CONSTANT.HOW_IT_WORK}`,
    element: <AddEditHowItWork />,
  },
  {
    path: `${ROUTING_CONSTANT.HOW_IT_WORK}/:id`,
    element: <AddEditHowItWork />,
  },
  {
    path: `${ROUTING_CONSTANT.USER}`,
    element: <AddEditUser />,
  },
  {
    path: `${ROUTING_CONSTANT.USER}/:id`,
    element: <AddEditUser />,
  },
  {
    path: `${ROUTING_CONSTANT.ASSESSMENT}`,
    element: <AddEditAssessment />,
  },
  {
    path: `${ROUTING_CONSTANT.ASSESSMENT}/:id`,
    element: <AddEditAssessment />,
  },
  {
    path: `${ROUTING_CONSTANT.ASSESSMENT_QUESTION}`,
    element: <AddEditAssessmentQuestion />,
  },
  {
    path: `${ROUTING_CONSTANT.ASSESSMENT_QUESTION}/:id`,
    element: <AddEditAssessmentQuestion />,
  },
  {
    path: `${ROUTING_CONSTANT.DEVICE}`,
    element: <AddEditDevice />,
  },
  {
    path: `${ROUTING_CONSTANT.DEVICE}/:id`,
    element: <AddEditDevice />,
  },
  {
    path: `${ROUTING_CONSTANT.NUGGETS_TAG}`,
    element: <AddEditNuggetsTag />,
  },
  {
    path: `${ROUTING_CONSTANT.NUGGETS_TAG}/:id`,
    element: <AddEditNuggetsTag />,
  },
  {
    path: `${ROUTING_CONSTANT.NUGGETS}`,
    element: <AddEditNuggets />,
  },
  {
    path: `${ROUTING_CONSTANT.NUGGETS}/:id`,
    element: <AddEditNuggets />,
  },
  {
    path: `${ROUTING_CONSTANT.MOBILE_SCREEN}`,
    element: <AddEditIntro />,
  },
  {
    path: `${ROUTING_CONSTANT.MOBILE_SCREEN}/:id`,
    element: <AddEditIntro />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_DETAIL_SCREEN}`,
    element: <AddEditUserDetailScreen />,
  },
  {
    path: `${ROUTING_CONSTANT.USER_DETAIL_SCREEN}/:id`,
    element: <AddEditUserDetailScreen />,
  },
  {
    path: `${ROUTING_CONSTANT.DELIVERY_OPTIONS}`,
    element: <AddEditDeliveryOptions />,
  },
  {
    path: `${ROUTING_CONSTANT.DELIVERY_OPTIONS}/:id`,
    element: <AddEditDeliveryOptions />,
  },
  {
    path: `${ROUTING_CONSTANT.TRAINER}`,
    element: <AddEditTrainer />,
  },
  {
    path: `${ROUTING_CONSTANT.TRAINER}/:id`,
    element: <AddEditTrainer />,
  },
  {
    path: `${ROUTING_CONSTANT.PROMO_CODE}`,
    element: <AddEditPromoCode />,
  },
  {
    path: `${ROUTING_CONSTANT.PROMO_CODE}/:id`,
    element: <AddEditPromoCode />,
  },
  {
    path: `${ROUTING_CONSTANT.BOT}`,
    element: <AddEditBot />,
  },
  {
    path: `${ROUTING_CONSTANT.BOT}/:id`,
    element: <AddEditBot />,
  },
  {
    path: `${ROUTING_CONSTANT.PRIVACY_POLICY}`,
    element: <AddEditPrivacyPolicy />,
  },
  {
    path: `${ROUTING_CONSTANT.PRIVACY_POLICY}/:id`,
    element: <AddEditPrivacyPolicy />,
  },
  {
    path: `${ROUTING_CONSTANT.TERMS_OF_USE_POLICY}`,
    element: <AddEditTermsOfUsePolicy />,
  },
  {
    path: `${ROUTING_CONSTANT.TERMS_OF_USE_POLICY}/:id`,
    element: <AddEditTermsOfUsePolicy />,
  },

  {
    path: "/verifyuser/:id",
    element: <VerifyUser />,
  },
  {
    path: "/resetPassword/:id",
    element: <ResetPassword />,
  },
  /** ************************************************** */
];
const contents = [...presentation];

export default contents;
