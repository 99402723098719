import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { Localstorage, decryptData } from "../../../core/auth.service";
import { UserDetailScreenService } from "../../../services/userDetailScreen.service";

const AddEditUserDetailScreen = () => {
    const { userDetailScreen }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [mobileScreenId, setMobileScreenId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [mobileData, setMobileData] = useState<any>({
        usertitle: "",
        usersubtitle: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        usertitle: yup.string().required("required"),
        usersubtitle: yup.string().required("required"),
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setMobileScreenId(decryptData(params.id));
                getUserDetailScreenById(decryptData(params.id));
            }
        };
        init();
    }, [params.id]);

    const getUserDetailScreenById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await UserDetailScreenService.getUserDetailScreenById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setMobileData(response?.data?.userDetailScreen);
                setValues({ ...response?.data?.userDetailScreen });
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.response.data.error);
            setIsLoading(false);
        }
    };
    const { values, handleChange, handleSubmit, setValues, errors } = useFormik({
        initialValues: { ...mobileData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = mobileScreenId ? await UserDetailScreenService.updateUserDetailScreenById(mobileScreenId, { ...values,userorder:Number(values.userorder) })
                    : await UserDetailScreenService.createUserDetailScreen({ ...values, userorder: (userDetailScreen?.length || 0) + 1 });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.USER_DETAIL_SCREEN_VIEW);
                    
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });
    const handleSave = () => {
        handleSubmit();
        setIsInvalidForm(true);
    }

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.USER_DETAIL_SCREEN_VIEW);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{mobileScreenId ? CONSTANT.UPDATE : CONSTANT.ADD} User Detail Screen</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">User Detail Screen Title*</label>

                                <FormGroup id="usertitle">
                                    <Input
                                        className={`${isInvalidForm && !values.usertitle
                                            ? "border border-danger input-form "
                                            : "input-form "
                                            }`}
                                        name="usertitle"
                                        value={values.usertitle}
                                        onChange={handleChange}
                                        placeholder="User Detail Screen Title"
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.usertitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE
                                        }</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">User Detail Screen Sub Title*</label>

                                <FormGroup id="usersubtitle">
                                    <Input
                                        className={`${isInvalidForm && !values.usersubtitle
                                            ? "border border-danger input-form "
                                            : "input-form "
                                            }`}
                                        name="usersubtitle"
                                        placeholder="User Detail Screen Sub Title"
                                        value={values.usersubtitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.usersubtitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUB_TITLE}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 process-btn mt-5 ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {mobileScreenId ? CONSTANT.UPDATE : CONSTANT.ADD} User Detail Screen
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditUserDetailScreen;
