import React from 'react';
import { RouteProps } from 'react-router-dom';
import DefaultAside from '../pages/_layout/_asides/DefaultAside';

const asides: RouteProps[] = [
	{ path: "/auth-pages/login", element: null },	
	{ path: "/resetPassword/:id", element: null },	
	{ path: "/verifyuser/:id", element: null },	


	{ path: '*', element: <DefaultAside /> },
];

export default asides;
