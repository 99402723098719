import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import { UserService } from "../../../services/user.service";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "./user.scss";

const AddEditUser = () => {
  const { usersData, setUsersData }: any = useContext(DataContext);

  const params = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState("");
  const [fieldsDisabled, setFieldsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [userData, setUserData] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    recoveryEmail: "",
    userType: "",
  });

  const [isInvalidForm, setIsInvalidForm] = useState(false);
  const validationSchema = yup.object().shape({
    firstName: yup.string().required("required"),
    lastName: yup.string().required("required"),
    email: yup.string().required("required"),
    userType: yup.string().required("required"),
  });

  useEffect(() => {
    const init = async () => {
      if (params && decryptData(params.id)) {
        setUserId(decryptData(params.id));
        getUserById(decryptData(params.id));
        setFieldsDisabled(true);
      }
    };
    init();
  }, [params.id]);

  const getUserById = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await UserService.getUserById(id);
      setIsLoading(false);

      if (response.status === CONSTANT.SUCCESS) {
        setUserData(response?.data?.user);
        setValues({ ...response?.data?.user });
      }
    } catch (error: any) {
      console.error(error);
      toast.error(error.response.data.error);
      setIsLoading(false);
    }
  };
  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: { ...userData },

    validationSchema,

    onSubmit: async (values, action) => {
      try {
        setIsLoading(true);
        const data = userId
          ? await UserService.updateUserById(userId, { ...values })
          : await UserService.registerUser({ ...values });
        setIsLoading(false);

        if (data && data.status === CONSTANT.SUCCESS) {
          toast.success(data.message);
          const username = await UserService.getAllUsersCronJob();
          setUsersData(username?.data?.users);
          action.resetForm();
          navigate(ROUTING_CONSTANT.USER_VIEW);

          const { _id, firstName } = data.data;
          if (userId) {
            const existingIndex = usersData.findIndex(
              (item: any) => item._id === _id
            );
            if (existingIndex !== -1) {
              setUsersData((prevData: any) => {
                const newData = [...prevData];
                newData[existingIndex] = { _id, firstName };
                return newData;
              });
            }
          }
        } else {
          toast.error(data.message);
        }
      } catch (error: any) {
        if (error && error?.response && error?.response?.data && error?.response?.data?.message === 'Validation Error') {
            toast.error(error.response.data.errors.forEach((errMessage: any) => {
                toast.error(errMessage);
            }));
        }
        toast.error(error?.response?.data?.error);
        setIsLoading(false);
      }
    },
  });

  const onCancelClick = () => {
    navigate(ROUTING_CONSTANT.USER_VIEW);
  };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{userId ? CONSTANT.UPDATE : CONSTANT.ADD} User</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">First Name*</label>

                                <FormGroup id="firstName">
                                    <Input
                                        className={`${isInvalidForm && !values.firstName
                                            ? "border border-danger input-form "
                                            : "input-form "
                                            }`}
                                        name="firstName"
                                        value={values.firstName}
                                        onChange={handleChange}
                                        placeholder="First Name"
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.firstName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.FIRST_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Last Name*</label>

                                <FormGroup id="lastName">
                                    <Input
                                        className={`${isInvalidForm && !values.lastName
                                            ? "border border-danger input-form "
                                            : "input-form "
                                            }`}
                                        name="lastName"
                                        placeholder="Last Name"
                                        value={values.lastName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.lastName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LAST_NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Email*</label>
                                <FormGroup id="email">
                                    <Input
                                        className={`${isInvalidForm && !values.email
                                            ? "border border-danger input-form "
                                            : "input-form"
                                            } ${fieldsDisabled ? "user-input" : ""}`}
                                        name="email"
                                        placeholder="Email"
                                        value={values.email}
                                        onChange={handleChange}
                                        disabled={fieldsDisabled}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.email && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.EMAIL}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Contact Number*</label>
                                <FormGroup id="contactNo">
                                    <Input
                                        type="text"
                                        maxLength={10}
                                        className={`${!userId && isInvalidForm && !values.contactNo
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            } ${fieldsDisabled ? "user-input" : ""}`}
                                        name="contactNo"
                                        placeholder="Contact Number"
                                        value={values.contactNo}
                                        onChange={(event: any) => {
                                            const { value } = event.target;
                                            const trimmedValue = value
                                                .replace(/\D/g, "")
                                                .substring(0, 10);
                                            setValues({ ...values, contactNo: trimmedValue });
                                        }}
                                        disabled={fieldsDisabled}
                                    />
                                </FormGroup>
                                {!userId && isInvalidForm && !values.contactNo && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.CONTACT_NUMBER}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Recovery Email</label>

                                <FormGroup id="recoveryEmail">
                                    <Input
                                        className="input-form"
                                        name="recoveryEmail"
                                        value={values.recoveryEmail}
                                        onChange={handleChange}
                                        placeholder="Recovey Email"
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">User Type*</label>
                                <FormGroup id="userType">
                                    {userId ? (
                                        <Input
                                            type="text"
                                            className="input-form"
                                            name="userType"
                                            value={values.userType}
                                            disabled={fieldsDisabled}
                                            placeholder="User Type"
                                        />
                                    ) : (
                                        <select
                                            className={`${isInvalidForm && values.userType.length === 0
                                                ? "border border-danger  form-select form-selet-lg input-form"
                                                : "form-select form-selet-lg input-form"
                                                }`}
                                            name="userType"
                                            value={values.userType}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select User Type</option>
                                            <option value={CONSTANT.ROLES.WAREHOUSE}>
                                                {CONSTANT.ROLES.WAREHOUSE}
                                            </option>
                                            <option value={CONSTANT.ROLES.CONTENT}>
                                                {CONSTANT.ROLES.CONTENT}
                                            </option>
                                            <option value={CONSTANT.ROLES.CRM}>
                                                {CONSTANT.ROLES.CRM}
                                            </option>
                                        </select>
                                    )}
                                </FormGroup>

                                {!userId && isInvalidForm && values.userType.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.ROLE}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 process-btn mt-5 ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                            >
                                {userId ? CONSTANT.UPDATE : CONSTANT.ADD} User
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditUser;
