import httpCommon from '../core/http-common';
import { API_URL } from '../core/constant';
import { toast } from "react-toastify";
import { logoutUser } from '../core/auth.service';
import { CONSTANT } from '../core/static-constant';

export const TrainerService = {
    getAllTrainers,
    deleteTrainerById,
    getTrainerById,
    editTrainer,
    addTrainer,
    getAllCourseName,
};

async function getAllTrainers(data: any) {
    return httpCommon.post(API_URL.GET_ALL_TRAINERS, data)
        .then((response: any) => {
            return response.data;
        }).catch((error) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}


async function deleteTrainerById(id: any) {
    return httpCommon.get(API_URL.DELETE_TRAINER_BY_ID + id).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}


async function getTrainerById(id: any) {
    return httpCommon.get(API_URL.GET_TRAINER_BY_ID + id).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function editTrainer(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_TRAINER_BY_ID + id, data).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function addTrainer(data: any) {
    return httpCommon.post(API_URL.CREATE_TRAINER, data).then((response) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function getAllCourseName() {
    return httpCommon.get(API_URL.GET_ALL_COURSE).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}