import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import {
    isValidHttpUrl,
    ROUTING_CONSTANT,
    isValidUrl,
} from "../../../core/constant";
import Loader from "../../Loader/loader";
import { TrainerService } from "../../../services/trainer.service";
import Select from "react-select";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { CourseService } from "../../../services";

const AddEditTrainer = () => {
    const { setTrainerData, trainerData,setCourseData }: any = useContext(DataContext);
    const { courseData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [trainerId, setTrainerId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedCourseName, setSelectedCourseName] = useState([]);
    const [selectedCourseId, setSelectedCourseId] = useState<any>([]);
    const [selectedCourseIdArr, setSelectedCourseIdArr] = useState<any>([]);

    const [trainerAllData, setTrainerAllData] = useState<{
        trainerName: string;
        trainerImage1: string;
        trainerImage2: string;
        trainerImage3: string;
        trainerIntroLongDesc: string;
        trainerIntroTitle: string;
        trainerSocialIcon1: string;
        trainerSocialLink1: string;
        trainerSocialIcon2: string;
        trainerSocialLink2: string;
        trainerSocialIcon3: string;
        trainerSocialLink3: string;
        trainerSocialIcon4: string; 
        trainerSocialLink4: string; 
        courses: string;
    }>({
        trainerName: "",
        trainerImage1: "",
        trainerImage2: "",
        trainerImage3: "",
        trainerIntroLongDesc: "",
        trainerIntroTitle: "",
        trainerSocialIcon1: "",
        trainerSocialLink1: "",
        trainerSocialIcon2: "",
        trainerSocialLink2: "",
        trainerSocialIcon3: "",
        trainerSocialLink3: "",
        trainerSocialIcon4: "", 
        trainerSocialLink4: "", 
        courses: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setTrainerId(decryptData(params.id));
                getTrainerById(decryptData(params.id));
            }
        };
        init();
        if(courseData?.length === 0){
            getAllCourse();
        }
    }, []);

    const getAllCourse = async () => {
        try {
          let data: any = await CourseService.getAllCourseByName()
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.courses;
            setCourseData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error) {
          toast.error(CONSTANT.ERROR_OCCURRED);
        }
      };

    useEffect(() => {
        const data = selectedCourseName
            ? selectedCourseName.map((item: any) => ({
                value: item.value,
                label: item.label,
            }))
            : [];

        setSelectedCourseId(data);
    }, [selectedCourseName]);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        trainerName: yup.string().required(" required"),
        trainerImage1: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        trainerImage2: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        trainerImage3: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        trainerIntroTitle: yup.string().required("required"),
        trainerIntroLongDesc: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...trainerAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = trainerId ? await TrainerService.editTrainer(trainerId, { ...values, courses: selectedCourseIdArr, }) : await TrainerService.addTrainer({ ...values, courses: selectedCourseIdArr, });
                    setIsLoading(false);
                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const TrainerName = await CourseService.getAllTrainerName();
                        setTrainerData(TrainerName?.data);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.TRAINER_VIEW);

                        const { _id, trainerName } = data.data;
                        if (trainerId) {
                            const existingIndex = trainerData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setTrainerData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, trainerName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    console.error(error);
                    setIsLoading(false);
                    toast.error(error.response.data.error);
                }
            },
        });

    const getTrainerById = async (id: any) => {
        try {
            setIsLoading(true);
            const response: any = await TrainerService.getTrainerById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setTrainerData(response?.data?.trainer);

                const trainerDatas = response?.data?.trainer[0];
                setTrainerAllData({
                    trainerName: trainerDatas?.trainerName,
                    trainerImage1: trainerDatas?.trainerImage1,
                    trainerImage2: trainerDatas?.trainerImage2,
                    trainerImage3: trainerDatas?.trainerImage3,
                    trainerIntroTitle: trainerDatas?.trainerIntroTitle,
                    trainerIntroLongDesc: trainerDatas?.trainerIntroLongDesc,
                    trainerSocialIcon1: trainerDatas?.trainerSocialIcon1,
                    trainerSocialLink1: trainerDatas?.trainerSocialLink1,
                    trainerSocialIcon2: trainerDatas?.trainerSocialIcon2,
                    trainerSocialLink2: trainerDatas?.trainerSocialLink2,
                    trainerSocialIcon3: trainerDatas?.trainerSocialIcon3,
                    trainerSocialLink3: trainerDatas?.trainerSocialLink3,
                    trainerSocialIcon4: trainerDatas?.trainerSocialIcon4,
                    trainerSocialLink4: trainerDatas?.trainerSocialLink4,
                    courses: trainerDatas?.courses,
                });

                setValues({
                    trainerName: trainerDatas?.trainerName,
                    trainerImage1: trainerDatas?.trainerImage1,
                    trainerImage2: trainerDatas?.trainerImage2,
                    trainerImage3: trainerDatas?.trainerImage3,
                    trainerIntroTitle: trainerDatas?.trainerIntroTitle,
                    trainerIntroLongDesc: trainerDatas?.trainerIntroLongDesc,
                    trainerSocialIcon1: trainerDatas?.trainerSocialIcon1,
                    trainerSocialLink1: trainerDatas?.trainerSocialLink1,
                    trainerSocialIcon2: trainerDatas?.trainerSocialIcon2,
                    trainerSocialLink2: trainerDatas?.trainerSocialLink2,
                    trainerSocialIcon3: trainerDatas?.trainerSocialIcon3,
                    trainerSocialLink3: trainerDatas?.trainerSocialLink3,
                    trainerSocialIcon4: trainerDatas?.trainerSocialIcon4,
                    trainerSocialLink4: trainerDatas?.trainerSocialLink4,
                    courses: trainerDatas?.courses,
                });

                const selectedTrainerCourses = trainerDatas.courses.map(
                    (course: any) => ({
                        value: course._id,
                        label: course.courseTitle,
                    })
                );

                setSelectedCourseName(selectedTrainerCourses);

                const courseIds = trainerDatas?.courses.map(
                    (course: any) => course._id
                );
                setSelectedCourseIdArr(courseIds);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.TRAINER_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();
    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {trainerId ? CONSTANT.UPDATE : CONSTANT.ADD} Trainer
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Trainer Name*</label>

                                <FormGroup id="trainerName">
                                    <Input
                                        placeholder="Trainer Name"
                                        className={`${isInvalidForm && !values.trainerName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerName"
                                        value={values.trainerName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.trainerName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Trainer Image1 URL*</label>

                                <FormGroup id="trainerImage1">
                                    <Input
                                        placeholder="Trainer Image URL"
                                        className={`${isInvalidForm && !values.trainerImage1
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerImage1"
                                        value={values.trainerImage1}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.trainerImage1) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Trainer Image2 URL*</label>

                                <FormGroup id="trainerImage2">
                                    <Input
                                        placeholder="Trainer Image URL"
                                        className={`${isInvalidForm && !values.trainerImage1
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerImage2"
                                        value={values.trainerImage2}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.trainerImage2) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Trainer Image3 URL*</label>

                                <FormGroup id="trainerImage3">
                                    <Input
                                        placeholder="Trainer Image URL"
                                        className={`${isInvalidForm && !values.trainerImage1
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerImage3"
                                        value={values.trainerImage3}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidHttpUrl(values.trainerImage3) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Trainer Title*</label>

                                <FormGroup id="longDescription">
                                    <Input
                                        placeholder="Enter Trainer Title"
                                        className={`${isInvalidForm && !values.trainerIntroTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerIntroTitle"
                                        value={values.trainerIntroTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.trainerIntroTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Long Description*</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Trainer Long Description"
                                        className={`${isInvalidForm && !values.trainerIntroLongDesc
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerIntroLongDesc"
                                        value={values.trainerIntroLongDesc}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm &&
                                    isValidHttpUrl(values.trainerImage3) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Icon1</label>

                                <FormGroup id="trainerSocialIcon1">
                                    <Input
                                        placeholder="Enter Trainer Social Icon1"
                                        className={`${isInvalidForm && !values.trainerSocialLink1
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialIcon1"
                                        value={values.trainerSocialIcon1}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Link1</label>

                                <FormGroup id="trainerSocialLink1">
                                    <Input
                                        placeholder="Enter Trainer Social Link1"
                                        className={`${isInvalidForm && !values.trainerSocialLink1
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialLink1"
                                        value={values.trainerSocialLink1}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Icon2</label>

                                <FormGroup id="trainerSocialIcon2">
                                    <Input
                                        placeholder="Enter Trainer Social Icon2"
                                        className={`${isInvalidForm && !values.trainerSocialLink2
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialIcon2"
                                        value={values.trainerSocialIcon2}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Link2</label>

                                <FormGroup id="trainerSocialLink2">
                                    <Input
                                        placeholder="Enter Trainer Social Link2"
                                        className={`${isInvalidForm && !values.trainerSocialLink2
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialLink2"
                                        value={values.trainerSocialLink2}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Icon3</label>

                                <FormGroup id="trainerSocialIcon13">
                                    <Input
                                        placeholder="Enter Trainer Social Icon3"
                                        className={`${isInvalidForm && !values.trainerSocialLink1
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialIcon3"
                                        value={values.trainerSocialIcon3}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Link3</label>

                                <FormGroup id="trainerSocialLink3">
                                    <Input
                                        placeholder="Enter Trainer Social Link"
                                        className={`${isInvalidForm && !values.trainerSocialLink1
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialLink3"
                                        value={values.trainerSocialLink3}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Icon4</label>

                                <FormGroup id="trainerSocialIcon4">
                                    <Input
                                        placeholder="Enter Trainer Social Icon4"
                                        className={`${isInvalidForm && !values.trainerSocialLink4
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialIcon4"
                                        value={values.trainerSocialIcon4}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Trainer Social Link4</label>

                                <FormGroup id="trainerSocialLink4">
                                    <Input
                                        placeholder="Enter Trainer Social Link4"
                                        className={`${isInvalidForm && !values.trainerSocialLink4
                                            ? "input-form"
                                            : "input-form"
                                            }`}
                                        name="trainerSocialLink4"
                                        value={values.trainerSocialLink4}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6" key="courses">
                                <label className="form-labels">Select Courses</label>

                                <FormGroup id="courses">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const courses = selectedOptions.map((course: any) => ({
                                                id: course.value,
                                                label: course.label,
                                            }));
                                            const courseIds = selectedOptions.map(
                                                (course: any) => course.value
                                            );
                                            setSelectedCourseIdArr(courseIds);
                                            setSelectedCourseName(courses);
                                        }}
                                        options={
                                            courseData &&
                                            courseData.map((course: any) => ({
                                                value: course._id,
                                                label: course.courseTitle,
                                                key: course._id,
                                            }))
                                        }
                                        placeholder="Select Courses"
                                        value={selectedCourseId}
                                        isSearchable={true}
                                        isMulti
                                    />
                                </FormGroup>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {trainerId ? CONSTANT.UPDATE : CONSTANT.ADD} Trainer
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditTrainer;
