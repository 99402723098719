import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import "./Assessment.scss";
import Modal, {
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "../../../components/bootstrap/Modal";
import { AssessmentService } from "../../../services/assessment.service";
import { AssessmentQuestionService } from "../../../services/assessmentQuestion.service";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import PaginationButtons from "../../../components/PaginationButtons";

const AddEditAssessment = () => {
    const { setAssessmentData, assessmentData, assessmentQuestionData, setAssessmentQuestionData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [AssessmentId, setAssessmentId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5);
    const [selectedQuestions, setSelectedQuestions] = useState<any>({});
    const [selectedCount, setSelectedCount] = useState(0);

    const handleChangePage = (page: any) => {
        setCurrentPage(page);
    };

    const handleChangeRowsPerPage = (rowsPerPage: any) => {
        setPerPage(rowsPerPage);
        setCurrentPage(1);
    };

    const handleCheckboxChange = (questionId: string) => {
        setSelectedQuestions((prevState: any) => {
            const newSelectedQuestions = { ...prevState, [questionId]: !prevState[questionId] };
            const selectedCount = Object.values(newSelectedQuestions).filter(Boolean).length;
            setSelectedCount(selectedCount);
            return newSelectedQuestions;
        });
    };

    const filteredQuestions = assessmentQuestionData?.filter((ele: any) => ele.assessmentAnswerType !== CONSTANT.YESNO);

    const paginatedQuestions = filteredQuestions?.slice((currentPage - 1) * perPage, currentPage * perPage);

    const Save = () => {
        const selectedCount = Object.values(selectedQuestions).filter(Boolean).length;
        setSelectedCount(selectedCount);
        setIsModalOpen(false);
    };

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [AssessmentAllData, setAssessmentAllData] = useState({
        assessmentName: "",
        alertCriteria: "",
        assessmentIntro: "",
        assessmentWhat: "",
        assessmentWhy: "",
        assessmentNextSteps: "",
        assessmentDuration: "",
        assessmentNoQuestions: "",
        assessmentQuestionId: ""
    });

    const validationSchema = yup.object().shape({
        assessmentName: yup.string().required("required"),
        assessmentIntro: yup.string().required(" required"),
        assessmentWhat: yup.string().required(" required"),
        assessmentWhy: yup.string().required(" required"),
        alertCriteria: yup.string().required(" required"),
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    useEffect(() => {
        if (assessmentQuestionData?.length === 0) {
            getAllAssessmentQuestionName();
        }
    }, []);

    const getAllAssessmentQuestionName = async () => {
        try {
            let data: any = await AssessmentQuestionService.getAllAssessmentQuestionName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.AssessmentQuestion;
                setAssessmentQuestionData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...AssessmentAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const selectedQuestionIds = Object.keys(selectedQuestions)
                        .filter(key => selectedQuestions[key])
                        .map(key => key);

                    const payload = {
                        ...values,
                        assessmentQuestionId: selectedQuestionIds,
                        assessmentNoQuestions: selectedQuestionIds.length.toString()
                    };
                    const data = AssessmentId ? await AssessmentService.updateAssessmentById(AssessmentId, payload) : await AssessmentService.createAssessment(payload);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        navigate(ROUTING_CONSTANT.ASSESSMENT_VIEW);
                        const AssessmentName = await AssessmentService.getAllAssessmentName();
                        setAssessmentData(AssessmentName?.data?.Assessment);
                        action.resetForm();

                        const { _id, assessmentName } = data.data;

                        if (AssessmentId) {
                            const existingIndex = assessmentData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setAssessmentData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, assessmentName };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });


    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setAssessmentId(decryptData(params.id));
                getAssessmentById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getAssessmentById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await AssessmentService.getAssessmentById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                const assessmentData = response?.data?.Assessment;
                setAssessmentAllData(assessmentData);
                const { assessmentQuestions, ...otherData } = assessmentData;
                setValues(otherData);
                const selectedQuestionIds: { [key: string]: boolean } = {};
                assessmentQuestions.forEach((question: any) => {
                    selectedQuestionIds[question._id] = true;
                });
                setSelectedQuestions(selectedQuestionIds);
                const selectedCount = Object.values(selectedQuestionIds).filter(Boolean).length;
                setSelectedCount(selectedCount);
            }

        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.ASSESSMENT_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {AssessmentId ? CONSTANT.UPDATE : CONSTANT.ADD}  Assessment
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment Name*</label>

                                <FormGroup id="assessmentName">
                                    <Input
                                        placeholder=" Assessment Name"
                                        className={`${isInvalidForm && !values.assessmentName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentName"
                                        value={values.assessmentName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment Alert Criteria*</label>

                                <FormGroup id="alertCriteria">
                                    <Input
                                        placeholder="Assessment Alert Criteria"
                                        className={`${isInvalidForm && !values.alertCriteria
                                            ? "border input-form border-danger"
                                            : "input-form"
                                            }`}
                                        name="alertCriteria"
                                        value={values.alertCriteria}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.alertCriteria && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Assessment Intro*</label>

                                <FormGroup id="assessmentIntro">
                                    <Input
                                        placeholder="Assessment Intro"
                                        className={`${isInvalidForm && !values.assessmentIntro
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentIntro"
                                        value={values.assessmentIntro}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentIntro && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.INTRO}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment What*</label>

                                <FormGroup id="assessmentWhat">
                                    <Textarea
                                        rows={5}
                                        placeholder="Assessment What"
                                        className={`${isInvalidForm && !values.assessmentWhat
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentWhat"
                                        value={values.assessmentWhat}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentWhat && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment Why*</label>

                                <FormGroup id="assessmentWhy">
                                    <Textarea
                                        rows={5}
                                        placeholder="Assessment Why"
                                        className={`${isInvalidForm && !values.assessmentWhy
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentWhy"
                                        value={values.assessmentWhy}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.assessmentWhy && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment NextSteps</label>

                                <FormGroup id="assessmentNextSteps">
                                    <Textarea
                                        rows={5}
                                        placeholder="Assessment NextSteps"
                                        className={`${isInvalidForm && !values.assessmentNextSteps
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="assessmentNextSteps"
                                        value={values.assessmentNextSteps}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Assessment Duration</label>

                                <FormGroup id="assessmentDuration">
                                    <Input
                                        placeholder="Assessment Duration"
                                        className={`${isInvalidForm && !values.assessmentDuration
                                            ? "border input-form "
                                            : "input-form"
                                            }`}
                                        name="assessmentDuration"
                                        value={values.assessmentDuration}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <div id="btn">
                                    <Button
                                        type="button"
                                        className={`w-30 mt-5`}
                                        color="primary"
                                        onClick={openModal}
                                        isDisable={isLoading}
                                    >
                                        Add Assessment Question
                                    </Button>
                                </div>
                                <div className="mt-2 assessmentQuestionAnswerHeading">
                                    {selectedCount > 0 && (
                                        <p>{`You have selected ${selectedCount} ${selectedCount === 1 ? 'question' : 'questions'}.`}</p>)}
                                </div>
                            </div>
                            {isModalOpen && (
                                <Modal setIsOpen={closeModal} isOpen={isModalOpen} toggle={closeModal}>
                                    <ModalHeader className="interestsQuestionAnswerHeading">
                                        {"Assessment Questions"}
                                        <Button icon="close" onClick={closeModal}>
                                        </Button>
                                    </ModalHeader>
                                    <ModalBody>
                                        <CardBody className="table-responsive">
                                            <table className="table table-modern table-hover">
                                                {isLoading ? (
                                                    <>
                                                        <tbody>
                                                            <tr>
                                                                <td colSpan={6}>
                                                                    <div className="d-flex justify-content-center">
                                                                        <div className="spinner-border" role="status">
                                                                            <span className="visually-hidden">Loading...</span>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </>
                                                ) : paginatedQuestions.length === 0 ? (
                                                    <tbody>
                                                        <tr>
                                                            <td colSpan={6}>
                                                                <div className="text-center fw-bold">No data Found</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ) : (
                                                    <tbody>
                                                        {paginatedQuestions
                                                            .map((ele: any, index: any) => {
                                                                const questionId = ele._id;
                                                                return (
                                                                    <tr key={index}>
                                                                        <th className="text-muted">
                                                                            <input
                                                                                className="form-check-input custom-checkbox"
                                                                                type="checkbox"
                                                                                id={`question-${questionId}`}
                                                                                name={`question-${questionId}`}
                                                                                checked={!!selectedQuestions[questionId]}
                                                                                onChange={() => handleCheckboxChange(questionId)}
                                                                            />
                                                                        </th>
                                                                        <th className="text-muted">
                                                                            <label
                                                                                className="form-check-label assessmentQuestionAnswerHeading"
                                                                                htmlFor={`question-${questionId}`}
                                                                            >
                                                                                {ele.assessmentQuestionName}
                                                                            </label>
                                                                        </th>
                                                                    </tr>
                                                                );
                                                            })}
                                                    </tbody>
                                                )}
                                            </table>
                                        </CardBody>
                                        <PaginationButtons
                                            count={filteredQuestions.length}
                                            label="Records"
                                            setCurrentPage={handleChangePage}
                                            currentPage={currentPage}
                                            perPage={perPage}
                                            setPerPage={handleChangeRowsPerPage}
                                        />
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button color="primary" onClick={Save} >
                                            {isLoading ? 'Saving...' : 'Save'}
                                        </Button>
                                    </ModalFooter>
                                </Modal>
                            )}
                        </div>
                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {AssessmentId ? CONSTANT.UPDATE : CONSTANT.ADD}  Assessment
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditAssessment;
