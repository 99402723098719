import React, { SVGProps } from 'react';

const SvgManIcon = (props: SVGProps<SVGSVGElement>) => {
	return (
		<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='currentColor' className='svg-icon' {...props}>
			<path fill='none' d='M0 0h24v24H0z' />
			<path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-3.31 0-6-2.69-6-6s2.69-6 6-6 6 2.69 6 6-2.69 6-6 6zm0-11c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 9c-2.67 0-5.06-1.09-6.77-2.84C6.56 15.06 8.96 14 12 14c3.04 0 5.44 1.06 6.77 2.16C16.94 17.91 15.65 18 12 18z' />
		</svg>
	);
};

export default SvgManIcon;
