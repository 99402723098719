import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { useFormik } from "formik";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import Select from "react-select";
import { BotService } from "../../../services/bot.service";
import { GoalService, interestQuestionService, InterestService } from "../../../services";
import { UserHardwareSpecificService } from "../../../services/hardwareSpecific.service";

const AddEditInterestQuestion = () => {
    const { interestQuestion, setinterestQuestionData, setMetricData, metricData, setGoalData, goalData,devicesData, setDevicesData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [interestQueId, setInterestQued] = useState("");
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [interestQueAllData, setInterestQueAllData] = useState({
        question: "",
        subtitle: "",
        type: "",
        options: [{ title: "", subtitle: "", details: "", typeValue: "" }],
    });

    //Function for add multiple option
    const handleAddOption = () => {
        const newOption = { title: "", subtitle: "", details: "", typeValue: "" };
        if (values.options.every((option) => option.title !== "" && option.subtitle !== "" && option.typeValue !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                options: [...prevValues.options, newOption],
            }));
        } else {
            toast.error(CONSTANT.FILL_OPTIONS);
        }
    };

    //Function for remove options
    const handleRemoveOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.options];
            updatedOptions.splice(index, 1);
            return { ...prevValues, options: updatedOptions };
        });
    };

    //Function for get options index and display/set value in dropdown
    const getOptionsForIndex = (index: any) => {
        if (values.type === CONSTANT.INTEREST_QUESTION_TYPE.METRIC) {
            const previousOption = index > 0 ? values.options[index - 1] : null;
            return metricData.map((item: any) => ({
                value: item._id,
                label: item.metricName,
            })).filter((option: any) => previousOption ? option.value !== previousOption.typeValue : true);
        } else if (values.type === CONSTANT.INTEREST_QUESTION_TYPE.GOAL) {
            return goalData.map((item: any) => ({
                value: item._id,
                label: item.name,
            }));
        } else if (values.type === CONSTANT.INTEREST_QUESTION_TYPE.SOURCE) {
            return devicesData.map((item: any) => ({
                value: item._id,
                label: item.deviceName,
            }));
        }
        return [];
    };

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setInterestQued(decryptData(params.id));
                getInterestQuestionById(decryptData(params.id));
            }
        };
        init();
        if (goalData?.length === 0) {
            getAllGoalName();
        }
        if (metricData?.length === 0) {
            getAllMetricName();
        }
        if (devicesData?.length === 0) {
            getAllDevice();
        }
    }, []);

    //validate fields
    const validationSchema = yup.object().shape({
        question: yup.string().required(" required"),
        subtitle: yup.string().required(" required"),
        type: yup.string().required(" required"),
    });

    //Api for get metrics in dropdown
    const getAllMetricName = async () => {
        try {
            let data: any = await BotService.getAllMetricName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.metric;
                setMetricData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    //Api for get goal in dropdown
    const getAllGoalName = async () => {
        try {
            let data: any = await GoalService.getAllGoalName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.Goal;
                setGoalData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAllDevice = async () => {
        try {
            let data: any = await UserHardwareSpecificService.getAllListOfDevice();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.device;
                setDevicesData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    //To add interest question and update
    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...interestQueAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = interestQueId ? await interestQuestionService.updateInterestQuestionById(interestQueId, { ...values }) : await interestQuestionService.createInterestQuestion({ ...values });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        navigate(ROUTING_CONSTANT.INTERESTS_QUESTIONS_VIEW);
                        const InterestQuestionName = await InterestService.getAllInterestQuestion();
                        setinterestQuestionData(InterestQuestionName.data?.interestsQuestion);
                        action.resetForm();

                        const { _id, question } = data.data;

                        if (interestQueId) {
                            const existingIndex = interestQuestion.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setinterestQuestionData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, question };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    //Api for get interest question by id
    const getInterestQuestionById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await interestQuestionService.getInterestQuestionById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setInterestQueAllData(response?.data?.interestsQuestion);
                setValues({ ...response?.data?.interestsQuestion });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.INTERESTS_QUESTIONS_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    //style to react select field
    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    //dropdown for source type
    const Sourceoptions = [
        { sourceName: CONSTANT.APPLEWATCH, value: CONSTANT.APPLEWATCH, label: CONSTANT.APPLEWATCH, _id: '1' },
        { sourceName: CONSTANT.WEIGHTMACHINE, value: CONSTANT.WEIGHTMACHINE, label: CONSTANT.WEIGHTMACHINE, _id: '2' },
        { sourceName: CONSTANT.FITBIT, value: CONSTANT.FITBIT, label: CONSTANT.FITBIT, _id: '3' },
        { sourceName: CONSTANT.HUMOTRONRING, value: CONSTANT.HUMOTRONRING, label: CONSTANT.HUMOTRONRING, _id: '4' },
        { sourceName: CONSTANT.WEIGHTMACHINE_ADVANCED, value: CONSTANT.WEIGHTMACHINE_ADVANCED, label: CONSTANT.WEIGHTMACHINE_ADVANCED, _id: '5' }
    ]

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {interestQueId ? CONSTANT.UPDATE : CONSTANT.ADD} Interest Question
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Interest Question Name*</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Interest Question Name"
                                        className={`${isInvalidForm && !values.question
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="question"
                                        value={values.question}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.question && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Interest Question Subtitle*</label>

                                <FormGroup id="shortDescription">
                                    <Input
                                        placeholder="Enter Interest Question Subtitle"
                                        className={`${isInvalidForm && !values.subtitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="subtitle"
                                        value={values.subtitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.subtitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUB_TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Select Interest Question Type*</label>
                                <FormGroup id="type">
                                    <select
                                        className={`${isInvalidForm && values.type.length === 0
                                            ? "border border-danger  form-select form-selet-lg input-form"
                                            : "form-select form-selet-lg input-form"
                                            }`}
                                        name="type"
                                        value={values.type}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Interest Question Type</option>
                                        <option value={CONSTANT.INTEREST_QUESTION_TYPE.SOURCE}>
                                            {CONSTANT.INTEREST_QUESTION_TYPE.SOURCE}
                                        </option>
                                        <option value={CONSTANT.INTEREST_QUESTION_TYPE.METRIC}>
                                            {CONSTANT.INTEREST_QUESTION_TYPE.METRIC}
                                        </option>
                                        <option value={CONSTANT.INTEREST_QUESTION_TYPE.GOAL}>
                                            {CONSTANT.INTEREST_QUESTION_TYPE.GOAL}
                                        </option>
                                    </select>
                                </FormGroup>
                                {isInvalidForm && values.type.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TYPE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Options*</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>

                                        </div>
                                    </>
                                    <div>
                                        {values?.options?.map((option, index) => (
                                            <div key={index} className="col-md-11 mt-2 d-flex">
                                                <div className="col-md-12">
                                                    <div className="d-flex mb-2 gap-2">
                                                        <Input
                                                            placeholder="Option title"
                                                            className={`${isInvalidForm && !option.title ? "border input-form border-danger" : "input-form"}`}
                                                            value={option.title}
                                                            name={`options[${index}].title`}
                                                            onChange={handleChange}
                                                        />
                                                        <Input
                                                            placeholder="Option subtitle"
                                                            className={`${isInvalidForm && !option.subtitle ? "border input-form border-danger" : "input-form"}`}
                                                            value={option.subtitle}
                                                            name={`options[${index}].subtitle`}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="d-flex mb-2 gap-2">
                                                        <Textarea
                                                            rows={3}
                                                            placeholder="Option details"
                                                            style={{ minHeight: "45px", maxWidth: "44rem" }}
                                                            className={`${isInvalidForm && !option.details ? "border input-form" : "input-form"}`}
                                                            value={option.details}
                                                            name={`options[${index}].details`}
                                                            onChange={handleChange}
                                                        />
                                                        <div className="col-md-6 mt-2">
                                                            <div key={index}>
                                                                <Select
                                                                    styles={customStyles}
                                                                    onChange={(selectedOption: any) => {
                                                                        handleChange({
                                                                            target: {
                                                                                name: `options[${index}].typeValue`,
                                                                                value: selectedOption?.value,
                                                                            },
                                                                        });
                                                                    }}
                                                                    className={`${isInvalidForm && !option.typeValue ? "border border-danger" : ""}`}
                                                                    options={getOptionsForIndex(index)}
                                                                    placeholder={
                                                                        values.type === CONSTANT.INTEREST_QUESTION_TYPE.METRIC
                                                                            ? "Select Metric"
                                                                            : values.type === CONSTANT.INTEREST_QUESTION_TYPE.GOAL ? "Select Goal" : values.type === CONSTANT.INTEREST_QUESTION_TYPE.SOURCE ? "Select Source" : "Select..."
                                                                    }
                                                                    value={
                                                                        option.typeValue &&
                                                                        (values.type === CONSTANT.INTEREST_QUESTION_TYPE.METRIC
                                                                            ? {
                                                                                value: option.typeValue,
                                                                                label: metricData.find(
                                                                                    (metric: any) => metric._id === option.typeValue
                                                                                )?.metricName,
                                                                            }
                                                                            : values.type === CONSTANT.INTEREST_QUESTION_TYPE.GOAL
                                                                                ? {
                                                                                    value: option.typeValue,
                                                                                    label: goalData.find(
                                                                                        (goal: any) => goal._id === option.typeValue
                                                                                    )?.name,
                                                                                }
                                                                                : values.type === CONSTANT.INTEREST_QUESTION_TYPE.SOURCE
                                                                                    ? {
                                                                                        value: option.typeValue,
                                                                                        label: devicesData.find(
                                                                                            (source: any) => source._id === option.typeValue
                                                                                        )?.deviceName,
                                                                                    }
                                                                                    : null
                                                                        )
                                                                    }
                                                                    isSearchable={true}
                                                                    isClearable={true}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-flex justify-content-center align-items-center">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z"
                                                        />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={handleSave}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {interestQueId ? CONSTANT.UPDATE : CONSTANT.ADD} Interest Question
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditInterestQuestion;
