import httpCommon from '../core/http-common';
import { logoutUser } from '../core/auth.service';
import { API_URL } from '../core/constant';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const UserHardwareSpecificService = {
    getAllUserHardwareSpecificData,
    getHardwareSpecificById,
    getAllDeviceByUserId,
    getAllListOfDevice
};

function getAllUserHardwareSpecificData(data: any) {
    return httpCommon.post(API_URL.GET_ALL_HARDWARE_SPECIFIC_BY_USER_ID, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getHardwareSpecificById(id: any) {
    return httpCommon.get(API_URL.GET_HARDWARE_SPECIFIC_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllDeviceByUserId(id: any) {
    return httpCommon.get(API_URL.GET_ALL_DEVICES_BY_USER_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllListOfDevice() {
    return httpCommon.get(API_URL.GET_ALL_LIST_OF_DEVICE).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

