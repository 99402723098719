import httpCommon from '../core/http-common';
import { API_URL } from '../core/constant';
import { logoutUser } from '../core/auth.service';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const DeviceCategoryService = {
    createDeviceCategory,
    updateDeviceCategoryById,
    getAllDeviceCategory,
    getAllDeviceCategoryName,
    getDeviceCategoryById,
    deleteDeviceCategoryById,
};

function createDeviceCategory(data: any) {
    return httpCommon.post(API_URL.CREATE_DEVICE_CATEGORY, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function updateDeviceCategoryById(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_DEVICE_CATEGORY_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getDeviceCategoryById(id: any) {
    return httpCommon.get(API_URL.GET_DEVICE_CATEGORY_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteDeviceCategoryById(id: any) {
    return httpCommon.post(API_URL.DELETE_DEVICE_CATEGORY_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllDeviceCategory(data: any) {
    let url = API_URL.GET_ALL_DEVICE_CATEGORY;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllDeviceCategoryName() {
    return httpCommon.get(API_URL.GET_ALL_DEVICE_CATEGORY_NAME).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
