import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import { DeliveryOptionsService } from "../../../services/deliveryOptions.service";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditDeliveryOptions = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [deliveryOptionsId, setDeliveryOptionsId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const { deliveryOption, setDeliveryOption }: any = useContext(DataContext);
    const [deliveryOptionsAllData, setDeliveryOptionsData] = useState({
        title: "",
        amount: "",
        shortDescription: "",
        longDescription: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        title: yup.string().required(" required"),
        amount: yup.string().required(" required"),
        shortDescription: yup.string().required("required"),
        longDescription: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...deliveryOptionsAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = deliveryOptionsId ? await DeliveryOptionsService.updateDeliveryOptionsById(deliveryOptionsId, { ...values }) : await DeliveryOptionsService.createDeliveryOption({ ...values });
                    setIsLoading(false);
                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.DELIVERY_OPTIONS_VIEW);
                        const { _id, benefitName } = data.data;

                        if (deliveryOptionsId) {
                            const existingIndex = deliveryOption.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setDeliveryOption((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, benefitName };
                                    return newData;
                                });
                            }
                        }
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            }
        });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setDeliveryOptionsId(decryptData(params.id));
                getDeliveryOptionsById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getDeliveryOptionsById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await DeliveryOptionsService.getDeliveryOptionsById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setDeliveryOptionsData(response?.data?.deliveryOption);
                setValues({ ...response?.data?.deliveryOption });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.DELIVERY_OPTIONS_VIEW);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {deliveryOptionsId ? CONSTANT.UPDATE : CONSTANT.ADD} Delivery
                            Option
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="title" label="Title*" isFloating>
                                    <Input
                                        placeholder="title"
                                        className={`${isInvalidForm && !values.title
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.title && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="amount" label="Amount *" isFloating>
                                    <Input
                                        placeholder="amount"
                                        type="number"
                                        className={`${isInvalidForm && !values.amount
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="amount"
                                        value={values.amount}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.amount && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="shortDescription"
                                    label="Short Description*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="shortDescription"
                                        className={`${isInvalidForm && !values.shortDescription
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="shortDescription"
                                        value={values.shortDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.shortDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup
                                    id="longDescription"
                                    label="Long Description*"
                                    isFloating
                                >
                                    <Input
                                        placeholder="longDescription"
                                        className={`${isInvalidForm && !values.longDescription
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                        name="longDescription"
                                        value={values.longDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.longDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {deliveryOptionsId ? CONSTANT.UPDATE : CONSTANT.ADD} Delivery
                                Option
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditDeliveryOptions;
