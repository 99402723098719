import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { PromocodeService } from "../../../services/promoCode.service";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditPromoCode = () => {
    const { marketData, setMarketData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [userId, setUserId] = useState("");
    const [fieldsDisabled, setFieldsDisabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [marketingData, setMarketingData] = useState<any>({
        promoCode: "",
        discountType: "",
        discountValue: "",
        startDate: "",
        endDate: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        promoCode: yup.string().required("required"),
        discountType: yup.string().required("required"),
        discountValue: yup.string().required("required"),
        startDate: yup.date().required("Start Date is required"),
        endDate: yup
            .date()
            .required("End Date is required")
            .when("startDate", (startDate, schema) => {
                return startDate
                    ? schema.min(startDate, "End Date must be later than Start Date")
                    : schema;
            }),
    });

    const {
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        setValues,
        setFieldValue,
    } = useFormik({
        initialValues: { ...marketingData },
        validationSchema,

        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = userId ? await PromocodeService.updatePromoCodeDetails(userId, { ...values, }) : await PromocodeService.createPromoCode({ ...values, });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.PROMO_CODE_VIEW);

                    const { _id, firstName } = data.data;
                    if (userId) {
                        const existingIndex = marketData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setMarketData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, firstName };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setUserId(decryptData(params.id));
                getPromoCodeDetailsById(decryptData(params.id));
                setFieldsDisabled(true);
            }
        };
        init();
    }, [params.id]);

    const getPromoCodeDetailsById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await PromocodeService.getPromoCodeDetailsById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setMarketingData(response?.data?.promoCode);

                setValues({ ...response?.data?.promoCode });
            }
        } catch (error: any) {
            toast.error(error.response.data.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.PROMO_CODE_VIEW);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {userId ? CONSTANT.UPDATE : CONSTANT.ADD} Promo Code
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Promo Code*</label>

                                <FormGroup id="promoCode">
                                    <Input
                                        className={`${isInvalidForm && !values.promoCode
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            } ${fieldsDisabled ? "user-input" : ""}`}
                                        name="promoCode"
                                        value={values.promoCode}
                                        onChange={handleChange}
                                        placeholder="Promo Code"
                                        disabled={fieldsDisabled}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.promoCode && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PROMOCODE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Discount Type*</label>

                                <FormGroup id="discountType">
                                    <select
                                        className={`${isInvalidForm && values.discountType.length === 0
                                            ? "border border-danger form-select form-select-lg"
                                            : "form-select form-select-lg"
                                            }`}
                                        name="discountType"
                                        value={values.discountType}
                                        onChange={handleChange}
                                    >
                                        <option value="" className="">
                                            Select Discount Type
                                        </option>
                                        <option value={CONSTANT.PERCENTAGE}>
                                            {CONSTANT.PERCENTAGE}
                                        </option>
                                        <option value={CONSTANT.FLAT}>{CONSTANT.FLAT}</option>
                                    </select>
                                </FormGroup>
                                {isInvalidForm && values.discountType.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DISCOUNT}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Discount Value*</label>
                                <FormGroup id="email">
                                    <Input
                                        className={`${isInvalidForm && !values.discountValue
                                            ? "border border-danger input-form "
                                            : "input-form"
                                            }`}
                                        name="discountValue"
                                        type="number"
                                        placeholder="Discount Value"
                                        value={values.discountValue}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.discountValue && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DISCOUNT_VALUE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Start Date*</label>
                                <FormGroup>
                                    <DatePicker
                                        className={`date-picker react-datepicker-wrapper p-auto ${isInvalidForm && values?.startDate?.length === 0
                                            ? "border border-danger"
                                            : errors.startDate && touched.startDate
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        isClearable
                                        placeholderText="   Select Start Date"
                                        selected={
                                            values.startDate
                                                ? moment(values.startDate).toDate()
                                                : null
                                        }
                                        minDate={moment().toDate()}
                                        onChange={(date: any) => {
                                            setFieldValue("startDate", date);
                                        }}
                                    />
                                </FormGroup>
                                {isInvalidForm && values?.startDate?.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.START_DATE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">End Date*</label>
                                <FormGroup>
                                    <DatePicker
                                        className={`date-picker react-datepicker-wrapper p-auto ${isInvalidForm && values?.endDate?.length === 0
                                            ? "border border-danger"
                                            : errors.endDate && touched.endDate
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                        isClearable
                                        placeholderText="   Select End Date"
                                        minDate={
                                            values.startDate
                                                ? moment(values.startDate).toDate()
                                                : moment().toDate()
                                        }
                                        selected={
                                            values.endDate ? moment(values.endDate).toDate() : null
                                        }
                                        onChange={(date: any) => {
                                            setFieldValue("endDate", date);
                                        }}
                                    />
                                </FormGroup>
                                {isInvalidForm && values?.endDate?.length === 0 ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.END_DATE}</small>
                                    </p>
                                ) : (
                                    errors.endDate &&
                                    touched.endDate && (
                                        <div className="text-danger">
                                            {CONSTANT.END_DATE_VALIDATION}
                                        </div>
                                    )
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                            >
                                {userId ? CONSTANT.UPDATE : CONSTANT.ADD} Promo Code
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditPromoCode;
