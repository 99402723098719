import httpCommon from "../core/http-common";
import { API_URL } from "../core/constant";
import { logoutUser } from "../core/auth.service";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";

export const IngredientService = {
    EditIngrediant,
    addIngrediant,
    getIngrediantById,
    getAllingrediant,
    deleteIngrediantById,
};

function addIngrediant(data: any) {
    return httpCommon
        .post(API_URL.CREATE_INGREDIENT, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function EditIngrediant(id: any, data: any) {
    return httpCommon
        .post(API_URL.UPDATE_INGREDIENT_BY_ID + id, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getIngrediantById(id: any) {
    return httpCommon
        .get(API_URL.GET_INGREDIENT_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getAllingrediant(data: any) {
    let url = API_URL.GET_ALL_INGREDIENT;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteIngrediantById(id: any) {
    return httpCommon
        .post(API_URL.DELETE_INGREDIENT_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}
