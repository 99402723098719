import React, { useEffect, useState } from "react";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import { useLocation, useNavigate } from "react-router-dom";
import {
    Localstorage,
} from "../../../core/auth.service";
import { CONSTANT } from "../../../core/static-constant";
import { toast } from "react-toastify";
import { UserService } from "../../../services/user.service";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import PaginationButtons from "../../../components/PaginationButtons";
import "../roleManagement/roleManagement.css";
import Page from "../../../layout/Page/Page";
import Button from "../../../components/bootstrap/Button";
import moment from "moment";

const MetricSpecific = () => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [perPage, setPerPage] = React.useState<number>(5);
    const [totalRecords, setTotalRecords] = React.useState(0);
    const [isInitialized, setIsInitialized] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const [MetricData, setMetricData] = useState([]);
    const { state } = useLocation();
    const UserEmail = state?.userEmail;
    const MetricUserId = Localstorage(CONSTANT.GET, CONSTANT.METRIC_SPECIFIC_USER_ID);

    const navigate = useNavigate();

    const handleChangePage = (newPage: any) => {
        let obj = {
            limit: perPage,
            pageCount: newPage,
            userId: MetricUserId,
        };
        getAllMetricByUser(obj);
        setCurrentPage(parseInt(newPage));
    };

    const handleChangeRowsPerPage = async (event: any) => {
        let obj = {
            limit: parseInt(event),
            pageCount: 1,
            userId: MetricUserId,
        };
        await setCurrentPage(1);
        await setPerPage(parseInt(event));
        await getAllMetricByUser(obj);
    };

    useEffect(() => {
        if (!isInitialized) {
            setIsInitialized(true);
        } else {
            let obj = {
                userId: MetricUserId,
                limit: perPage,
                pageCount: currentPage,
            };
            getAllMetricByUser(obj);
        }
    }, [isInitialized]);

    const getAllMetricByUser = async (obj: any) => {
        try {
            setShowLoading(true);
            let data: any = await UserService.getAllMetricByUser(obj);
            setShowLoading(false);
            if (data && data?.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.metrics || {};
                // const metricsData: any = {};
                // responseData.forEach((metric: any) => {
                //     const { data } = metric;
                //     Object.entries(data).forEach(([key, value]) => {
                //         metricsData[key] = value;
                //     });
                // });
                const Data = responseData.map((metric: any) => ({
                    metricName: metric.metricId.metricName,
                    metricDataRange: metric.metricId.dataRange,
                    value: metric.data.value,
                    devices: metric?.devices?.deviceName,
                    createdAt: metric.createdAt,
                    updatedAt: metric.updatedAt,
                }));
                setMetricData(Data);
                setTotalRecords(data?.data?.totalRecords);
            } else {
                if (
                    data.message === CONSTANT.AUTHENTICATION_FAIL ||
                    data.statusCode === 403
                ) {
                    navigate("/auth-pages/login");
                    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN);
                }
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setShowLoading(false);
        }
    };
    return (
        <>
            <PageWrapper>
                <Page container="fluid">
                    <div className="">
                        <Card stretch>
                            <h3 className="text-center mt-3 mb-5 text-primary">
                                <strong>Metric Data</strong>
                            </h3>

                            <div className="d-flex justify-content-between align-items-center px-5">
                                <p className="fw-bolder m-0">
                                    UserEmail: {UserEmail}
                                </p>

                                <div>
                                    <Button
                                        type="button"
                                        className="btn-dark text-nowrap mt-2"
                                        onClick={() => {
                                            navigate(-1);
                                        }}
                                    >
                                        Back
                                    </Button>
                                </div>
                            </div>
                            <CardBody className="table-responsive">

                                <table className="table table-modern table-hover">
                                    <thead>
                                        <tr>
                                            <th>Device Name</th>
                                            <th>Metric Name</th>
                                            <th>Last Sync Date</th>
                                            <th>Metric Data Range</th>
                                            <th className="text-end">Value</th>
                                        </tr>
                                    </thead>
                                    {showLoading ? (
                                        <>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={4}>
                                                        <div className="d-flex justify-content-center">
                                                            <div className="spinner-border" role="status">
                                                                <span className="visually-hidden">
                                                                    Loading...
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </>
                                    ) : MetricData.length === 0 ? (
                                        <tbody>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div className="text-center fw-bold">
                                                        No data Found
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    ) : (
                                        <tbody>
                                            {MetricData &&
                                                MetricData.map((ele: any, i: any) => {
                                                    return (
                                                        <tr key={i}>
                                                            <>
                                                                <th className="text-muted text-capitalize">
                                                                    <span dangerouslySetInnerHTML={{ __html: ele.devices || "N/A" }} />
                                                                </th>
                                                                <th className="text-muted text-capitalize">
                                                                    <span dangerouslySetInnerHTML={{ __html: ele.metricName }} />
                                                                </th>
                                                                <th className="text-muted text-capitalize">
                                                                    {ele && ele.updatedAt
                                                                        ? ` ${moment(ele.updatedAt).format(
                                                                            "MMM DD YYYY"
                                                                        )},at ${moment(ele.updatedAt).format("h:mm A")}`
                                                                        : ` ${moment(ele.createdAt).format(
                                                                            "MMM DD YYYY"
                                                                        )},at ${moment(ele.createdAt).format("h:mm A")}`}
                                                                </th>
                                                                <th className="text-muted text-capitalize">
                                                                    <span dangerouslySetInnerHTML={{ __html: ele.metricDataRange }} />
                                                                </th>
                                                                <th className="text-muted text-end">
                                                                    {ele.value}
                                                                </th>
                                                            </>
                                                        </tr>
                                                    );
                                                })
                                            }


                                        </tbody>
                                    )}
                                </table>
                            </CardBody>
                            <PaginationButtons
                                count={totalRecords}
                                label="Records"
                                setCurrentPage={handleChangePage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={handleChangeRowsPerPage}
                            />
                        </Card>
                    </div>
                </Page>
            </PageWrapper>
        </>
    );
};

export default MetricSpecific;
