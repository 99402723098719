import httpCommon from '../core/http-common';
import { API_URL } from '../core/constant';
import { logoutUser } from '../core/auth.service';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const howItWorkService = {
    getAllHowItWork,
    deleteHowItWorkById,
    getHowItWorkById,
    editHowItWork,
    addHowItWork,
};

function getAllHowItWork(data: any) {
    return httpCommon.post(API_URL.GET_ALL_HOW_IT_WORKS, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteHowItWorkById(id: any) {
    return httpCommon.post(API_URL.DELETE_HOW_IT_WORKS_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getHowItWorkById(id: any) {
    return httpCommon.get(API_URL.GET_HOW_IT_WORKS_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function editHowItWork(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_HOW_IT_WORKS_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function addHowItWork(data: any) {
    return httpCommon.post(API_URL.CREATE_HOW_IT_WORKS, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
