import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { MasterService } from "../../../services/master.service";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditMaster = () => {
    const { masterData, setMasterData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [masterId, setMasterId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [masterAllData, setMasterAllData] = useState({
        masterTitle: "",
        masterSubtitle: "",
        masterImage: "",
        masterAllCoursePrice: "",
        masterAllCoursePriceText: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setMasterId(decryptData(params.id));
                getMasterById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const validationSchema = yup.object().shape({
        masterTitle: yup.string().required("Name is required"),
        masterSubtitle: yup.string().required("required"),
        masterImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        masterAllCoursePrice: yup.string().required("required"),
        masterAllCoursePriceText: yup.string().required("required"),
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...masterAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = masterId ? await MasterService.updateMasterById(masterId, { ...values, }) : await MasterService.createMaster({ ...values, });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.MASTER_VIEW);

                    const { _id, name } = data.data;
                    if (masterId) {
                        const existingIndex = masterData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setMasterData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, name };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                setIsLoading(false);
                toast.error(error.response.data.error);
            }
        },
    });

    const getMasterById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await MasterService.getMasterById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setMasterAllData(response?.data?.master);
                setValues({ ...response?.data?.master });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.MASTER_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };
    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{masterId ? CONSTANT.UPDATE : CONSTANT.ADD} Master</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Master Title*</label>

                                <FormGroup id="classTitle">
                                    <Input
                                        placeholder="Enter master title"
                                        className={`${isInvalidForm && !values.masterTitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="masterTitle"
                                        value={values.masterTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.masterTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Master Subtitle*</label>

                                <FormGroup id="classDescription">
                                    <Input
                                        placeholder="Enter master subtitle"
                                        className={`${isInvalidForm && !values.masterSubtitle
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="masterSubtitle"
                                        value={values.masterSubtitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.masterSubtitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUB_TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Master Image*</label>

                                <FormGroup id="classDescription">
                                    <Input
                                        placeholder="Enter master image url"
                                        className={`${isInvalidForm && !values.masterImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="masterImage"
                                        value={values.masterImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm &&
                                    isValidHttpUrl(values.masterImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Master All Course Price*</label>

                                <FormGroup id="classDescription">
                                    <Input
                                        type="number"
                                        placeholder="Enter master all course price"
                                        className={`${isInvalidForm && !values.masterAllCoursePrice
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="masterAllCoursePrice"
                                        value={values.masterAllCoursePrice}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.masterAllCoursePrice && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.PRICE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">
                                    Master All Course Price Text*
                                </label>

                                <FormGroup id="classDescription">
                                    <Input
                                        placeholder="Enter master all course price text"
                                        className={`${isInvalidForm && !values.masterAllCoursePriceText
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="masterAllCoursePriceText"
                                        value={values.masterAllCoursePriceText}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.masterAllCoursePriceText && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.MASTER_ALL_COURSE_TEXT}</small>
                                    </p>
                                )}
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {masterId ? CONSTANT.UPDATE : CONSTANT.ADD} Master
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn  float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditMaster;
