import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { Localstorage, decryptData } from "../../../core/auth.service";
import { InterestService } from "../../../services/interest.service";
import Select from "react-select";


const AddEditInterest = () => {
    const { interest,interestQuestion,setInterestQuestionData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [interestId, setinterestId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedInterestName, setSelectedInterestName] = useState([]);
    const [selectedInterestId, setSelectedInterestId] = useState<any>([]);
    const [selectedInterestIdArr, setSelectedInterestIdArr] = useState<any>([]);

    const [interestData, setInterestData] = useState<any>({
        title: "",
        subtitle: "",
        interestQuestionId:""
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        title: yup.string().required("required"),
        subtitle: yup.string().required("required"),
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setinterestId(decryptData(params.id));
                getInterestById(decryptData(params.id));
            }
        };
        init();
    }, [params.id]);

    useEffect(() => {
        if(interestQuestion?.length === 0){
            getAllInterestQuestion();
        }
    }, []);

    const getAllInterestQuestion = async () => {
        try {
          let data: any = await InterestService.getAllInterestQuestion();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.interestsQuestion;
            setInterestQuestionData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
      };

    const getInterestById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await InterestService.getInterestById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setInterestData(response?.data?.interests);
                setValues({ ...response?.data?.interests });
                const selectedInterestNamesss = response?.data?.interests?.interestQuestionId.map(
                    (interest: any) => ({
                        id: interest._id,
                        label: interest.question,
                    })
                );
                setSelectedInterestName(selectedInterestNamesss);

                const interests = response?.data?.interests?.interestQuestionId.map((interest: any) => interest._id);
                setSelectedInterestIdArr(interests);
            }
        } catch (error: any) {
            console.error(error);
            toast.error(error.response.data.error);
            setIsLoading(false);
        }
    };
    const { values, handleChange, handleSubmit, setValues, errors } = useFormik({
        initialValues: { ...interestData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = interestId ? await InterestService.updateInterestById(interestId, { ...values,interestCount:Number(values.interestCount),interestQuestionId:selectedInterestIdArr })
                    : await InterestService.createInterest({ ...values, interestCount: (interest?.length || 0) + 1,interestQuestionId: selectedInterestIdArr, });
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.INTERESTS_VIEW);
                    
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const data = selectedInterestName
            ? selectedInterestName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedInterestId(data);
    }, [selectedInterestName]);

    const handleSave = () => {
        handleSubmit();
        setIsInvalidForm(true);
    }

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.INTERESTS_VIEW);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{interestId ? CONSTANT.UPDATE : CONSTANT.ADD} Interest</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Interest Title*</label>

                                <FormGroup id="title">
                                    <Input
                                        className={`${isInvalidForm && !values.title
                                            ? "border border-danger input-form "
                                            : "input-form "
                                            }`}
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        placeholder="Interest Title"
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.title && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.TITLE
                                        }</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Interest Sub Title*</label>

                                <FormGroup id="subtitle">
                                    <Input
                                        className={`${isInvalidForm && !values.subtitle
                                            ? "border border-danger input-form "
                                            : "input-form "
                                            }`}
                                        name="subtitle"
                                        placeholder="Interest Sub Title"
                                        value={values.subtitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.subtitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.SUB_TITLE}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="interest">
                                <label className="form-labels">Select Questions*</label>

                                <FormGroup id="interest">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const interest = selectedOptions.map((interests: any) => ({
                                                id: interests.value,
                                                label: interests.label,
                                            }));

                                            const interests = selectedOptions.map((interest: any) => interest.value);
                                            setSelectedInterestIdArr(interests);

                                            setSelectedInterestName(interest);
                                        }}
                                        options={
                                            interestQuestion &&
                                            interestQuestion
                                                .map((interest: any) => ({
                                                    value: interest._id,
                                                    label: interest.question,
                                                }))
                                        }
                                        placeholder="Select Questions"
                                        value={selectedInterestId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedInterestId.length === 0
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedInterestId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 process-btn mt-5 ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {interestId ? CONSTANT.UPDATE : CONSTANT.ADD} Interest
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditInterest;
