import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Select from "react-select";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { BotService } from "../../../services/bot.service";
import { levelZeroMappingService } from "../../../services/levelZeroMapping.service";
import { GoalService } from "../../../services";

const AddEditLevelZeroMapping = () => {
  const {
    setLevelZeroMappingData,
    levelZeroMappingData,
    setGoalData,
    goalData,
    setLevelOneMappingData,
    levelOneMappingData
  }: any = useContext(DataContext);
  const params = useParams();
  const navigate = useNavigate();
  const [LevelZeroMappingId, setLevelZeroMappingId] = useState("");
  const [selectedMetricName, setSelectedMetricName] = useState([]);
  const [selectedMetricId, setSelectedMetricId] = useState<any>([]);
  const [selectedMetricIdArr, setSelectedMetricIdArr] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGoalId, setSelectedGoalId] = useState<any>([]);
  const [selectedGoalIdArr, setSelectedGoalIdArr] = useState<any>("");
  const [LevelZeroMappingAllData, setLevelZeroMappingAllData] = useState({
    goalIndexName: "",
    goalId: "",
    metricId: "",
  });
  const validationSchema = yup.object().shape({
    goalIndexName: yup.string().required("required"),
  });

  const [isInvalidForm, setIsInvalidForm] = useState(false);

  const { values, handleChange, handleSubmit, setValues } = useFormik({
    initialValues: { ...LevelZeroMappingAllData },
    validationSchema,
    onSubmit: async (values, action) => {
      try {
        setIsLoading(true);
        const data = LevelZeroMappingId
          ? await levelZeroMappingService.updateLevelZeroMappingById(
              LevelZeroMappingId,
              {
                ...values,
                metricId: selectedMetricIdArr,
                goalId: selectedGoalIdArr,
              }
            )
          : await levelZeroMappingService.createLevelZeroMappingInfo({
              ...values,
              metricId: selectedMetricIdArr,
              goalId: selectedGoalIdArr,
            });
        setIsLoading(false);

        if (data && data.status === CONSTANT.SUCCESS) {
          toast.success(data.message);
          action.resetForm();
          navigate(ROUTING_CONSTANT.LEVEL_ZERO_MAPPING_VIEW);

          const { _id, name } = data.data;

          if (LevelZeroMappingId) {
            const existingIndex = levelZeroMappingData.findIndex(
              (item: any) => item._id === _id
            );
            if (existingIndex !== -1) {
              setLevelZeroMappingData((prevData: any) => {
                const newData = [...prevData];
                newData[existingIndex] = { _id, name };
                return newData;
              });
            }
          }
        } else {
          toast.error(data.message);
        }
      } catch (error: any) {
        toast.error(error?.response?.data?.error);
        setIsLoading(false);
      }
    },
  });

  useEffect(() => {
    const init = async () => {
      if (params && decryptData(params.id)) {
        setLevelZeroMappingId(decryptData(params.id));
        getLevelZeroMappingById(decryptData(params.id));
        // setFieldsDisabled(true);
      }
    };
    if (levelOneMappingData?.length === 0) {
      getAllLevelOneMetricName();
    }
    if (goalData?.length === 0) {
      getAllGoalName();
    }
    init();
  }, []);

  useEffect(() => {
    const data = selectedMetricName
      ? selectedMetricName.map((item: any) => ({
          value: item.id,
          label: item.label,
        }))
      : [];

    setSelectedMetricId(data);
  }, [selectedMetricName]);

  const getAllLevelOneMetricName = async () => {
    try {
      let data: any = await BotService.getAllLevelOneName();
      if (data && data.status === CONSTANT.SUCCESS) {
        const responseData = data?.data?.metric;
        setLevelOneMappingData(responseData);
      } else {
        toast.error(data.message);
      }
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
    }
  };

  const getLevelZeroMappingById = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await levelZeroMappingService.getLevelZeroMappingById(
        id
      );
      setIsLoading(false);
      if (response.status === CONSTANT.SUCCESS) {
        setLevelZeroMappingAllData(response?.data?.levelZeroMapping);
        setValues({ ...response?.data?.levelZeroMapping });
        const selectedMetricNames =
          response?.data?.levelZeroMapping?.metricId.map((metric: any) => ({
            id: metric._id,
            label: metric.metricName,
          }));

        setSelectedMetricName(selectedMetricNames);
        const metrics = response?.data?.levelZeroMapping?.metricId.map(
          (metric: any) => metric._id
        );
        setSelectedMetricIdArr(metrics);

        setSelectedGoalId({
          value: response?.data?.levelZeroMapping?.goalId?._id,
          label: response?.data?.levelZeroMapping?.goalId?.name,
        });

        setSelectedGoalIdArr(response?.data?.levelZeroMapping?.goalId._id);
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.error);
      setIsLoading(false);
    }
  };

  //Api for get goal in dropdown
  const getAllGoalName = async () => {
    try {
      let data: any = await GoalService.getAllGoalName();
      if (data && data.status === CONSTANT.SUCCESS) {
        const responseData = data?.data?.Goal;
        setGoalData(responseData);
      } else {
        toast.error(data.message);
      }
    } catch (error: any) {
        toast.error(error?.response?.data?.message);
    }
  };
  const onCancelClick = () => {
    navigate(ROUTING_CONSTANT.LEVEL_ZERO_MAPPING_VIEW);
  };

  const handleSave = () => {
    setIsInvalidForm(true);
  };

  function style() {
    return {
      control: (baseStyles: any) => ({
        ...baseStyles,
        borderRadius: "10px",
      }),
    };
  }

  const customStyles = style();
  return (
    <div className="page container-xxl align-items-center">
      {isLoading && (
        <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
          <Loader />
        </div>
      )}
      <Card className="w-100">
        <CardBody>
          <h3 className="text-center mt-3 mb-5 text-primary">
            <strong>
              {LevelZeroMappingId ? CONSTANT.UPDATE : CONSTANT.ADD} Level Zero
              Mapping
            </strong>
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="row g-4">
              <div className="col-sm-12 col-md-6">
                <label className="form-labels">Goal Index Name*</label>

                <FormGroup id="goalIndexName">
                  <Input
                    placeholder="Goal Index Name"
                    className={`${
                      isInvalidForm && !values.goalIndexName
                        ? "border border-danger input-form"
                        : "input-form"
                    }`}
                    name="goalIndexName"
                    value={values.goalIndexName}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm && !values.goalIndexName && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.NAME}</small>
                  </p>
                )}
              </div>

              <div className="col-sm-12 col-md-6" key="goal">
                <label className="form-labels">Select Goal*</label>

                <FormGroup id="goal">
                  <Select
                    styles={customStyles}
                    onChange={(selectedOptions: any) => {
                      setSelectedGoalIdArr(selectedOptions.value);
                      setSelectedGoalId(selectedOptions);
                    }}
                    options={
                      goalData &&
                      goalData.map((goal: any) => ({
                        value: goal._id,
                        label: goal.name,
                      }))
                    }
                    placeholder="Select Goal"
                    value={selectedGoalId}
                    isSearchable={true}
                    className={`${
                      isInvalidForm && selectedGoalId?.length === 0
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                </FormGroup>
                {isInvalidForm && selectedGoalId?.length === 0 && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.GOAL_ID}</small>
                  </p>
                )}
              </div>

              <div className="col-sm-12 col-md-6" key="metric">
                <label className="form-labels">Select Level One Metrics*</label>

                <FormGroup id="metric">
                  <Select
                    styles={customStyles}
                    onChange={(selectedOptions: any) => {
                      const metric = selectedOptions.map((metrics: any) => ({
                        id: metrics.value,
                        label: metrics.label,
                      }));

                      const metrics = selectedOptions.map(
                        (metric: any) => metric.value
                      );
                      setSelectedMetricIdArr(metrics);
                      setSelectedMetricName(metric);
                    }}
                    options={
                      levelOneMappingData &&
                      levelOneMappingData.map((metric: any) => ({
                        value: metric._id,
                        label: metric.metricName,
                      }))
                    }
                    placeholder="Select Level One Metrics"
                    value={selectedMetricId}
                    isSearchable={true}
                    isMulti
                    className={`${
                      isInvalidForm && selectedMetricId.length === 0
                        ? "border border-danger"
                        : ""
                    }`}
                  />
                </FormGroup>
                {isInvalidForm && selectedMetricId.length === 0 && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.METRIC_ID}</small>
                  </p>
                )}
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className={`w-25 mt-5 process-btn ${
                  isLoading ? "disabled-button" : "bg-info text-white"
                }`}
                onClick={handleSave}
                isDisable={isLoading}
              >
                {LevelZeroMappingId ? CONSTANT.UPDATE : CONSTANT.ADD} Level Zero
                Mapping
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-25 mt-5 cancel-btn float-end"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddEditLevelZeroMapping;
