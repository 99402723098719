import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { UserProfileCategoryService } from "../../../services/userCategory.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
  isValidHttpUrl,
  isValidUrl,
  ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { UserProfileSubCategoryService } from "../../../services/userSubCategory.service";

const AddEditUserProfileCategory = () => {
  const { setUserProfileCategoryData, userProfileCategoryData }: any = useContext(DataContext);

  const params = useParams();
  const navigate = useNavigate();
  const [UserProfileCategoryId, setUserCategoryId] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [UserProfileCategoryAllData, setUserProfileCategoryAllData] = useState({
    name: "",
  });

  const validationSchema = yup.object().shape({
    name: yup.string().required("required"),
  });

  const [isInvalidForm, setIsInvalidForm] = useState(false);

  const { values, errors, touched, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues: { ...UserProfileCategoryAllData },
      validationSchema,

      onSubmit: async (values, action) => {
        try {
            setIsLoading(true);
            const data = UserProfileCategoryId ? await UserProfileCategoryService.editUserProfileCategory(UserProfileCategoryId,{...values}) : await UserProfileCategoryService.addUserProfileCategory({...values});
            setIsLoading(false);

            if (data && data.status === CONSTANT.SUCCESS) {
                toast.success(data.message);
                const UserProfileCategoryName = await UserProfileSubCategoryService.getAllUserProfileCategoryName();
                setUserProfileCategoryData(UserProfileCategoryName?.data?.userProfileCategory);
                action.resetForm();

                navigate(ROUTING_CONSTANT.USER_PROFILE_CATEGORY_VIEW);

                const { _id, name } = data.data;

                if (UserProfileCategoryId) {
                    const existingIndex = userProfileCategoryData.findIndex((item: any) => item._id === _id);
                    if (existingIndex !== -1) {
                        setUserProfileCategoryData((prevData: any) => {
                            const newData = [...prevData];
                            newData[existingIndex] = { _id, name };
                            return newData;
                        });
                    }
                } 
            } else {
                toast.error(data.message);
            }
        } catch (error:any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
          }
      },
    });

  useEffect(() => {
    const init = async () => {
      if (params && decryptData(params.id)) {
        setUserCategoryId(decryptData(params.id));
        getuserCategoryById(decryptData(params.id));
      }
    };
    init();
  }, []);

  const getuserCategoryById = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await UserProfileCategoryService.getUserProfileCategoryById(id);
      setIsLoading(false);
      if (response.status === CONSTANT.SUCCESS) {
        setUserProfileCategoryAllData(response?.data?.userProfileCategory);
        setValues({ ...response?.data?.userProfileCategory });
      }
    } catch (error:any) {
        toast.error(error?.response?.data?.error);
        setIsLoading(false);
      }
  };

  const onCancelClick = () => {
    navigate(ROUTING_CONSTANT.USER_PROFILE_CATEGORY_VIEW);
  };

  const handleSave = () => {
    setIsInvalidForm(true);
  };

  return (
    <div className="page container-xxl align-items-center">
      {isLoading && (
        <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
          <Loader />
        </div>
      )}
      <Card className="w-100">
        <CardBody>
          <h3 className="text-center mt-3 mb-5 text-primary">
            <strong>
              {UserProfileCategoryId ? CONSTANT.UPDATE : CONSTANT.ADD} User Profile Category
            </strong>
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="row g-4">
              <div className="col-sm-12 col-md-6">
                <label className="form-labels">User Profile Category Name*</label>

                <FormGroup id="categoryName">
                  <Input
                    placeholder="User Profile Category Name"
                    className={`${
                      isInvalidForm && !values.name
                        ? "border border-danger input-form"
                        : "input-form"
                    }`}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm && !values.name && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.NAME}</small>
                  </p>
                )}
              </div>
            </div>

            <div>
              <Button
                type="submit"
                className={`w-25 mt-5 process-btn ${
                  isLoading ? "disabled-button" : "bg-info text-white"
                }`}
                onClick={handleSave}
                isDisable={isLoading}
              >
                {UserProfileCategoryId ? CONSTANT.UPDATE : CONSTANT.ADD} User Profile Category
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-25 mt-5 cancel-btn float-end"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddEditUserProfileCategory;
