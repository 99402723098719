import httpCommon from '../core/http-common';
import { API_URL } from '../core/constant';
import { logoutUser } from '../core/auth.service';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';


export const BotService = {
    createBot,
    updateBotById,
    getAllBots,
    getBotById,
    deleteBotById,
    getAllMetricName,
    getAllLevelOneName
};

function createBot(data: any) {
    return httpCommon.post(API_URL.CREATE_BOTS, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function updateBotById(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_BOT_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getBotById(id: any) {
    return httpCommon.get(API_URL.GET_BOT_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllBots(data: any) {
    let url = API_URL.GET_ALL_BOTS;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllMetricName() {
    return httpCommon.get(API_URL.GET_ALL_METRIC_NAME).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
function getAllLevelOneName() {
    return httpCommon.get(API_URL.GET_ALL_LEVEL_ONE_NAME).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteBotById(id: any) {
    return httpCommon.post(API_URL.DELETE_BOT_BY_ID + id).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
