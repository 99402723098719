
import React, { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import { toast } from "react-toastify";
import { UserService } from "../../../services/user.service";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import { CONSTANT } from "../../../core/static-constant";

interface ILoginHeaderProps {
    isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = () => {
    return (
        <>
            <div className="text-center h1 fw-bold mt-5">Welcome to Humotron,</div>
            <div className="text-center h4 text-muted mb-5">Set your Password</div>
        </>
    );
};


const ResetPassword = () => {
    const { darkModeStatus } = useDarkMode();
    const [singUpStatus, setSingUpStatus] = useState<boolean>();
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordsMatch, setPasswordsMatch] = useState(true);

    const { id } = useParams()
    const navigate = useNavigate()

    const resetPassword = async () => {
        const obj = {
            userId: decryptData(id),
            password: password,
            confirmPassword: confirmPassword,
        };

        try {
            setIsLoading(true);
            const data = await UserService.createAdminUserPassword(obj);
            if (data && data?.data?.status !== CONSTANT.FAIL) {
                toast.success(data?.message);
                setTimeout(() => {
                    setIsLoading(false);
                    navigate("/auth-pages/login")
                }, 2000);
            } else {
                toast.error(data?.data?.message);
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            setIsLoading(false);
        }
    };

    function handleKeyPress(event: any) {
        if (event.key === "Enter") {
            resetPassword();
        }
    }

    const handlePasswordChange = (e: any) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        setPasswordsMatch(newPassword === confirmPassword);
    };

    const handleConfirmPasswordChange = (e: any) => {
        const newConfirmPassword = e.target.value;
        setConfirmPassword(newConfirmPassword);
        setPasswordsMatch(newConfirmPassword === password);
    };

    const [isFormValid, setIsFormValid] = useState(false);


    useEffect(() => {
        setIsFormValid(password !== '' && confirmPassword !== '');
    }, [password, confirmPassword]);


    return (
        <PageWrapper
            isProtected={false}
            title="Login"
            className={classNames({
                "bg-dark": !singUpStatus,
                "bg-light": singUpStatus,
            })}
        >
            <Page className="p-0">
                <div className="row h-100 align-items-center justify-content-center">

                    <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
                        <Card className="shadow-3d-dark" data-tour="login-page">
                            <CardBody>
                                {isLoading && (
                                    <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                                        <Loader />
                                    </div>
                                )}
                                <div
                                    className={classNames("rounded-3", {
                                        "bg-l10-dark": !darkModeStatus,
                                        "bg-dark": darkModeStatus,
                                    })}
                                >

                                </div>
                                <LoginHeader />
                                <form className="row g-4">
                                    <>
                                        <div className="col-12">


                                            <FormGroup
                                                id="loginUsername"
                                                isFloating
                                                label="Password"
                                                className="mb-3"
                                            >
                                                <Input
                                                    type="password"
                                                    value={password}
                                                    onChange={handlePasswordChange}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </FormGroup>

                                            <FormGroup id="loginPassword" isFloating label="Confirm Password">
                                                <Input
                                                    type="password"
                                                    value={confirmPassword}
                                                    onChange={handleConfirmPasswordChange}
                                                    onKeyPress={handleKeyPress}
                                                />
                                            </FormGroup>



                                        </div>
                                        <div className="col-12">

                                            <Button
                                                className={`w-100 py-3 ${isLoading || !isFormValid || !passwordsMatch ? "disabled-button" : "bg-warning"
                                                    }`}
                                                onClick={resetPassword}
                                                isDisable={isLoading || !passwordsMatch || !isFormValid}
                                            >
                                                Set Password
                                            </Button>

                                        </div>
                                    </>

                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};
ResetPassword.propTypes = {
    isSignUp: PropTypes.bool,
};
ResetPassword.defaultProps = {
    isSignUp: false,
};

export default ResetPassword;
