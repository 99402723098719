import axios from "axios";
import { CONSTANT } from './static-constant';
import { BASE_URL } from './constant';
import { Localstorage } from './auth.service';
const userInfo: any = Localstorage(CONSTANT.GET,CONSTANT.AUTH_TOKEN);

const user: any = userInfo && userInfo;

export default axios.create({
  baseURL: BASE_URL,
  headers:
    user && user
      ? {
          "Content-type": "multipart/form-data",
          authorization: "Bearer " + user?.replace("Basic ", ""),
        }
      : { "Content-type": "multipart/form-data" },
});
