import React, { SVGProps } from 'react';

const Device = (props: SVGProps<SVGSVGElement>) => {
	return (
        <>
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" height="20px" width="20px" viewBox="0 0 120 120" fill='gray'>
                <path d="M85.81 120H34.19a8.39 8.39 0 0 1-8.38-8.39V8.39A8.39 8.39 0 0 1 34.19 0h51.62a8.39 8.39 0 0 1 8.38 8.39v103.22a8.39 8.39 0 0 1-8.38 8.39zM34.19 3.87a4.52 4.52 0 0 0-4.51 4.52v103.22a4.52 4.52 0 0 0 4.51 4.52h51.62a4.52 4.52 0 0 0 4.51-4.52V8.39a4.52 4.52 0 0 0-4.51-4.52z"/>
                <path d="M73.7 10.32H46.3L39.28 3.3 42.01.57l5.89 5.88h24.2L77.99.57l2.73 2.73-7.02 7.02zM47.1 103.23h25.81v3.87H47.1z"/>
            </svg>
        </>
	);
};

export default Device;
