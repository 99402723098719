import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import {
  CategoryService,
  IngredientService,
  ProductService,
  SubCategoryService,
} from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";

const AddEditIngredient = () => {
  const { ingredientData, setIngredientData }: any = useContext(DataContext);

  const params = useParams();
  const navigate = useNavigate();
  const [ingredientId, setIngredientId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [ingredientAllData, setIngredientAllData] = useState({
    name: "",
    ingredientImage: "",
    shortDescription: "",
    longDescription: "",
  });

  const [isInvalidForm, setIsInvalidForm] = useState(false);

  const validationSchema = yup.object().shape({
    name: yup.string().required(" required"),
    shortDescription: yup.string().required(" required"),
    longDescription: yup.string().required("required"),
  });

  const { values, errors, touched, handleChange, handleSubmit, setValues } =
    useFormik({
      initialValues: { ...ingredientAllData },
      validationSchema,
      onSubmit: async (values, action) => {
        try {    
            setIsLoading(true);
            const data = ingredientId ? await IngredientService.EditIngrediant(ingredientId, { ...values }) : await IngredientService.addIngrediant({ ...values });
            setIsLoading(false);

            if (data && data.status === CONSTANT.SUCCESS) {
                toast.success(data.message);
                const Ingredientname = await ProductService.getAllIngredientName();
                setIngredientData(Ingredientname.data?.ingredients);
                action.resetForm();
                navigate(ROUTING_CONSTANT.INGREDIENT_VIEW);

                const { _id, faqName } = data.data;
                if (ingredientId) {
                    const existingIndex = ingredientData.findIndex((item: any) => item._id === _id);
                    if (existingIndex !== -1) {
                        setIngredientData((prevData: any) => {
                            const newData = [...prevData];
                            newData[existingIndex] = { _id, faqName };
                            return newData;
                        });
                    }
                } 
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
          }
      },
    });

  useEffect(() => {
    const init = async () => {
      if (params && decryptData(params.id)) {
        setIngredientId(decryptData(params.id));
        getIngrediantById(decryptData(params.id));
      }
    };
    init();
  }, []);

  const getIngrediantById = async (id: any) => {
    try {
      setIsLoading(true);
      const response = await IngredientService.getIngrediantById(id);
      setIsLoading(false);

      if (response.status === CONSTANT.SUCCESS) {
        setIngredientAllData(response?.data?.ingredient);
        setValues({ ...response?.data?.ingredient });
      }
    } catch (error: any) {
        toast.error(error?.response?.data?.error);
        setIsLoading(false);
      }
  };

  const onCancelClick = () => {
    navigate(ROUTING_CONSTANT.INGREDIENT_VIEW);
  };

  return (
    <div className="page container-xxl align-items-center">
      {isLoading && (
        <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
          <Loader />
        </div>
      )}
      <Card className="w-100">
        <CardBody>
          <h3 className="text-center mt-3 mb-5 text-primary">
            <strong>
              {ingredientId ? CONSTANT.UPDATE : CONSTANT.ADD} Ingrediant
            </strong>
          </h3>
          <form onSubmit={handleSubmit}>
            <div className="row g-4">
              <div className="col-sm-12 col-md-6">
                <FormGroup id="name" label="Ingrediant Name*" isFloating>
                  <Input
                    placeholder="name"
                    className={`${
                      isInvalidForm && !values.name
                        ? "border border-danger"
                        : ""
                    }`}
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm && !values.name && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.NAME}</small>
                  </p>
                )}
              </div>

              <div className="col-sm-12 col-md-6">
                <FormGroup
                  id="longDescription"
                  label="Long Description*"
                  isFloating
                >
                  <Input
                    placeholder="Long Description"
                    className={`${
                      isInvalidForm && !values.longDescription
                        ? "border border-danger"
                        : ""
                    }`}
                    name="longDescription"
                    value={values.longDescription}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm && !values.longDescription && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.LONG_DESCRIPTION}</small>
                  </p>
                )}
              </div>

              <div className="col-sm-12 col-md-6">
                <FormGroup
                  id="shortDescription"
                  label="Short Description*"
                  isFloating
                >
                  <Input
                    placeholder="Short Description"
                    className={`${
                      isInvalidForm && !values.shortDescription
                        ? "border border-danger"
                        : ""
                    }`}
                    name="shortDescription"
                    value={values.shortDescription}
                    onChange={handleChange}
                  />
                </FormGroup>
                {isInvalidForm && !values.shortDescription && (
                  <p className="text-danger ms-2">
                    <small>{CONSTANT.SHORT_DESCRIPTION}</small>
                  </p>
                )}
              </div>
              <div className="col-sm-12 col-md-6">
                <FormGroup id="ingredientImage" label="Image URL" isFloating>
                  <Input
                    placeholder="Image URL"
                    name="ingredientImage"
                    value={values.ingredientImage}
                    onChange={handleChange}
                  />
                </FormGroup>
              </div>
            </div>

            <div>
              <Button
                type="submit"
                onClick={() => setIsInvalidForm(true)}
                isDisable={isLoading}
                className={`w-25 mt-5 process-btn ${
                  isLoading ? " disabled-button" : "bg-info text-white"
                }`}
              >
                {ingredientId ? CONSTANT.UPDATE : CONSTANT.ADD} Ingrediant
              </Button>
              <Button
                type="button"
                color="danger"
                className="w-25 mt-5 cancel-btn float-end"
                onClick={onCancelClick}
              >
                Cancel
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
};

export default AddEditIngredient;
