import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { NuggetsService } from "../../../services/nuggets.service";
import { NuggetsTagService } from "../../../services/nuggetsTag.service";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";
import Textarea from "../../../components/bootstrap/forms/Textarea";

interface Content {
    subTitle: string;
    description: string;
}

interface Section {
    title: string;
    content: Content[];
}

interface RelatedLink {
    title: string;
    url: string;
}

const AddEditNuggets = () => {
    const { setNuggetData, nuggetData, nuggetTagData, setNuggetTagData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [NuggetsId, setNuggetsId] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const [selectedNuggetTagName, setSelectedNuggetTagName] = useState([]);
    const [selectedNuggetTagId, setSelectedNuggetTagId] = useState<any>([]);
    const [selectedNuggetTagIdArr, setSelectedNuggetTagIdArr] = useState<any>([]);

    const [nuggetTagAllData, setNuggetTagAllData] = useState<any>("");

    const [NuggetsAllData, setNuggetsAllData] = useState<any>({
        nuggetName: "",
        nuggetTopic: "",
        secContextTags: "",
        chatPrompts: [''],
        preview: {
            contentTypes: []
        },
        description: {
            overview: '',
            sections: [],
            relatedLinks: []
        }
    });

    useEffect(() => {
        // if (nuggetTagData?.length === 0) {
        getAllNuggetTagName();
        // }
        getAllNuggetTagByType();
    }, []);

    const getAllNuggetTagName = async () => {
        try {
            const requestBody = {
                tagLevel: CONSTANT.NUGGET_TAG.SECONDARY_TAG,
                tagType: CONSTANT.TAG_LEVEL.CONTEXT_TAG 
            };
            let data = await NuggetsTagService.getAllNuggetTagByType(requestBody);
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.nuggetTag;
                setNuggetTagData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const validationSchema = yup.object().shape({
        nuggetName: yup.string().required("required"),
        nuggetTopic: yup.string().required("required"),
    });

    const getAllNuggetTagByType = async () => {
        try {
            const requestBody = {
                tagLevel: CONSTANT.NUGGET_TAG.SECONDARY_TAG,
                tagType: CONSTANT.TAG_LEVEL.CONTENT_TAG 
            };
            let data = await NuggetsTagService.getAllNuggetTagByType(requestBody);
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.nuggetTag;
                setNuggetTagAllData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    // const getAllSecondaryNuggetTagByPrimaryNuggetTag = async (selectedId: any) => {
    //     try {
    //         let data = await NuggetsTagService.getAllSecondaryNuggetTagByPrimaryNuggetTag(selectedId);
    //         if (data && data.status === CONSTANT.SUCCESS) {
    //             const responseData = data?.data?.nuggetTag;
    //             setNuggetTagSecondaryData(responseData);
    //         } else {
    //             toast.error(data.message);
    //             setNuggetTagSecondaryData([])
    //         }
    //     } catch (error: any) {
    //         setNuggetTagSecondaryData([])
    //         toast.error(error?.response?.data?.message);
    //     }
    // };

    const handleAddChatPrompt = () => {
        if (NuggetsAllData.chatPrompts.length === 0 || NuggetsAllData.chatPrompts[NuggetsAllData.chatPrompts.length - 1] !== '') {
            setNuggetsAllData({
                ...NuggetsAllData,
                chatPrompts: [...NuggetsAllData.chatPrompts, ''],
            });
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveChatPrompt = (index: any) => {
        const updatedChatPrompts = NuggetsAllData.chatPrompts.filter((_: any, i: any) => i !== index).filter((prompt: any) => prompt !== '');
        setNuggetsAllData({
            ...NuggetsAllData,
            chatPrompts: updatedChatPrompts,
        });
    };

    const handleChatPromptChange = (index: any, event: any) => {
        const newChatPrompts = NuggetsAllData.chatPrompts.map((prompt: any, i: any) =>
            i === index ? event.target.value : prompt
        ).filter((prompt: any) => prompt !== '');
        setNuggetsAllData({
            ...NuggetsAllData,
            chatPrompts: newChatPrompts,
        });
    };

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...NuggetsAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    const filteredChatPrompts = NuggetsAllData.chatPrompts.filter((prompt: any) => prompt !== '');
                    const filteredValues: any = { ...values, chatPrompts: filteredChatPrompts, secContextTags: selectedNuggetTagIdArr, description: NuggetsAllData.description, preview: NuggetsAllData.preview };
                    setIsLoading(true);
                    const data = NuggetsId ? await NuggetsService.updateNuggetById(NuggetsId, filteredValues) : await NuggetsService.addNugget(filteredValues);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const MetricName = await NuggetsService.getAllNuggetName();
                        setNuggetData(MetricName?.data?.nugget);
                        navigate(ROUTING_CONSTANT.NUGGETS_VIEW);
                        action.resetForm();

                        const { _id, name } = data.data;

                        if (NuggetsId) {
                            const existingIndex = nuggetData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setNuggetData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    useEffect(() => {
        const data = selectedNuggetTagName
            ? selectedNuggetTagName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedNuggetTagId(data);
    }, [selectedNuggetTagName]);

    // useEffect(() => {
    //     const data = selectedSecondaryName
    //         ? selectedSecondaryName.map((item: any) => ({
    //             value: item.id,
    //             label: item.label,
    //         }))
    //         : [];

    //     setSelectedSecondaryId(data);
    // }, [selectedSecondaryName]);

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setNuggetsId(decryptData(params.id));
                getNuggetById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getNuggetById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await NuggetsService.getNuggetById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setNuggetsAllData(response?.data?.nugget);
                setValues({ ...response?.data?.nugget });
                const selectedNuggetsNamesss = response?.data?.nugget?.secContextTags.map(
                    (nuggets: any) => ({
                        id: nuggets._id,
                        label: nuggets.tagName,
                    })
                );
                setSelectedNuggetTagName(selectedNuggetsNamesss);

                const nugget = response?.data?.nugget?.secContextTags.map((nuggets: any) => nuggets._id);
                setSelectedNuggetTagIdArr(nugget);

                setSelectedTags(response);

            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const setSelectedTags = (response: any) => {
        if (response?.data?.nugget?.preview?.contentTypes) {
            const contentTypes = response.data.nugget.preview.contentTypes;
            const updatedContentTypes = contentTypes.map((contentType: any) => ({
                secContentTag: contentType.secContentTag._id,
                // secondaryTags: contentType.secondaryTags.map((tag: any) => tag._id),
                description: contentType.description || '',
            }));
            setContentTypes(updatedContentTypes);
            // const updatedSelectedSecondaryName = contentTypes.map((contentType: any) =>
            //     contentType.secondaryTags.map((tag: any) => ({
            //         value: tag._id,
            //         label: tag.tagName
            //     }))
            // );
            // setSelectedSecondaryName(updatedSelectedSecondaryName);

        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.NUGGETS_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }
    const initialContentType: any = {
        secContentTag: '',
        // secondaryTags: [],
        description: ''
    };

    const [contentTypes, setContentTypes] = useState<any>([initialContentType]);

    useEffect(() => {
        setNuggetsAllData((prevData: any) => ({
            ...prevData,
            preview: {
                ...prevData.preview,
                contentTypes: contentTypes
            }
        }));
    }, [contentTypes]);

    const handleAddContentType = () => {
        const newContentType = {
            ...initialContentType,
            // secondaryTags: []
        };

        setContentTypes((prevContentTypes: any) => [...prevContentTypes, newContentType]);

        setNuggetsAllData((prevData: any) => ({
            ...prevData,
            preview: {
                ...prevData.preview,
                contentTypes: [...prevData.preview.contentTypes, newContentType]
            }
        }));
    };

    const handleRemoveContentType = (index: number) => {
        setContentTypes((prevContentTypes: any) => {
            const updatedContentTypes = [...prevContentTypes];
            updatedContentTypes.splice(index, 1);
            return updatedContentTypes;
        });
        setNuggetsAllData((prevData: any) => ({
            ...prevData,
            contentTypes: prevData?.contentTypes?.filter((_: any, i: any) => i !== index)
        }));
    };

    const handleInputChange = (index: number, e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const updatedContentTypes = [...contentTypes];
        updatedContentTypes[index] = { ...updatedContentTypes[index], [name]: value };
        setContentTypes(updatedContentTypes);
        setNuggetsAllData((prevData: any) => ({
            ...prevData,
            contentTypes: updatedContentTypes
        }));
    };

    const customStyles = style();

    const handleOverviewChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                overview: e.target.value
            }
        });
    };

    const addSection = () => {
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                sections: [...NuggetsAllData.description.sections, { title: '', content: [] }]
            }
        });
    };

    const removeSection = (sectionIndex: number) => {
        const newSections = NuggetsAllData.description.sections.filter((_: any, index: any) => index !== sectionIndex);
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                sections: newSections
            }
        });
    };

    const addContent = (sectionIndex: number) => {
        const newSections = [...NuggetsAllData.description.sections];
        newSections[sectionIndex].content.push({ subTitle: '', description: '' });
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                sections: newSections
            }
        });
    };

    const removeContent = (sectionIndex: number, contentIndex: number) => {
        const newSections = [...NuggetsAllData.description.sections];
        newSections[sectionIndex].content = newSections[sectionIndex].content.filter((_: any, index: any) => index !== contentIndex);
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                sections: newSections
            }
        });
    };

    const addRelatedLink = () => {
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                relatedLinks: [...NuggetsAllData.description.relatedLinks, { title: '', url: '' }]
            }
        });
    };

    const removeRelatedLink = (index: number) => {
        const newRelatedLinks = NuggetsAllData.description.relatedLinks.filter((_: any, i: any) => i !== index);
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                relatedLinks: newRelatedLinks
            }
        });
    };

    const handleSectionChange = (sectionIndex: number, field: keyof Section, value: string) => {
        const newSections = [...NuggetsAllData.description.sections];
        newSections[sectionIndex][field] = value;
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                sections: newSections
            }
        });
    };

    const handleContentChange = (sectionIndex: number, contentIndex: number, field: keyof Content, value: string) => {
        const newSections = [...NuggetsAllData.description.sections];
        newSections[sectionIndex].content[contentIndex][field] = value;
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                sections: newSections
            }
        });
    };

    const handleRelatedLinkChange = (index: number, field: keyof RelatedLink, value: string) => {
        const newRelatedLinks = [...NuggetsAllData.description.relatedLinks];
        newRelatedLinks[index][field] = value;
        setNuggetsAllData({
            ...NuggetsAllData,
            description: {
                ...NuggetsAllData.description,
                relatedLinks: newRelatedLinks
            }
        });
    };

    function indexToAlphabet(index: any) {
        let result = '';
        while (index >= 0) {
          result = String.fromCharCode((index % 26) + 65) + result;
          index = Math.floor(index / 26) - 1;
        }
        return result;
      }

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {NuggetsId ? CONSTANT.UPDATE : CONSTANT.ADD} Nugget
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Nuggets Name*</label>

                                <FormGroup id="nuggetName">
                                    <Input
                                        placeholder="Nuggets Name"
                                        className={`${isInvalidForm && !values.nuggetName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="nuggetName"
                                        value={values.nuggetName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.nuggetName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Nuggets Topic Name*</label>

                                <FormGroup id="nuggetTopic">
                                    <Input
                                        placeholder="Nuggets Topic Name"
                                        className={`${isInvalidForm && !values.nuggetTopic
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="nuggetTopic"
                                        value={values.nuggetTopic}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.nuggetTopic && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="nuggets">
                                <label className="form-labels">Select Secondary Context Nugget Tags*</label>

                                <FormGroup id="nuggets">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const nuggets = selectedOptions.map((nugget: any) => ({
                                                id: nugget.value,
                                                label: nugget.label,
                                            }));

                                            const nugget = selectedOptions.map((nuggets: any) => nuggets.value);
                                            setSelectedNuggetTagIdArr(nugget);

                                            setSelectedNuggetTagName(nuggets);
                                        }}
                                        options={
                                            nuggetTagData &&
                                            nuggetTagData
                                                .map((nuggets: any) => ({
                                                    value: nuggets._id,
                                                    label: nuggets.tagName,
                                                }))
                                        }
                                        placeholder="Select Secondary Context Nugget Tags"
                                        value={selectedNuggetTagId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedNuggetTagId.length === 0
                                            ? "border input-form border-danger"
                                            : "input-form"
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedNuggetTagId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <div>
                                        <label className="form-labels">Chat Prompts</label><svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-plus-circle sku-icon cursor-pointer"
                                            viewBox="0 0 16 16"
                                            onClick={handleAddChatPrompt}
                                        >
                                            <path
                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                            <path
                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                        </svg>
                                    </div>
                                    <div>
                                        {NuggetsAllData?.chatPrompts.map((prompt: any, index: any) => (
                                            <div key={index} className="d-flex align-items-center justify-content-center mt-2">
                                                <Input
                                                    type="text"
                                                    value={prompt}
                                                    placeholder="Chat Prompt"
                                                    onChange={(event) => handleChatPromptChange(index, event)}
                                                    className={`${isInvalidForm && !values.chatPrompts
                                                        ? "border"
                                                        : ""
                                                        }`}
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => handleRemoveChatPrompt(index)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-12">
                                <FormGroup id="options">
                                    <div>
                                        <>
                                            <label className="form-labels">Content Types</label>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddContentType}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                        </>
                                    </div>
                                    <div>
                                        {contentTypes?.map((contentType: any, index: any) => (
                                            <div key={index} style={{ marginBottom: '20px' }} className="d-flex gap-2 align-items-center justify-content-center mt-2">
                                                <FormGroup id="device" className="col-md-6">
                                                    <Select
                                                        styles={customStyles}
                                                        onChange={(selectedOption) => {
                                                            setContentTypes((prevState: any) => prevState.map((contentType: any, idx: any) =>
                                                                idx === index
                                                                    ? { ...contentType, secContentTag: selectedOption ? selectedOption.value : "" }
                                                                    : contentType,
                                                            ));
                                                            // if(selectedOption){
                                                            //     getAllSecondaryNuggetTagByPrimaryNuggetTag(selectedOption?.value)
                                                            // }
                                                        }}
                                                        options={
                                                            nuggetTagAllData &&
                                                            nuggetTagAllData.map((devices: any) => ({
                                                                value: devices._id,
                                                                label: devices.tagName,
                                                            }))
                                                        }
                                                        placeholder="Select Secondary Content Nugget Tags"
                                                        value={
                                                            contentTypes[index].secContentTag
                                                                ? {
                                                                    value: contentTypes[index].secContentTag,
                                                                    label: nuggetTagAllData.find((tag: any) => tag._id === contentTypes[index].secContentTag)?.tagName || ""
                                                                }
                                                                : null
                                                        }
                                                        className={`${"border border rounded-circle"
                                                            }`}
                                                        isSearchable={true}
                                                        isClearable={true}
                                                    />
                                                </FormGroup>
                                                <Textarea
                                                    rows={5}
                                                    placeholder="Description"
                                                    className={`${isInvalidForm && !values.nuggetName
                                                        ? "border input-form"
                                                        : "input-form"
                                                        }`}
                                                    name="description"
                                                    value={contentType.description}
                                                    onChange={(e: any) => handleInputChange(index, e)}
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-circle sku-icon cursor-pointer mt-1"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => handleRemoveContentType(index)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>


                            <div className="col-sm-12 col-md-12">
                                <label className="form-labels">Overview</label>
                                <FormGroup id="nuggetTopic">
                                    <Textarea
                                        placeholder="Nuggets Overview"
                                        className={`${isInvalidForm && !values.nuggetTopic
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="nuggetTopic"
                                        value={NuggetsAllData.description.overview}
                                        onChange={handleOverviewChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="">
                                <div className="d-flex">
                                    <label className="form-labels">Sections</label>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-plus-circle sku-icon cursor-pointer"
                                        viewBox="0 0 16 16"
                                        onClick={addSection}
                                    >
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </div>
                                {NuggetsAllData.description.sections.map((section: any, sectionIndex: any) => (
                                    <div key={sectionIndex}>
                                        <div className="d-flex align-items-center justify-content-center mt-2 gap-2 col-md-12">
                                            <h4>{sectionIndex + 1}.</h4>
                                            <Input
                                                value={section.title}
                                                onChange={(e: any) => handleSectionChange(sectionIndex, 'title', e.target.value)}
                                                placeholder="Section Title"
                                                className="input-form"
                                            />
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="currentColor"
                                                className="bi bi-plus-circle section-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={() => addContent(sectionIndex)}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg>
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"  
                                                fill="currentColor"
                                                className="bi bi-dash-circle section-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={() => removeSection(sectionIndex)}
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                            </svg>
                                        </div>
                                        {section.content.map((content: any, contentIndex: any) => (
                                            <div key={contentIndex} className="d-flex gap-2 mt-2 align-items-center justify-content-center mx-5 col-md-10">
                                                <h6>{indexToAlphabet(contentIndex)}</h6>
                                                <Input
                                                    value={content.subTitle}
                                                    onChange={(e: any) => handleContentChange(sectionIndex, contentIndex, 'subTitle', e.target.value)}
                                                    placeholder={`Section ${sectionIndex + 1} Subtitle`}
                                                    className="input-form"
                                                />
                                                <Textarea
                                                    value={content.description}
                                                    onChange={(e: any) => handleContentChange(sectionIndex, contentIndex, 'description', e.target.value)}
                                                    placeholder={`Section ${sectionIndex + 1} Description`}
                                                    rows={2}
                                                />
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-dash-circle sku-icon cursor-pointer"
                                                    viewBox="0 0 16 16"
                                                    onClick={() => removeContent(sectionIndex, contentIndex)}
                                                >
                                                    <path
                                                        fillRule="evenodd"
                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                </svg>
                                            </div>
                                        ))}

                                    </div>
                                ))}
                            </div>

                            <div className="">
                                <div className="d-flex">
                                    <label className="form-labels">Related Links</label>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="16"
                                        fill="currentColor"
                                        className="bi bi-plus-circle sku-icon cursor-pointer"
                                        viewBox="0 0 16 16"
                                        onClick={addRelatedLink}
                                    >
                                        <path
                                            d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                        <path
                                            d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                    </svg>
                                </div>
                                {NuggetsAllData.description.relatedLinks.map((link: any, index: any) => (
                                    <div key={index} className="d-flex gap-2 align-items-center justify-content-center">
                                        <Input
                                            value={link.title}
                                            onChange={(e: any) => handleRelatedLinkChange(index, 'title', e.target.value)}
                                            placeholder="Link Title"
                                            className="input-form"
                                        />
                                        <Input
                                            value={link.url}
                                            onChange={(e: any) => handleRelatedLinkChange(index, 'url', e.target.value)}
                                            placeholder="URL"
                                            className="input-form"
                                        />
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            fill="currentColor"
                                            className="bi bi-dash-circle sku-icon cursor-pointer"
                                            viewBox="0 0 16 16"
                                            onClick={() => removeRelatedLink(index)}
                                        >
                                            <path
                                                fillRule="evenodd"
                                                d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                        </svg>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {NuggetsId ? CONSTANT.UPDATE : CONSTANT.ADD} Nugget
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div >
    );
};

export default AddEditNuggets;
