import httpCommon from "../core/http-common";
import { API_URL } from "../core/constant";
import { logoutUser } from "../core/auth.service";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";

export const DeliveryOptionsService = {
    getAllDeliveryOption,
    deleteDeliveryOptionsById,
    getDeliveryOptionsById,
    updateDeliveryOptionsById,
    createDeliveryOption,
};

function getAllDeliveryOption(data: any) {
    return httpCommon
        .post(API_URL.GET_ALL_DELIVERY_OPTION, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function deleteDeliveryOptionsById(id: any) {
    return httpCommon
        .get(API_URL.DELETE_DELIVERY_OPTIONS_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getDeliveryOptionsById(id: any) {
    return httpCommon
        .get(API_URL.GET_DELIVERY_OPTIONS_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function updateDeliveryOptionsById(id: any, data: any) {
    return httpCommon
        .post(API_URL.UPDATE_DELIVERY_OPTIONS_BY_ID + id, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function createDeliveryOption(data: any) {
    return httpCommon
        .post(API_URL.CREATE_DELIVERY_OPTION, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}
