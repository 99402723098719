import { BounceLoader } from "react-spinners";

const Loader = () => {
  return (
    <div className="overlay">
      <BounceLoader
        color="#123abc"
        loading={true}
        className="loader-div "
        size={50}
      />
    </div>
  );
};

export default Loader;
