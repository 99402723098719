import React, { ReactNode, useContext, useEffect, useState } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Brand from "../../../layout/Brand/Brand";
import Navigation, {
  NavigationLine,
} from "../../../layout/Navigation/Navigation";
import User from "../../../layout/User/User";
import { dashboardPagesMenu } from "../../../menu";
import ThemeContext from "../../../contexts/themeContext";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Hand from "../../../assets/img/hand.png";
import HandWebp from "../../../assets/img/hand.webp";
import Icon from "../../../components/icon/Icon";
import Button from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import Aside, {
  AsideBody,
  AsideFoot,
  AsideHead,
} from "../../../layout/Aside/Aside";
import { Localstorage } from "../../../core/auth.service";
import { CONSTANT } from "../../../core/static-constant";

const DefaultAside = () => {
  const { asideStatus, setAsideStatus } = useContext(ThemeContext);

  const [doc, setDoc] = useState(
    Localstorage(CONSTANT.GET, CONSTANT.FACIT_ASIDE_DOC_STATUS) === "true" || false
  );

  const { t } = useTranslation(["translation", "menu"]);

  const { darkModeStatus } = useDarkMode();

  useEffect(() => {
    document.title = "Humotron Admin";
  }, []);

  return (
    <Aside>
      <AsideHead>
        <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
      </AsideHead>
      <AsideBody>
        {!doc && (
          <>
            <Navigation menu={dashboardPagesMenu} id="aside-dashboard" />
            <NavigationLine />
          </>
        )}
        {doc && (
          <>
            <NavigationLine />
            <NavigationLine />
          </>
        )}

        {asideStatus && doc && (
          <Card className="m-3 ">
            <CardBody className="pt-0">
              <img
                srcSet={HandWebp}
                src={Hand}
                alt="Hand"
                width={130}
                height={130}
              />
              <p
                className={classNames("h4", {
                  "text-dark": !darkModeStatus,
                  "text-light": darkModeStatus,
                })}
              >
                {t("Everything is ready!") as ReactNode}
              </p>
              <Button
                color="info"
                isLight
                className="w-100"
                onClick={() => {
                  Localstorage(CONSTANT.SET, CONSTANT.FACIT_ASIDE_DOC_STATUS, CONSTANT.FALSE)
                  setDoc(false);
                }}
              >
                {t("Demo Pages") as ReactNode}
              </Button>
            </CardBody>
          </Card>
        )}
      </AsideBody>
      <AsideFoot>
        <nav aria-label="aside-bottom-menu">
          <div className="navigation">
            <div
              role="presentation"
              className="navigation-item cursor-pointer"
              onClick={() => {
                Localstorage(CONSTANT.SET, CONSTANT.FACIT_ASIDE_DOC_STATUS, String(!doc))
                setDoc(!doc);
              }}
              data-tour="documentation"
            ></div>
          </div>
        </nav>
        <User />
      </AsideFoot>
    </Aside>
  );
};

export default DefaultAside;
