import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import { RecipeService, RecipeBundleService } from "../../../services";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { isValidHttpUrl, isValidUrl, ROUTING_CONSTANT } from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import Select from "react-select";
import { BotService } from "../../../services/bot.service";
import Textarea from "../../../components/bootstrap/forms/Textarea";

const AddEditRecipeBundle = () => {
    const { recipeBundleData, setRecipeBundleData, recipeData, setRecipeData, setMetricData, metricData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [recipeId, setRecipeId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [selectedRecipeName, setSelectedRecipeName] = useState([]);
    const [selectedRecipeId, setSelectedRecipeId] = useState<any>([]);
    const [selectedRecipeIdArr, setSelectedRecipeIdArr] = useState<any>([]);

    const [selectedMetricName, setSelectedMetricName] = useState("");
    const [selectedMetricId, setSelectedMetricId] = useState<any>("");

    const [recipeBundleAllData, setRecipeBundleAllData] = useState({
        recipeBundleName: "",
        recipeBundleShortDesc: "",
        keyBenefits: "",
        recipeBundleRecommendationTagImage: "",
        recipeBundleRecommendationTag: "",
        recipeBundleWhy: [{ name: "", description: "" }],
        recipeBundleRecommendation: [{ title: "", description: "" }],
        metricId: "",
        recipesId: "",
        recipeCount: "",
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const validationSchema = yup.object().shape({
        recipeBundleName: yup.string().required(" required"),
        recipeBundleShortDesc: yup.string().required(" required"),
        keyBenefits: yup.string().required("required"),
    });

    const handleAddOption = () => {
        const newOption = { name: "", description: "" };
        if (values.recipeBundleWhy.every((option) => option.name !== "" && option.description !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                recipeBundleWhy: [...prevValues.recipeBundleWhy, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.recipeBundleWhy];
            updatedOptions.splice(index, 1);
            return { ...prevValues, recipeBundleWhy: updatedOptions };
        });
    };

    const handleAddRecipeBundleRecommendationOption = () => {
        const newOption = { title: "", description: "" };
        if (values.recipeBundleRecommendation.every((option) => option.title !== "" && option.description !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                recipeBundleRecommendation: [...prevValues.recipeBundleRecommendation, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveRecipeBundleRecommendationOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.recipeBundleRecommendation];
            updatedOptions.splice(index, 1);
            return { ...prevValues, recipeBundleRecommendation: updatedOptions };
        });
    };

    useEffect(() => {
        if (recipeData?.length === 0) {
            getAllRecipeName();
        }
        if (metricData?.length === 0) {
            getAllMetricName();
        }
    }, []);

    useEffect(() => {
        const data = selectedRecipeName
            ? selectedRecipeName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedRecipeId(data);
    }, [selectedRecipeName]);

    const getAllMetricName = async () => {
        try {
            let data: any = await BotService.getAllMetricName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.metric;
                setMetricData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const getAllRecipeName = async () => {
        try {
            let data: any = await RecipeService.getAllRecipeName();
            if (data && data.status === CONSTANT.SUCCESS) {
                const responseData = data?.data?.recipe;
                setRecipeData(responseData);
            } else {
                toast.error(data.message);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
    };

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...recipeBundleAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                const filteredOptions = values.recipeBundleRecommendation.filter((option: any) => option.title !== "" && option.description !== "");
                const filteredWhyOptions = values.recipeBundleWhy.filter((option: any) => option.name !== "" && option.description !== "");
                const payload = {
                    ...values,
                    recipesId: selectedRecipeIdArr,
                    metricId: selectedMetricId,
                    recipeBundleRecommendation: filteredOptions,
                    recipeCount: selectedRecipeIdArr.length.toString(),
                    recipeBundleWhy: filteredWhyOptions,
                };
                setIsLoading(true);
                const data = recipeId ? await RecipeBundleService.updateRecipeBundleById(recipeId, payload) : await RecipeBundleService.createRecipeBundle(payload);
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();
                    navigate(ROUTING_CONSTANT.RECIPE_BUNDLE_VIEW);
                    const RecipeName = await RecipeBundleService.getAllRecipeBundleName();
                    setRecipeBundleData(RecipeName?.data?.recipeBundle);

                    const { _id, recipeBundleName } = data.data;
                    if (recipeId) {
                        const existingIndex = recipeBundleData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setRecipeBundleData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, recipeBundleName };
                                return newData;
                            });
                        }
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                console.error(error);
                toast.error(error.response.data.error);
                setIsLoading(false);
            }
        },
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setRecipeId(decryptData(params.id));
                getRecipeBundleById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const getRecipeBundleById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await RecipeBundleService.getRecipeBundleById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setRecipeBundleAllData(response?.data?.recipeBundle);
                setValues({ ...response?.data?.recipeBundle });
                setSelectedMetricName(response?.data?.recipeBundle?.metricId?.metricName);
                setSelectedMetricId(response?.data?.recipeBundle?.metricId?._id);
                const selectedRecommendationNames = response?.data?.recipeBundle?.recipesId.map(
                    (recipe: any) => ({
                        id: recipe._id,
                        label: recipe.recipeName,
                    })
                );
                setSelectedRecipeName(selectedRecommendationNames);
                const recipe = response?.data?.recipeBundle?.recipesId.map((recipe: any) => recipe._id);
                setSelectedRecipeIdArr(recipe);
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
            toast.error(error.response.data.error);
        }
    };
    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.RECIPE_BUNDLE_VIEW);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{recipeId ? CONSTANT.UPDATE : CONSTANT.ADD} Recipe Bundle</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Bundle Name*</label>
                                <FormGroup id="recipeBundleName">
                                    <Input
                                        placeholder="Recipe Bundle Name"
                                        className={`${isInvalidForm && !values.recipeBundleName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="recipeBundleName"
                                        value={values.recipeBundleName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.recipeBundleName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Bundle Short Description*</label>
                                <FormGroup id="recipeBundleShortDesc">
                                    <Input
                                        placeholder="Recipe Bundle Short Description"
                                        className={`${isInvalidForm && !values.recipeBundleShortDesc
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="recipeBundleShortDesc"
                                        value={values.recipeBundleShortDesc}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.recipeBundleShortDesc && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Bundle Key Benefits*</label>
                                <FormGroup id="keyBenefits">
                                    <Textarea
                                        rows={5}
                                        placeholder="Recipe Bundle Key Benefits"
                                        className={`${isInvalidForm && !values.keyBenefits
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="keyBenefits"
                                        value={values.keyBenefits}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm && !values.keyBenefits && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Bundle Recommendation Tag</label>
                                <FormGroup id="recipeBundleRecommendationTag">
                                    <Input
                                        placeholder="Recipe Bundle Recommendation Tag"
                                        name="recipeBundleRecommendationTag"
                                        value={values.recipeBundleRecommendationTag}
                                        onChange={handleChange}
                                        className={`${isInvalidForm && !values.recipeBundleRecommendationTag
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Recipe Bundle Why</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.recipeBundleWhy?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Recipe Bundle Why Name"
                                                        className={`${isInvalidForm && !option.name ? "border input-form" : "input-form"}`}
                                                        value={option.name}
                                                        name={`recipeBundleWhy[${index}].name`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Recipe Bundle Why Description"
                                                        className={`${isInvalidForm && !option.description ? "border input-form" : "input-form"}`}
                                                        value={option.description}
                                                        name={`recipeBundleWhy[${index}].description`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <FormGroup id="options">
                                    <>
                                        <div>
                                            <><label className="form-labels">Recipe Bundle Recommendations</label><svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                fill="currentColor"
                                                className="bi bi-plus-circle sku-icon cursor-pointer"
                                                viewBox="0 0 16 16"
                                                onClick={handleAddRecipeBundleRecommendationOption}
                                            >
                                                <path
                                                    d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                <path
                                                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                            </svg></>
                                        </div>
                                    </>
                                    <div>
                                        {values?.recipeBundleRecommendation?.map((option: any, index: any) => (
                                            <div key={index} className="">
                                                <div className="d-flex gap-2 align-items-center justify-content-center mt-1">
                                                    <Input
                                                        placeholder="Recipe Bundle Recommendation Why Title"
                                                        className={`${isInvalidForm && !option.title ? "border input-form" : "input-form"}`}
                                                        value={option.title}
                                                        name={`recipeBundleRecommendation[${index}].title`}
                                                        onChange={handleChange} />
                                                    <Input
                                                        placeholder="Recipe Bundle Recommendation Why Description"
                                                        className={`${isInvalidForm && !option.description ? "border input-form" : "input-form"}`}
                                                        value={option.description}
                                                        name={`recipeBundleRecommendation[${index}].description`}
                                                        onChange={handleChange} />
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        fill="currentColor"
                                                        className="bi bi-dash-circle sku-icon cursor-pointer"
                                                        viewBox="0 0 16 16"
                                                        onClick={() => handleRemoveRecipeBundleRecommendationOption(index)}
                                                    >
                                                        <path
                                                            fillRule="evenodd"
                                                            d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                    </svg>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Recipe Bundle Recommendation Tag Image</label>
                                <FormGroup id="recipeBundleRecommendationTagImage">
                                    <Input
                                        placeholder="Recipe Bundle Recommendation Tag Image"
                                        className={`${isInvalidForm && !values.recipeBundleRecommendationTagImage
                                            ? "border input-form"
                                            : "input-form"
                                            }`}
                                        name="recipeBundleRecommendationTagImage"
                                        value={values.recipeBundleRecommendationTagImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6" key="recipe">
                                <label className="form-labels">Select Recipes*</label>

                                <FormGroup id="recipe">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const recipes = selectedOptions.map((recipe: any) => ({
                                                id: recipe.value,
                                                label: recipe.label,
                                            }));

                                            const recipe = selectedOptions.map((recipe: any) => recipe.value);
                                            setSelectedRecipeIdArr(recipe);

                                            setSelectedRecipeName(recipes);
                                        }}
                                        options={
                                            recipeData &&
                                            recipeData
                                                .map((recipe: any) => ({
                                                    value: recipe._id,
                                                    label: recipe.recipeName,
                                                }))
                                        }
                                        placeholder="Select recipes"
                                        value={selectedRecipeId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedRecipeId.length === 0
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedRecipeId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.RECIPE_ID}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="metric">
                                <label className="form-labels">Select Metric*</label>

                                <FormGroup id="metric">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOption) => {
                                            setSelectedMetricId(
                                                selectedOption ? selectedOption.value : ""
                                            );
                                            setSelectedMetricName(
                                                selectedOption ? selectedOption.label : ""
                                            );
                                        }}
                                        options={
                                            metricData &&
                                            metricData
                                                .map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.metricName,
                                                }))
                                        }
                                        placeholder="Select Metric"
                                        value={
                                            selectedMetricName
                                                ? {
                                                    value: selectedMetricId,
                                                    label: selectedMetricName,
                                                }
                                                : null
                                        }
                                        isSearchable={true}
                                        className={`${isInvalidForm && !selectedMetricName
                                            ? "border border-danger"
                                            : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && !selectedMetricName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.METRIC_ID}</small>
                                    </p>
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={() => setIsInvalidForm(true)}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {recipeId ? CONSTANT.UPDATE : CONSTANT.ADD} Recipe Bundle
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditRecipeBundle;
