import httpCommon from '../core/http-common';
import { logoutUser } from '../core/auth.service';
import { API_URL } from '../core/constant';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const PrivacyPolicyService = {
    getAllPrivacyPolicy,
    deletePrivacyPolicyById,
    getPrivacyPolicyById,
    updatePrivacyPolicyById,
    createPrivacyPolicy,
    getAllPrivacyPolicyName
};

function getAllPrivacyPolicy(data: any) {
    return httpCommon.post(API_URL.GET_ALL_PRIVACY_POLICY, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deletePrivacyPolicyById(id: any) {
    return httpCommon.post(API_URL.DELETE_PRIVACY_POLICY_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getPrivacyPolicyById(id: any) {
    return httpCommon.get(API_URL.GET_PRIVACY_POLICY_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function updatePrivacyPolicyById(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_PRIVACY_POLICY_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function createPrivacyPolicy(data: any) {
    return httpCommon.post(API_URL.CREATE_PRIVACY_POLICY, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllPrivacyPolicyName() {
    return httpCommon.get(API_URL.GET_ALL_PRIVACY_POLICY_NAME).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}