import axios from 'axios';
import authHeader, { Localstorage } from './auth.service';
import { BASE_URL } from './constant';
import { CONSTANT } from './static-constant';
const userInfo: any = Localstorage(CONSTANT.GET, CONSTANT.AUTH_TOKEN);
const user: any = userInfo && userInfo

export default axios.create({
  baseURL: BASE_URL,
  headers:
    user 
      ? {
        "Authorization": `${"Bearer " + user}`,
        }
      : { 'Content-type': 'application/json' },
});
