import httpCommon from "../core/http-common";
import { logoutUser } from "../core/auth.service";
import { API_URL } from "../core/constant";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";

export const levelZeroMappingService = {
  getAllLevelZeroMapping,
  deleteLevelZeroMappingById,
  getLevelZeroMappingById,
  updateLevelZeroMappingById,
  createLevelZeroMappingInfo,
};

function getAllLevelZeroMapping(data: any) {
  return httpCommon
    .post(API_URL.GET_ALL_LEVEL_ZERO_MAPPING, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: any) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function deleteLevelZeroMappingById(id: any) {
  return httpCommon
    .post(API_URL.DELETE_LEVEL_ZERO_MAPPING_BY_ID + id)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: any) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function getLevelZeroMappingById(id: any) {
  return httpCommon
    .get(API_URL.GET_LEVEL_ZERO_MAPPING_BY_ID + id)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: any) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function updateLevelZeroMappingById(id: any, data: any) {
  return httpCommon
    .post(API_URL.UPDATE_LEVEL_ZERO_MAPPING_BY_ID + id, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: any) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function createLevelZeroMappingInfo(data: any) {
  return httpCommon
    .post(API_URL.CREATE_LEVEL_ZERO_MAPPING, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: any) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}
