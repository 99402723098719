import React from "react";
import { RouteProps } from "react-router-dom";
import { dashboardPagesMenu } from "../menu";
import DashboardHeader from "../pages/_layout/_headers/DashboardHeader";
import { ROUTING_CONSTANT_SUB_PATH } from "../core/constant";

const headers: RouteProps[] = [
  { path: dashboardPagesMenu?.dashboard.path, element: <DashboardHeader /> },
  {
    path: dashboardPagesMenu?.Users?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Users?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Users?.subMenu[2]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[2]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[3]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[4]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[5]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[6]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Store?.subMenu[7]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Admin?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Admin?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Admin?.subMenu[2]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Admin?.subMenu[3]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.interests?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.interests?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Data?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Data?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Data?.subMenu[2]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Data?.subMenu[3]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Data?.subMenu[4]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.Data?.subMenu[5]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[2]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[3]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[4]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[5]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[6]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[7]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[8]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[9]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.shop?.subMenu[10]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.nuggets?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.nuggets?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  { path: dashboardPagesMenu?.order?.path, element: <DashboardHeader /> },
  {
    path: dashboardPagesMenu?.mobileScreen?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.selfTrainer?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.selfTrainer?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.settings?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.settings?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.selfTrainer?.subMenu[2]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.selfTrainer?.subMenu[3]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.selfTrainer?.subMenu[4]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.selfTrainer?.subMenu[5]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.policy?.subMenu[0]?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.policy?.subMenu[1]?.path,
    element: <DashboardHeader />,
  },
//   { path: dashboardPagesMenu?.user?.path, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.USER, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.USER_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.RECIPE_BUNDLE, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.RECIPE_BUNDLE_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.ASSESSMENT, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.ASSESSMENT_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.ASSESSMENT_QUESTION, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.ASSESSMENT_QUESTION_SLUG, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_HARDWARE_SPECIFIC_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_METRIC_SPECIFIC_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_MEDICAL_SPECIFIC_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_PROFILE_CATEGORY,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_PROFILE_CATEGORY_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_PROFILE_SUB_CATEGORY,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_PROFILE_SUB_CATEGORY_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_PROFILE_QUESTION_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_PROFILE_QUESTION,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.CATEGORY, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.CATEGORY_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.MODEL, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.MODEL_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.PRIVACY_POLICY, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.PRIVACY_POLICY_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.TERMS_OF_USE_POLICY, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.TERMS_OF_USE_POLICY_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.NUGGETS, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.NUGGETS_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.NUGGETS_TAG, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.NUGGETS_TAG_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.RECOMMENDATION_RULE, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.RECOMMENDATION_RULE_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.RECIPE, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.RECIPE,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.SUB_CATEGORY,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.SUB_CATEGORY_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.PRODUCT,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.PRODUCT_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.SKU, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.SKU_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.INGREDIENT, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.INGREDIENT_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.INTERESTS, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.INTERESTS_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.INTERESTS_QUESTIONS,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.INTERESTS_QUESTIONS_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.METRICS, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.METRICS_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.MARKETING, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.MARKETING_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.DOSAGE_ASSISTANCE,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.DOSAGE_ASSISTANCE_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.BENEFIT, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.BENEFIT_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.MOBILE_SCREEN,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.MOBILE_SCREEN_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.FAQ, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.FAQ_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.KEYWORD, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.KEYWORD_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_DETAIL_SCREEN,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.USER_DETAIL_SCREEN_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.HOW_IT_WORK, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.HOW_IT_WORK_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.CLASS, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.CLASS_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.CHAPTER, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.CHAPTER_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.COURSE, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.COURSE_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.COURSE_FAQ, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.COURSE_FAQ_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.MASTER, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.MASTER_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.TRAINER, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.TRAINER_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.PROMO_CODE, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.PROMO_CODE_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.DELIVERY_OPTIONS,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.DELIVERY_OPTIONS_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.BOT, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.BOT_SLUG, element: <DashboardHeader /> },
  {
    path: ROUTING_CONSTANT_SUB_PATH.LEVEL_ONE_MAPPING,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.LEVEL_ONE_MAPPING_SLUG,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.LEVEL_ZERO_MAPPING,
    element: <DashboardHeader />,
  },
  {
    path: ROUTING_CONSTANT_SUB_PATH.LEVEL_ZERO_MAPPING_SLUG,
    element: <DashboardHeader />,
  },
  { path: ROUTING_CONSTANT_SUB_PATH.GOAL, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.GOAL_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.LAB_TEST, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.LAB_TEST_SLUG, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.DEVICE, element: <DashboardHeader /> },
  { path: ROUTING_CONSTANT_SUB_PATH.DEVICE_SLUG, element: <DashboardHeader /> },
  {
    path: dashboardPagesMenu?.deliveryOptions?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.bot?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.chatGpt?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.promoCode?.path,
    element: <DashboardHeader />,
  },
  {
    path: dashboardPagesMenu?.roles_permission?.path,
    element: <DashboardHeader />,
  },
];

export default headers;