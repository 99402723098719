import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Select from "react-select";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";
import { BotService } from "../../../services/bot.service";
import { levelOneMappingService } from "../../../services/levelOneMapping.service";

const AddEditLevelOneMapping = () => {
    const { setLevelOneMappingData, levelOneMappingData,setMetricData,metricData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [LevelOneMappingId, setLevelOneMappingId] = useState("");
    const [selectedMetricName, setSelectedMetricName] = useState([]);
    const [selectedMetricId, setSelectedMetricId] = useState<any>([]);
    const [selectedMetricIdArr, setSelectedMetricIdArr] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [LevelOneMappingAllData, setLevelOneMappingAllData] = useState({
        metricName: "",
        metricUserFacingName:"",
        metricId: "",
    });

    const validationSchema = yup.object().shape({
        metricName: yup.string().required("required"),
        metricUserFacingName:yup.string().required("required"),
    });

    const [isInvalidForm, setIsInvalidForm] = useState(false);

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...LevelOneMappingAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = LevelOneMappingId ? await levelOneMappingService.updateLevelOneMappingById(LevelOneMappingId, { ...values,metricId: selectedMetricIdArr}) : await levelOneMappingService.createLevelOneMappingInfo({ ...values,metricId: selectedMetricIdArr});
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.LEVEL_ONE_MAPPING_VIEW);
                        const LevelOneName = await BotService.getAllLevelOneName();
                        setLevelOneMappingData(LevelOneName.data?.metric);
                        const { _id, name } = data.data;

                        if (LevelOneMappingId) {
                            const existingIndex = levelOneMappingData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setLevelOneMappingData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, name };
                                    return newData;
                                });
                            }
                        }
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setLevelOneMappingId(decryptData(params.id));
                getLevelOneMappingById(decryptData(params.id));
                // setFieldsDisabled(true);
            }
        };
        if(metricData?.length === 0){
            getAllMetricName();
        }
        init();
    }, []);

    useEffect(() => {
        const data = selectedMetricName
            ? selectedMetricName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedMetricId(data);
    }, [selectedMetricName]);

    const getAllMetricName = async () => {
        try {
          let data: any = await BotService.getAllMetricName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.metric;
            setMetricData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
      };

    const getLevelOneMappingById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await levelOneMappingService.getLevelOneMappingById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setLevelOneMappingAllData(response?.data?.levelOneMapping);
                setValues({ ...response?.data?.levelOneMapping });
                const selectedMetricNames = response?.data?.levelOneMapping?.metricId.map(
                    (metric: any) => ({
                        id: metric._id,
                        label: metric.metricName,
                    })
                );
                setSelectedMetricName(selectedMetricNames);
                const metrics = response?.data?.levelOneMapping?.metricId.map((metric: any) => metric._id);
                setSelectedMetricIdArr(metrics);
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.LEVEL_ONE_MAPPING_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {LevelOneMappingId ? CONSTANT.UPDATE : CONSTANT.ADD} Level One Mapping
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Level One Mapping Name*</label>

                                <FormGroup id="metricName">
                                    <Input
                                        placeholder="Level One Mapping Name"
                                        className={`${isInvalidForm && !values.metricName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="metricName"
                                        value={values.metricName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Level One Mapping User Facing Name*</label>

                                <FormGroup id="metricUserFacingName">
                                    <Input
                                        placeholder="Level One Mapping User Facing Name"
                                        className={`${isInvalidForm && !values.metricUserFacingName
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="metricUserFacingName"
                                        value={values.metricUserFacingName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.metricUserFacingName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6" key="metric">
                                <label className="form-labels">Select Level Two Metrics*</label>

                                <FormGroup id="metric">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const metric = selectedOptions.map((metrics: any) => ({
                                                id: metrics.value,
                                                label: metrics.label,
                                            }));

                                            const metrics = selectedOptions.map((metric: any) => metric.value);
                                            setSelectedMetricIdArr(metrics);
                                            setSelectedMetricName(metric);
                                        }}
                                        options={
                                            metricData &&
                                            metricData
                                                .map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.metricName,
                                                }))
                                        }
                                        placeholder="Select Level Two Metrics"
                                        value={selectedMetricId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedMetricId.length === 0
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedMetricId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.METRIC_ID}</small>
                                    </p>
                                )}
                            </div>
                            

                        </div>

                        <div>
                            <Button
                                type="submit"
                                className={`w-25 mt-5 process-btn ${isLoading ? "disabled-button" : "bg-info text-white"
                                    }`}
                                onClick={handleSave}
                                isDisable={isLoading}
                            >
                                {LevelOneMappingId ? CONSTANT.UPDATE : CONSTANT.ADD} Level One Mapping
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditLevelOneMapping;
