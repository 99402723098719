import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import { ROUTING_CONSTANT } from "../../../core/constant";
import { BotService } from "../../../services/bot.service";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import "../User/user.scss";
import Select from "react-select";
import { decryptData } from "../../../core/auth.service";

const AddEditBot = () => {
    const { setBotData, botData,setMetricData,metricData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [botId, setBotId] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [selectedMetricName, setSelectedMetricName] = useState([]);
    const [selectedMetricId, setSelectedMetricId] = useState<any>([]);
    const [selectedMetricIdArr, setSelectedMetricIdArr] = useState<any>([]);
    const [botAllData, setBotAllData] = useState({
        botName: "",
        expertise: "",
        intro: "",
        dp: "",
        metrics: "",
        data: JSON.stringify({
            bodyMassIndex: 0,
            pushCount: 0,
            runningSpeed: 0,
            restingHeartRate: 0,
            height: ""
        }),
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setBotId(decryptData(params.id));
                getBotById(decryptData(params.id));
            }
        };
        init();
        if(metricData?.length === 0){
            getAllMetricName();
        }
    }, []);

    const getAllMetricName = async () => {
        try {
          let data: any = await BotService.getAllMetricName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.metric;
            setMetricData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
      };

      useEffect(() => {
        const data = selectedMetricName
            ? selectedMetricName.map((item: any) => ({
                value: item.id,
                label: item.label,
            }))
            : [];

        setSelectedMetricId(data);
    }, [selectedMetricName]);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        botName: yup.string().required(" required"),
        expertise: yup.string().required("required"),
        intro: yup.string().required("required"),
        dp: yup.string().required("required"),
        data: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...botAllData },
        validationSchema,
        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);

                const parsedData = JSON.parse(values.data);
                const data = botId
                    ? await BotService.updateBotById(botId, { ...values, data: parsedData,metrics: selectedMetricIdArr, })
                    : await BotService.createBot({ ...values, data: parsedData,metrics: selectedMetricIdArr });
                setIsLoading(false);
                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    action.resetForm();

                    navigate(ROUTING_CONSTANT.BOT_VIEW);

                    const { _id, botName } = data.data;

                    if (botId) {
                        const existingIndex = botData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setBotData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, botName };
                                return newData;
                            });
                        }
                    } else {
                        setBotData((prevData: any) => [...prevData, { _id, botName }]);
                    }
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    const getBotById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await BotService.getBotById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                const botData = response?.data?.bot;
                const selectedMetricNamesss = response?.data?.bot?.metrics.map(
                    (metric: any) => ({
                        id: metric._id,
                        label: metric.metricName,
                    })
                );
                setSelectedMetricName(selectedMetricNamesss);

                const metrics = response?.data?.bot?.metrics.map((metric: any) => metric._id);
                setSelectedMetricIdArr(metrics);
                setBotAllData({
                    botName: botData.botName,
                    expertise: botData.expertise,
                    intro: botData.intro,
                    dp: botData.dp,
                    metrics: botData.metrics,
                    data: botData.data, 

                });
    
                setValues({
                    botName: botData.botName,
                    expertise: botData.expertise,
                    intro: botData.intro,
                    dp: botData.dp,
                    metrics: botData.metrics,
                    data: JSON.stringify(botData.data), 
                });
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
            setIsLoading(false);
        }
    };
    

    
    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.BOT_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{botId ? CONSTANT.UPDATE : CONSTANT.ADD} Bot</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Bot Name*</label>

                                <FormGroup id="botName">
                                    <Input
                                        placeholder="Bot Name"
                                        className={`${isInvalidForm && !values.botName ? "border border-danger input-form" : "input-form"
                                            }`}
                                        name="botName"
                                        value={values.botName}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.botName && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Bot Intro*</label>

                                <FormGroup id="botIntro">
                                    <Input
                                        placeholder="Bot Intro"
                                        className={`${isInvalidForm && !values.intro ? "border border-danger input-form" : "input-form"
                                            }`}
                                        name="intro"
                                        value={values.intro}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.intro && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.INTRO}</small>
                                            </p>
                                        )}
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Bot Experties*</label>

                                <FormGroup id="expertise">
                                    <Input
                                        placeholder="Bot Expertise"
                                        className={`${isInvalidForm && !values.expertise ? "border border-danger input-form" : "input-form"
                                            }`}
                                        name="expertise"
                                        value={values.expertise}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.expertise && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Bot DP*</label>

                                <FormGroup id="dp">
                                    <Input
                                        placeholder="Bot DP"
                                        className={`${isInvalidForm && !values.dp ? "border border-danger input-form" : "input-form"
                                            }`}
                                        name="dp"
                                        value={values.dp}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.dp && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Bot Data</label>

                                <FormGroup id="data">
                                    <Textarea
                                        rows={5}
                                        placeholder="Bot Data"
                                        className={`${isInvalidForm && !values.data ? "border border-danger input-form" : "input-form"
                                            }`}
                                        name="data"
                                        value={values.data.toString()}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.data && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.REQUIRED}</small>
                                    </p>
                                )}
                            </div>
                        
                            <div className="col-sm-12 col-md-6" key="metric">
                                <label className="form-labels">Select Metrics*</label>

                                <FormGroup id="metric">
                                    <Select
                                        styles={customStyles}
                                        onChange={(selectedOptions: any) => {
                                            const metric = selectedOptions.map((metrics: any) => ({
                                                id: metrics.value,
                                                label: metrics.label,
                                            }));

                                            const metrics = selectedOptions.map((metric: any) => metric.value);
                                            setSelectedMetricIdArr(metrics);

                                            setSelectedMetricName(metric);
                                        }}
                                        options={
                                            metricData &&
                                            metricData
                                                .map((metric: any) => ({
                                                    value: metric._id,
                                                    label: metric.metricName,
                                                }))
                                        }
                                        placeholder="Select Metrics"
                                        value={selectedMetricId}
                                        isSearchable={true}
                                        isMulti
                                        className={`${isInvalidForm && selectedMetricId.length === 0
                                                ? "border border-danger"
                                                : ""
                                            }`}
                                    />
                                </FormGroup>
                                {isInvalidForm && selectedMetricId.length === 0 && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.METRIC_ID}</small>
                                    </p>
                                )}
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {botId ? CONSTANT.UPDATE : CONSTANT.ADD} Bot
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5   cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditBot;
