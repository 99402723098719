import { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    isValidVideoUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { ChapterService, ClassService } from "../../../services";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import "../User/user.scss";

const AddEditClass = () => {
    const { classData,setClassData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [classId, setClassId] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isDefault, setIsDefault] = useState<any>("false");
    const [classAllData, setClassAllData] = useState({
        classTitle: "",
        classThumbImage: "",
        classDescription: "",
        classHoverVideo: "",
        classIntroVideo: "",
        hours: "",
        minutes: "",
        seconds: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setClassId(decryptData(params.id));
                getClassById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const validationSchema = yup.object().shape({
        classTitle: yup.string().required(" required"),
        classThumbImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        classDescription: yup.string().required("required"),
        classHoverVideo: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),

        classIntroVideo: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
    });

    const { values, handleChange, handleSubmit, setValues } = useFormik({
        initialValues: { ...classAllData },
        validationSchema,

        onSubmit: async (values, action) => {
            try {
                setIsLoading(true);
                const data = classId ? await ClassService.editClass(classId,{...values}) : await ClassService.addClass({...values});
                setIsLoading(false);

                if (data && data.status === CONSTANT.SUCCESS) {
                    toast.success(data.message);
                    const ClassName = await ChapterService.getAllClassName();
                    setClassData(ClassName.data?.classes);
                    action.resetForm();

                    navigate(ROUTING_CONSTANT.CLASS_VIEW);

                    const { _id, className } = data.data;

                    if (classId) {
                        const existingIndex = classData.findIndex((item: any) => item._id === _id);
                        if (existingIndex !== -1) {
                            setClassData((prevData: any) => {
                                const newData = [...prevData];
                                newData[existingIndex] = { _id, className };
                                return newData;
                            });
                        }
                    } 
                } else {
                    toast.error(data.message);
                }
            } catch (error: any) {
                toast.error(error?.response?.data?.error);
                setIsLoading(false);
            }
        },
    });

    const getClassById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await ClassService.getClassById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setClassAllData(response?.data?.class);
                setValues({ ...response?.data?.class });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.CLASS_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>{classId ? CONSTANT.UPDATE : CONSTANT.ADD} Class</strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Title*</label>

                                <FormGroup id="classTitle">
                                    <Input
                                        placeholder="Enter class Title"
                                        className={`${isInvalidForm && !values.classTitle
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="classTitle"
                                        value={values.classTitle}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.classTitle && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.NAME}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Thumb Image*</label>

                                <FormGroup id="classThumbImage">
                                    <Input
                                        placeholder="Enter class Thumb Image"
                                        className={`${isInvalidForm && !values.classThumbImage
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="classThumbImage"
                                        value={values.classThumbImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm &&
                                    isValidHttpUrl(values.classThumbImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.IMAGE_VALID}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Description*</label>

                                <FormGroup id="classDescription">
                                    <Input
                                        placeholder="Enter class description"
                                        className={`${isInvalidForm && !values.classDescription
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="classDescription"
                                        value={values.classDescription}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm && !values.classDescription && (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.LONG_DESCRIPTION}</small>
                                    </p>
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Hover Video*</label>

                                <FormGroup id="classImg">
                                    <Input
                                        placeholder="Class Hover Video"
                                        className={`${isInvalidForm && !values.classHoverVideo
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="classHoverVideo"
                                        value={values.classHoverVideo}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidVideoUrl(values.classHoverVideo) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_VIDEO}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Intro Video*</label>

                                <FormGroup id="classImg">
                                    <Input
                                        placeholder="Class Hover Video"
                                        className={`${isInvalidForm && !values.classIntroVideo
                                                ? "border border-danger input-form"
                                                : "input-form"
                                            }`}
                                        name="classIntroVideo"
                                        value={values.classIntroVideo}
                                        onChange={handleChange}
                                    />
                                </FormGroup>

                                {isInvalidForm &&
                                    isValidVideoUrl(values.classIntroVideo) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_VIDEO}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Hours</label>

                                <FormGroup id="classImg">
                                    <Input
                                        placeholder="Class Hours"
                                        className={`${isInvalidForm && !values.hours
                                                ? "input-form"
                                                : "input-form"
                                            }`}
                                        name="hours"
                                        type="number"
                                        value={values.hours}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Minutes</label>

                                <FormGroup id="classImg">
                                    <Input
                                        placeholder="Class Minutes"
                                        className={`${isInvalidForm && !values.minutes
                                                ? "input-form"
                                                : "input-form"
                                            }`}
                                        name="minutes"
                                        type="number"
                                        value={values.minutes}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels"> Class Seconds</label>

                                <FormGroup id="classImg">
                                    <Input
                                        placeholder="Class Seconds"
                                        className={`${isInvalidForm && !values.seconds
                                                ? "input-form"
                                                : "input-form"
                                            }`}
                                        name="seconds"
                                        type="number"
                                        value={values.seconds}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                            </div>

                            <div>
                                <Button
                                    type="submit"
                                    onClick={handleSave}
                                    isDisable={isLoading}
                                    className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                        }`}
                                >
                                    {classId ? CONSTANT.UPDATE : CONSTANT.ADD} Class
                                </Button>
                                <Button
                                    type="button"
                                    color="danger"
                                    className="w-25 mt-5 cancel-btn float-end"
                                    onClick={onCancelClick}
                                >
                                    Cancel
                                </Button>
                            </div>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditClass;
