import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { UserProfileQuestionService } from "../../../services/userQuestion.service";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import { decryptData } from "../../../core/auth.service";
import Select from "react-select";
import "../User/user.scss";

const AddEditUserQuestion = () => {
    const { setUserProfileQuestionData, userProfileQuestionData }: any = useContext(DataContext);
    const params = useParams();
    const navigate = useNavigate();
    const [UserProfileQuestionId, setUserProfileQuestionId] = useState("");
    const [selectedAnswerType, setSelectedAnswerType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [selectedQuestionName, setSelectedQuestionName] = useState("");
    const [selectedQuestionId, setSelectedQuestionId] = useState("");

    const [userProfileQuestionAllData, setUserProfileQuestionAllData] = useState({
        question: "",
        age: "",
        gender: "",
        type: "",
        answerType: "",
        followUpAnswer: "",
        followUpQuestionId: "",
        options: [{ key: "", value: "" }],
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setUserProfileQuestionId(decryptData(params.id));
                getUserProfileQuestionById(decryptData(params.id));
            }
        };
        init();
        if(userProfileQuestionData?.length === 0){
            getAllUserProfileQuestionName();
        }
    }, []);

    const getAllUserProfileQuestionName = async () => {
        try {
          let data: any = await UserProfileQuestionService.getAllUserProfileQuestionName();
          if (data && data.status === CONSTANT.SUCCESS) {
            const responseData = data?.data?.profileQuestion;
            setUserProfileQuestionData(responseData);
          } else {
            toast.error(data.message);
          }
        } catch (error: any) {
            toast.error(error?.response?.data?.message);
        }
      };

    const filteredOptions = userProfileQuestionData?.filter((question: any) => {
        return question._id !== UserProfileQuestionId;
    });

    const validationSchema = yup.object().shape({
        question: yup.string().required("required"),
        age: yup.string().required("required"),
        gender: yup.string().required("required"),
        type: yup.string().required("required"),
        answerType: yup.string().required("required"),
        followUpAnswer: yup.string().required("required"),
    });

    const handleAddOption = () => {
        const newOption = { key: "", value: "" };
        if (values.options.every((option) => option.key !== "" && option.value !== "")) {
            setValues((prevValues) => ({
                ...prevValues,
                options: [...prevValues.options, newOption],
            }));
        } else {
            toast.error(CONSTANT.VALID_OPTIONS);
        }
    };

    const handleRemoveOption = (index: any) => {
        setValues((prevValues) => {
            const updatedOptions = [...prevValues.options];
            updatedOptions.splice(index, 1);
            return { ...prevValues, options: updatedOptions };
        });
    };

    const { values, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...userProfileQuestionAllData },
            validationSchema,

            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const filteredOptions = values.options.filter((option: any) => option.key !== "" && option.value !== "");
                    if (values.answerType !== 'Text' && values.answerType !== 'LongText') {
                        if (!values.options || values.options.length === 0) {
                            toast.error("Options are required.");
                            setIsLoading(false);
                            return;
                        }
                        for (const option of values.options) {
                            if (!option.key || !option.value) {
                                toast.error("Options are required.");
                                setIsLoading(false);
                                return;
                            }
                        }
                    }
                    const payload = {
                        ...values,
                        options: filteredOptions,
                        followUpQuestionId: selectedQuestionId
                    };
                    const data = UserProfileQuestionId ? await UserProfileQuestionService.editUserProfileQuestionById(UserProfileQuestionId,payload ) : await UserProfileQuestionService.addUserProfileQuestion(payload);
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const UserProfileQuestionName = await UserProfileQuestionService.getAllUserProfileQuestionName();
                        setUserProfileQuestionData(UserProfileQuestionName?.data?.profileQuestion);                
                        action.resetForm();
                        navigate(ROUTING_CONSTANT.USER_PROFILE_QUESTION_VIEW);

                        const { _id, question } = data.data;
                        if (UserProfileQuestionId) {
                            const existingIndex = userProfileQuestionData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setUserProfileQuestionData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, question };
                                    return newData;
                                });
                            }
                        } 
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getUserProfileQuestionById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await UserProfileQuestionService.getUserProfileQuestionById(id);
            setIsLoading(false);
            if (response.status === CONSTANT.SUCCESS) {
                setSelectedQuestionId(response?.data?.profileQuestion?.followUpQuestionId);
                setSelectedQuestionName(response?.data?.profileQuestion?.followUpQuestion?.question);
                setUserProfileQuestionAllData(response?.data?.profileQuestion);
                setValues({ ...response?.data?.profileQuestion });
            }
        } catch (error: any) {
            toast.error(error?.response?.data?.error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.USER_PROFILE_QUESTION_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };
    function style() {
        return {
            control: (baseStyles: any) => ({
                ...baseStyles,
                borderRadius: "10px",
            }),
        };
    }

    const customStyles = style();

    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;

    return (
        <>
            <div className="page container-xxl align-items-center">
                {isLoading && (
                    <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                        <Loader />
                    </div>
                )}
                <Card className="w-100">
                    <CardBody>
                        <h3 className="text-center mt-3 mb-5 text-primary">
                            <strong>{UserProfileQuestionId ? CONSTANT.UPDATE : CONSTANT.ADD} User Profile Question</strong>
                        </h3>
                        <form onSubmit={handleSubmit}>
                            <div className="row g-4">
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels"> Question*</label>

                                    <FormGroup id="question">
                                        <Input
                                            placeholder="Question Name"
                                            className={`${isInvalidForm && !values.question
                                                ? "border border-danger input-form"
                                                : "input-form"
                                                }`}
                                            name="question"
                                            value={values.question}
                                            onChange={handleChange}
                                        />
                                    </FormGroup>
                                    {isInvalidForm && !values.question && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.NAME}</small>
                                        </p>
                                    )}
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels">Age*</label>

                                    <FormGroup id="age">
                                        <select
                                            className={`${isInvalidForm && !values.age ? "border border-danger form-select form-selet-lg input-form" : "input-form form-select form-selet-lg"}`}
                                            name="age"
                                            value={values.age}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select Age</option>
                                            <option value="Default">Default</option>
                                            <option value="5-15">5-15</option>
                                            <option value="15-25">15-25</option>
                                            <option value="25-35">25-35</option>
                                            <option value="35-45">35-45</option>
                                            <option value="45-60">45-60</option>
                                            <option value="Above60">Above60</option>
                                        </select>
                                        <div>
                                            {isInvalidForm && !values.age && (
                                                <p className="text-danger ms-2">
                                                    <small>{CONSTANT.AGE}</small>
                                                </p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels">Gender*</label>

                                    <FormGroup id="gender">
                                        <select
                                            className={`${isInvalidForm && !values.gender ? "border border-danger form-select form-selet-lg input-form" : "input-form form-select form-selet-lg"}`}
                                            name="gender"
                                            value={values.gender}
                                            onChange={handleChange}
                                            placeholder="Select Gender"
                                        >
                                            <option value="" disabled>Select Gender</option>
                                            <option value="Default">Default</option>
                                            <option value="Male">Male</option>
                                            <option value="Female">Female</option>

                                        </select>
                                        <div>
                                            {isInvalidForm && !values.gender && (
                                                <p className="text-danger ms-2">
                                                    <small>{CONSTANT.GENDER}</small>
                                                </p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels">Type*</label>

                                    <FormGroup id="type">
                                        <select
                                            className={`${isInvalidForm && !values.type ? "border border-danger form-select form-selet-lg input-form" : "input-form form-select form-selet-lg"}`}
                                            name="type"
                                            value={values.type}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select Type</option>
                                            <option value="single">single</option>
                                            <option value="multi">multi</option>
                                        </select>
                                        <div>
                                            {isInvalidForm && !values.type && (
                                                <p className="text-danger ms-2">
                                                    <small>{CONSTANT.TYPE}</small>
                                                </p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels">Answer Type*</label>

                                    <FormGroup id="answerType">
                                        <select
                                            className={`${isInvalidForm && !values.answerType ? "border border-danger form-select form-selet-lg input-form" : "form-select form-selet-lg input-form"}`}
                                            name="answerType"
                                            value={values.answerType}
                                            onChange={(e) => {
                                                setSelectedAnswerType(e.target.value);
                                                handleChange(e);
                                            }}
                                        >
                                            <option value="" disabled>Select Answer Type</option>
                                            <option value="Dropdown">Dropdown</option>
                                            <option value="Radio">Radio</option>
                                            <option value="Checkbox">Checkbox</option>
                                            <option value="Text">Text</option>
                                            <option value="LongText">LongText</option>
                                        </select>
                                        <div>
                                            {isInvalidForm && !values.answerType && (
                                                <p className="text-danger ms-2">
                                                    <small>{CONSTANT.TYPE}</small>
                                                </p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <label className="form-labels">Follow-up Answer*</label>

                                    <FormGroup id="followUpAnswer">
                                        <select
                                            className={`${isInvalidForm && !values.followUpAnswer ? "border border-danger form-select form-selet-lg input-form" : "form-select form-selet-lg input-form"}`}
                                            name="followUpAnswer"
                                            value={values.followUpAnswer}
                                            onChange={handleChange}
                                        >
                                            <option value="" disabled>Select Follow-up Answer</option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                        </select>
                                        <div>
                                            {isInvalidForm && !values.followUpAnswer && (
                                                <p className="text-danger ms-2">
                                                    <small>{CONSTANT.FOLLOW_UP_ANSWER}</small>
                                                </p>
                                            )}
                                        </div>
                                    </FormGroup>
                                </div>
                                <div className="col-sm-12 col-md-6" key="questions">
                                    <label className="form-labels">Select Follow-up Question*</label>
                                    <FormGroup id="followupquestion">
                                        <Select
                                            styles={customStyles}
                                            onChange={(selectedOption: any) => {
                                                setSelectedQuestionId(
                                                    selectedOption ? selectedOption.value : ""
                                                );
                                                setSelectedQuestionName(
                                                    selectedOption ? selectedOption.label : ""
                                                );
                                            }}
                                            options={filteredOptions?.map((questions: any) => ({
                                                value: questions._id,
                                                label: questions.question,
                                            }))}
                                            placeholder="Select Follow-up Question"
                                            value={
                                                selectedQuestionName
                                                    ? {
                                                        value: selectedQuestionId,
                                                        label: selectedQuestionName,
                                                    }
                                                    : null
                                            }
                                            className={`${isInvalidForm && !selectedQuestionName
                                                ? "border"
                                                : ""
                                                }`}
                                            isSearchable={true}
                                        />
                                    </FormGroup>
                                </div>

                                <div className="col-sm-12 col-md-6">
                                    <FormGroup id="options">
                                        <>
                                            {values.answerType !== "Text" && values.answerType !== "LongText" && (
                                                <div>
                                                    {selectedAnswerType !== "Text" && selectedAnswerType !== "LongText" && (
                                                        <><label className="form-labels">Option*</label><svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            fill="currentColor"
                                                            className="bi bi-plus-circle sku-icon cursor-pointer"
                                                            viewBox="0 0 16 16"
                                                            onClick={handleAddOption}
                                                        >
                                                            <path
                                                                d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path
                                                                d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                        </svg></>
                                                    )}
                                                </div>
                                            )}
                                        </>
                                        <div>
                                            {values.answerType !== "Text" && values.answerType !== "LongText" && (
                                                values?.options?.map((option, index) => (
                                                    <div key={index} className="">
                                                        <div className="d-flex gap-2">
                                                            <Input
                                                                placeholder="Option Name"
                                                                className={`${isInvalidForm && !option.key ? "border border-danger input-form" : "input-form"}`}
                                                                value={option.key}
                                                                name={`options[${index}].key`}
                                                                onChange={handleChange} />
                                                            <Input
                                                                placeholder="Option Value"
                                                                className={`${isInvalidForm && !option.value ? "border border-danger input-form" : "input-form"}`}
                                                                value={option.value}
                                                                name={`options[${index}].value`}
                                                                onChange={handleChange} />
                                                            {option.key !== "" && option.value !== "" && (
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    fill="currentColor"
                                                                    className="bi bi-dash-circle sku-icon cursor-pointer"
                                                                    viewBox="0 0 16 16"
                                                                    onClick={() => handleRemoveOption(index)}
                                                                >
                                                                    <path
                                                                        fillRule="evenodd"
                                                                        d="M8 1a7 7 0 1 0 0 14A7 7 0 0 0 8 1zm-3.5 7a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7a.5.5 0 0 1-.5-.5z" />
                                                                </svg>)}
                                                        </div>
                                                    </div>
                                                ))
                                            )}

                                        </div>
                                    </FormGroup>
                                    {isInvalidForm && values.options.length === 0 && values.answerType !== "Text" && values.answerType !== "LongText" && (
                                        <p className="text-danger ms-2">
                                            <small>{CONSTANT.OPTION}</small>
                                        </p>
                                    )}
                                </div>


                                <div>
                                    <Button
                                        type="submit"
                                        onClick={handleSave}
                                        isDisable={isLoading}
                                        className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                            }`}
                                    >
                                        {UserProfileQuestionId ? CONSTANT.UPDATE : CONSTANT.ADD} User Profile Question
                                    </Button>
                                    <Button
                                        type="button"
                                        color="danger"
                                        className="w-25 mt-5 cancel-btn float-end"
                                        onClick={onCancelClick}
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </div>
                        </form>
                    </CardBody>
                </Card>
            </div>
        </>
    );
};

export default AddEditUserQuestion;
