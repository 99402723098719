import httpCommon from "../core/http-common";
import { API_URL } from "../core/constant";
import { logoutUser } from "../core/auth.service";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";

export const DosageAssistanceService = {
    addDosageAssistance,
    EditDosageAssistance,
    getDosageAssistanceById,
    getAllDosageAssistance,
    deleteDosageAssistanceById,
};

function addDosageAssistance(data: any) {
    return httpCommon
        .post(API_URL.CREATE_DOSAGE_ASSISTANCE, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function EditDosageAssistance(id: any, data: any) {
    return httpCommon
        .post(API_URL.UPDATE_DOSAGE_ASSISTANCE_BY_ID + id, data)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getDosageAssistanceById(id: any) {
    return httpCommon
        .get(API_URL.GET_DOSAGE_ASSISTANCE_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}

function getAllDosageAssistance(data: any) {
    let url = API_URL.GET_ALL_DOSAGE_ASSISTANCE;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deleteDosageAssistanceById(id: any) {
    return httpCommon
        .post(API_URL.DELETE_DOSAGE_ASSISTANCE_BY_ID + id)
        .then((response: any) => {
            if (response.statusText !== "OK") {
                const error = response.statusText;
                return Promise.reject(error);
            }
            return response.data;
        }).catch((error: any) => {
            if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
                logoutUser({ error });
                toast.error(error.response.data.error);
            } else {
                return Promise.reject(error);
            }
        });
}
