import httpCommon from '../core/http-common';
import { API_URL } from '../core/constant';
import { toast } from "react-toastify";
import { logoutUser } from '../core/auth.service';
import { CONSTANT } from '../core/static-constant';

export const PromocodeService = {
    createPromoCode,
    updatePromoCodeDetails,
    getAllPromoCodes,
    getPromoCodeDetailsById,
    deletePromoCodeById
};

function createPromoCode(data: any) {
    return httpCommon.post(API_URL.CREATE_PROMO_CODES, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function updatePromoCodeDetails(id: any, data: any) {
    return httpCommon.put(API_URL.UPDATE_PROMO_CODE_DETAILS + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getPromoCodeDetailsById(id: any) {
    return httpCommon.get(API_URL.GET_PROMO_CODE_DETAILS_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function getAllPromoCodes(data: any) {
    let url = API_URL.GET_ALL_PROMO_CODES;
    return httpCommon.post(url, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

function deletePromoCodeById(id: any) {
    return httpCommon.post(API_URL.DELETE_PROMO_CODE_BY_ID + id).then((response: any) => {
        if (response.statusText !== "OK") {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

