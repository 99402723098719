import httpCommon from '../core/http-common';
import { API_URL } from '../core/constant';
import { logoutUser } from '../core/auth.service';
import { toast } from 'react-toastify';
import { CONSTANT } from '../core/static-constant';

export const CourseService = {
    getAllCourses,
    deleteCourseById,
    getCourseById,
    editCourse,
    addCourse,
    getAllTrainerName,
    getAllChapterName,
    getAllCourseByName
    // getChapterByCourseId,
};

async function getAllCourses(data: any) {
    return httpCommon.post(API_URL.GET_ALL_COURSE, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function deleteCourseById(id: any) {
    return httpCommon.get(API_URL.DELETE_COURSE_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function getCourseById(id: any) {
    return httpCommon.get(API_URL.GET_COURSE_BY_ID + id).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function editCourse(id: any, data: any) {
    return httpCommon.post(API_URL.UPDATE_COURSE_BY_ID + id, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function addCourse(data: any) {
    return httpCommon.post(API_URL.ADD_COURSE, data).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

async function getAllTrainerName() {
    return httpCommon.get(API_URL.GET_ALL_TRAINERS).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}
async function getAllChapterName() {
    return httpCommon.get(API_URL.GET_ALL_CHAPTER).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}


function getAllCourseByName() {
    return httpCommon.get(API_URL.GET_ALL_COURSE).then((response: any) => {
        if (response.statusText !== 'OK') {
            const error = response.statusText;
            return Promise.reject(error);
        }
        return response.data;
    }).catch((error: any) => {
        if (error.response.data.success === CONSTANT.FAIL && error.response.status === 403) {
            logoutUser({ error });
            toast.error(error.response.data.error);
        } else {
            return Promise.reject(error);
        }
    });
}

// async function getChapterByCourseId(courseId: any) {
//     return httpCommon.get(API_URL.GET_CHAPTER_BY_COURSE_ID + courseId).then((response) => {
//         if (response.statusText !== 'OK') {
//             const error = response.statusText;
//             return Promise.reject(error);
//         }
//         return response.data;
//     });
// }

