import React, { FC, useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import classNames from "classnames";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import Page from "../../../layout/Page/Page";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Button from "../../../components/bootstrap/Button";
import useDarkMode from "../../../hooks/useDarkMode";
import AuthContext from "../../../contexts/authContext";
import httpCommon from "../../../core/http-common";
import { toast } from "react-toastify";
import { UserService } from "../../../services/user.service";
import Loader from "../../Loader/loader";
import { CONSTANT } from "../../../core/static-constant";
import { Localstorage } from "../../../core/auth.service";
interface ILoginHeaderProps {
  isNewUser?: boolean;
}
const LoginHeader: FC<ILoginHeaderProps> = () => {
  return (
    <>
      <div className="text-center h1 fw-bold mt-5">Welcome,</div>
      <div className="text-center h4 text-muted mb-5">Sign in to continue!</div>
    </>
  );
};

interface ILoginProps {
  isSignUp?: boolean;
}
const Login: FC<ILoginProps> = ({ isSignUp }) => {
  const { setUser } = useContext(AuthContext);

  const { darkModeStatus } = useDarkMode();

  const [signInPassword, setSignInPassword] = useState<boolean>(false);
  const [singUpStatus, setSingUpStatus] = useState<boolean>(!!isSignUp);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate("/"), [navigate]);

  const loginUser = async () => {
    const obj = {
      email: email,
      password: password,
      mode:CONSTANT.MODE.NORMAL

    };

    try {
      setIsLoading(true);
      const data = await UserService.loginUser(obj);
      if (data && data?.status === CONSTANT.SUCCESS) {
        Localstorage(CONSTANT.SET,CONSTANT.USER_DATA,JSON.stringify(data));
        Localstorage(CONSTANT.SET, CONSTANT.AUTH_TOKEN, data.data.token.replace("Basic ", ""));

        setTimeout(() => {
          setIsLoading(false);
          window.location.href = "/";
        }, 1000);

        toast.success(data?.message);
        console.error('data',data)
      } else {
        toast.error(data?.message);
        console.error('data',data)
        setIsLoading(false);
      }
    } catch (err:any) {
      console.error('error',err);
      toast.error(err?.response?.data?.error)
      setIsLoading(false);
    }
  };

  function handleKeyPress(event: any) {
    if (event.key === "Enter") {
      loginUser();
    }
  }

  useEffect(() => {
    setIsFormValid(password !== "" && email !== "");
  }, [password, email]);

  return (
    <PageWrapper
      isProtected={false}
      title="Login"
      className={classNames({
        "bg-dark": !singUpStatus,
        "bg-light": singUpStatus,
      })}
    >
      <Page className="p-0">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-xl-4 col-lg-6 col-md-8 shadow-3d-container">
            <Card className="shadow-3d-dark" data-tour="login-page">
              <CardBody>
                {isLoading && (
                  <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                  </div>
                )}
                <div className="text-center my-5">{/* GreenLab */}</div>
                <div
                  className={classNames("rounded-3", {
                    "bg-l10-dark": !darkModeStatus,
                    "bg-dark": darkModeStatus,
                  })}
                >
                  <div className="row row-cols-12 g-3 pb-3 px-3 mt-0">
                    <div className="col">
                      <Button
                        color={darkModeStatus ? "light" : "dark"}
                        className="rounded-1 w-100"
                        size="lg"
                      >
                        Humotron Admin
                      </Button>
                    </div>
                  </div>
                </div>
                <LoginHeader />
                <form className="row g-4">
                  <>
                    <div className="col-12">
                      <FormGroup
                        id="loginUsername"
                        isFloating
                        label="Your email or username"
                        className="mb-3"
                      >
                        <Input
                          autoComplete="email"
                          type="email"
                          value={email}
                          onChange={(e: any) => setEmail(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </FormGroup>
                      <FormGroup id="loginPassword" isFloating label="Password">
                        <Input
                          type="password"
                          autoComplete="password"
                          value={password}
                          onChange={(e: any) => setPassword(e.target.value)}
                          onKeyPress={handleKeyPress}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-12">
                      {!signInPassword ? (
                        <Button
                          className={`w-100 py-3 ${
                            isLoading || !isFormValid
                              ? "disabled-button"
                              : "bg-warning"
                          }`}
                          onClick={loginUser}
                          isDisable={isLoading || !isFormValid}
                        >
                          Continue
                        </Button>
                      ) : (
                        <Button color="warning" className="w-100 py-3">
                          Login
                        </Button>
                      )}
                    </div>
                  </>
                  {/* )} 

									{/* BEGIN :: Social Login */}
                  {/* {!signInPassword && (
										<>
											<div className='col-12 mt-3 text-center text-muted'>
												OR
											</div>
											<div className='col-12 mt-3'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomApple'
													onClick={handleOnClick}>
													Sign in with Apple
												</Button>
											</div>
											<div className='col-12'>
												<Button
													isOutline
													color={darkModeStatus ? 'light' : 'dark'}
													className={classNames('w-100 py-3', {
														'border-light': !darkModeStatus,
														'border-dark': darkModeStatus,
													})}
													icon='CustomGoogle'
													onClick={handleOnClick}>
													Continue with Google
												</Button>
											</div>
										</>
									)} */}
                  {/* END :: Social Login */}
                </form>
              </CardBody>
            </Card>
            {/* <div className='text-center'>
							<a
								href='/'
								className={classNames('text-decoration-none me-3')}>
								Privacy policy
							</a>
							<a
								href='/'
								className={classNames('link-light text-decoration-none')}>
								Terms of use
							</a>
						</div> */}
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
Login.propTypes = {
  isSignUp: PropTypes.bool,
};
Login.defaultProps = {
  isSignUp: false,
};

export default Login;
