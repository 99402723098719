import httpCommon from "../core/http-common";
import { API_URL } from "../core/constant";
import { logoutUser } from "../core/auth.service";
import { toast } from "react-toastify";
import { CONSTANT } from "../core/static-constant";

export const UserService = {
  getAllUser,
  deleteUserById,
  getUserById,
  updateUserById,
  createUser,
  registerUser,
  verifyAdminUser,
  createAdminUserPassword,
  loginUser,
  getAllMetricByUser,
  getAllUsersCronJob,
  getAllMedicalReportByUser,
  getClinicalReportByUserId,
  getMetricDetailByPdfId
};

function loginUser(data: any) {
  return httpCommon
    .post(API_URL.LOGIN_USER, { ...data })
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function getAllUser(data: any) {
  let url = API_URL.GET_ALL_USER;
  return httpCommon
    .post(url, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function getAllUsersCronJob() {
  let url = API_URL.GET_ALL_USER;
  return httpCommon
    .get(url)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function deleteUserById(id: any) {
  return httpCommon
    .post(API_URL.DELETE_USER_BY_ID + id)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function getUserById(id: any) {
  return httpCommon
    .get(API_URL.GET_USER_BY_ID + id)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function updateUserById(id: any, data: any) {
  return httpCommon
    .post(API_URL.UPDATE_USER_BY_ID + id, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function createUser(data: any) {
  return httpCommon
    .post(API_URL.CREATE_USER, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function registerUser(data: any) {
  return httpCommon
    .post(API_URL.REGISTER_USER, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function verifyAdminUser(data: any) {
  return httpCommon
    .post(API_URL.VERIFY_ADMIN_USER, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}
function createAdminUserPassword(data: any) {
  return httpCommon
    .post(API_URL.CREATE_ADMIN_USER_PASSWORD, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function getAllMetricByUser(data: any) {
  return httpCommon
    .post(API_URL.GET_ALL_METRIC_BY_USER, data)
    .then((response: any) => {
      if (response.statusText !== "OK") {
        const error = response.statusText;
        return Promise.reject(error);
      }
      return response.data;
    })
    .catch((error: any) => {
      if (
        error.response.data.success === CONSTANT.FAIL &&
        error.response.status === 403
      ) {
        logoutUser({ error });
        toast.error(error.response.data.error);
      } else {
        return Promise.reject(error);
      }
    });
}

function getAllMedicalReportByUser(data: any) {
    return httpCommon
      .post(API_URL.GET_ALL_MEDICAL_REPORT_BY_USER, data)
      .then((response: any) => {
        if (response.statusText !== "OK") {
          const error = response.statusText;
          return Promise.reject(error);
        }
        return response.data;
      })
      .catch((error: any) => {
        if (
          error.response.data.success === CONSTANT.FAIL &&
          error.response.status === 403
        ) {
          logoutUser({ error });
          toast.error(error.response.data.error);
        } else {
          return Promise.reject(error);
        }
      });
  }

  function getClinicalReportByUserId(data: any) {
    return httpCommon
      .post(API_URL.GET_CLINICAL_REPORT_BY_USER_ID, data)
      .then((response: any) => {
        if (response.statusText !== "OK") {
          const error = response.statusText;
          return Promise.reject(error);
        }
        return response.data;
      })
      .catch((error: any) => {
        if (
          error.response.data.success === CONSTANT.FAIL &&
          error.response.status === 403
        ) {
          logoutUser({ error });
          toast.error(error.response.data.error);
        } else {
          return Promise.reject(error);
        }
      });
  }

  function getMetricDetailByPdfId(data: any) {
    return httpCommon
      .post(API_URL.GET_PDF_DETAIL_BY_USER_ID, data)
      .then((response: any) => {
        if (response.statusText !== "OK") {
          const error = response.statusText;
          return Promise.reject(error);
        }
        return response.data;
      })
      .catch((error: any) => {
        if (
          error.response.data.success === CONSTANT.FAIL &&
          error.response.status === 403
        ) {
          logoutUser({ error });
          toast.error(error.response.data.error);
        } else {
          return Promise.reject(error);
        }
      });
  }