import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { CONSTANT } from "../../../core/static-constant";
import FormGroup from "../../../components/bootstrap/forms/FormGroup";
import Input from "../../../components/bootstrap/forms/Input";
import Card, { CardBody } from "../../../components/bootstrap/Card";
import Button from "../../../components/bootstrap/Button";
import {
    isValidHttpUrl,
    isValidUrl,
    ROUTING_CONSTANT,
} from "../../../core/constant";
import { howItWorkService } from "../../../services/howItWork.service";
import { useFormik } from "formik";
import * as yup from "yup";
import DataContext from "../../../contexts/dataContext";
import Loader from "../../Loader/loader";
import Textarea from "../../../components/bootstrap/forms/Textarea";
import { decryptData } from "../../../core/auth.service";
import { ProductService } from "../../../services";

const AddEditHowItWork = () => {
    const { howItWorkData, setHowItWorkData }: any = useContext(DataContext);

    const params = useParams();
    const navigate = useNavigate();
    const [howItWorkId, setHowItWorkId] = useState("");
    const [isInvalidForm, setIsInvalidForm] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const [howItWorkAllData, setHowItWorkAllData] = useState({
        productHow: "",
        productHowImage: "",
        productHelps: "",
    });

    useEffect(() => {
        const init = async () => {
            if (params && decryptData(params.id)) {
                setHowItWorkId(decryptData(params.id));
                getHowItWorkById(decryptData(params.id));
            }
        };
        init();
    }, []);

    const validationSchema = yup.object().shape({
        productHow: yup.string().required(" required"),
        productHowImage: yup
            .string()
            .matches(isValidUrl(), "required")
            .required("required"),
        productHelps: yup.string().required("required"),
    });

    const { values, errors, touched, handleChange, handleSubmit, setValues } =
        useFormik({
            initialValues: { ...howItWorkAllData },
            validationSchema,
            onSubmit: async (values, action) => {
                try {
                    setIsLoading(true);
                    const data = howItWorkId ? await howItWorkService.editHowItWork(howItWorkId, { ...values }) : await howItWorkService.addHowItWork({ ...values });
                    setIsLoading(false);

                    if (data && data.status === CONSTANT.SUCCESS) {
                        toast.success(data.message);
                        const HowItWorkName = await ProductService.getAllHowItWorkList();
                        setHowItWorkData(HowItWorkName.data?.howItWorks);

                        action.resetForm();

                        navigate(ROUTING_CONSTANT.HOW_IT_WORK_VIEW);

                        const { _id, productHow } = data.data;

                        if (howItWorkId) {
                            const existingIndex = howItWorkData.findIndex((item: any) => item._id === _id);
                            if (existingIndex !== -1) {
                                setHowItWorkData((prevData: any) => {
                                    const newData = [...prevData];
                                    newData[existingIndex] = { _id, productHow };
                                    return newData;
                                });
                            }
                        } 
                    } else {
                        toast.error(data.message);
                    }
                } catch (error: any) {
                    toast.error(error?.response?.data?.error);
                    setIsLoading(false);
                }
            },
        });

    const getHowItWorkById = async (id: any) => {
        try {
            setIsLoading(true);
            const response = await howItWorkService.getHowItWorkById(id);
            setIsLoading(false);

            if (response.status === CONSTANT.SUCCESS) {
                setHowItWorkAllData(response?.data?.howItWork);
                setValues({ ...response?.data?.howItWork });
            }
        } catch (error: any) {
            console.error(error);
            setIsLoading(false);
        }
    };

    const onCancelClick = () => {
        navigate(ROUTING_CONSTANT.HOW_IT_WORK_VIEW);
    };

    const handleSave = () => {
        setIsInvalidForm(true);
    };

    return (
        <div className="page container-xxl align-items-center">
            {isLoading && (
                <div className="absolute top-[50%] z-50 left-[50%]  transform translate-x-[-50%] translate-y-[-50%]">
                    <Loader />
                </div>
            )}
            <Card className="w-100">
                <CardBody>
                    <h3 className="text-center mt-3 mb-5 text-primary">
                        <strong>
                            {howItWorkId ? CONSTANT.UPDATE : CONSTANT.ADD} How It Work
                        </strong>
                    </h3>
                    <form onSubmit={handleSubmit}>
                        <div className="row g-4">
                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">How To Use*</label>

                                <FormGroup id="shortDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for product how to use"
                                        className={`${isInvalidForm && !values.productHow
                                            ? "border border-danger "
                                            : ""
                                            }`}
                                        name="productHow"
                                        value={values.productHow}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.productHow && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.HOW_ITS_WORK}</small>
                                            </p>
                                        )}
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">How It Works*</label>

                                <FormGroup id="shortDescription">
                                    <Textarea
                                        rows={5}
                                        placeholder="Enter HTML content for product how it work"
                                        className={`${isInvalidForm && !values.productHelps
                                            ? "border border-danger "
                                            : ""
                                            }`}
                                        name="productHelps"
                                        value={values.productHelps}
                                        onChange={handleChange}
                                    />
                                    <div>
                                        {isInvalidForm && !values.productHelps && (
                                            <p className="text-danger ms-2">
                                                <small>{CONSTANT.HELP}</small>
                                            </p>
                                        )}
                                        <a
                                            href="https://codebeautify.org/htmlviewer"
                                            target="_blank"
                                        >
                                            HTML Viewer
                                        </a>
                                    </div>
                                </FormGroup>
                            </div>

                            <div className="col-sm-12 col-md-6">
                                <label className="form-labels">Product How Image*</label>

                                <FormGroup id="ProductHowImage">
                                    <Input
                                        placeholder="Product How Image URL"
                                        className={`${isInvalidForm && !values.productHowImage
                                            ? "border border-danger input-form"
                                            : "input-form"
                                            }`}
                                        name="productHowImage"
                                        value={values.productHowImage}
                                        onChange={handleChange}
                                    />
                                </FormGroup>
                                {isInvalidForm &&
                                    isValidHttpUrl(values.productHowImage) === false ? (
                                    <p className="text-danger ms-2">
                                        <small>{CONSTANT.VALID_IMAGE}</small>
                                    </p>
                                ) : (
                                    ""
                                )}
                            </div>
                        </div>

                        <div>
                            <Button
                                type="submit"
                                onClick={handleSave}
                                isDisable={isLoading}
                                className={`w-25 mt-5 process-btn ${isLoading ? " disabled-button" : "bg-info text-white"
                                    }`}
                            >
                                {howItWorkId ? CONSTANT.UPDATE : CONSTANT.ADD} How It Work
                            </Button>
                            <Button
                                type="button"
                                color="danger"
                                className="w-25 mt-5 cancel-btn float-end"
                                onClick={onCancelClick}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </CardBody>
            </Card>
        </div>
    );
};

export default AddEditHowItWork;
