import React, { useState, useContext, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu } from '../../components/bootstrap/Dropdown';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';
import Collapse from '../../components/bootstrap/Collapse';
import { NavigationLine } from '../Navigation/Navigation';
import Icon from '../../components/icon/Icon';
import AuthContext from '../../contexts/authContext';
import { toast } from 'react-toastify';
import { CONSTANT } from '../../core/static-constant';
import { Localstorage } from '../../core/auth.service';
import Swal, { SweetAlertOptions } from "sweetalert2";

const User = () => {
    const { userData, setUser } = useContext(AuthContext);
    const navigate = useNavigate();
    const { darkModeStatus, setDarkModeStatus } = useDarkMode();

    const [collapseStatus, setCollapseStatus] = useState<boolean>(false);

    const { t } = useTranslation(['translation', 'menu']);

    const SHOW_CONFIRM_DIALOG = (message: any, callback: any) => {
        Swal.fire({
            iconColor: "black",
            className: "alert",
            title: message,
            text: "This will end your current session.",
            icon: "question",
            showCancelButton: true,
            confirmButtonColor: "#c12424",
            cancelButtonColor: "black",
            confirmButtonText: "Yes, Logout!",
            width: "30rem",
            height: "18rem",
        } as SweetAlertOptions).then((result: any) => {
            if (result.value) {
                callback(result.value);
            } else {
                callback(false);
            }
        });
    };

    const handleClick = () => {
        SHOW_CONFIRM_DIALOG(
            CONSTANT.LOGOUT_CONFIRMATION,
            async (confirmed: any) => {
                // if (setUser) {
                //     setUser('');
                // }
                if (confirmed) {
                    Localstorage(CONSTANT.REMOVE, CONSTANT.USER_DATA)
                    Localstorage(CONSTANT.REMOVE, CONSTANT.AUTH_TOKEN)
                    navigate("/auth-pages/login");
                    toast.success(CONSTANT.LOGGED_OUT)
                }
            }
        )
    }

    // let data: any = localStorage.getItem("userData")
    // data = JSON.parse(data)

    return (
        <>
            <DropdownMenu>
                <DropdownItem>
                    <Button
                        icon='AccountBox'
                    >
                        Profile
                    </Button>
                </DropdownItem>
                {/* <DropdownItem>
					<Button
						icon={darkModeStatus ? 'DarkMode' : 'LightMode'}
						onClick={() => setDarkModeStatus(!darkModeStatus)}
						aria-label='Toggle fullscreen'>
						{darkModeStatus ? 'Dark Mode' : 'Light Mode'}
					</Button>
				</DropdownItem> */}
            </DropdownMenu>
            <Collapse isOpen={collapseStatus} className='user-menu'>
                <NavigationLine />
            </Collapse>
            <nav aria-label='aside-bottom-user-menu-2'>
                <div className='navigation'>
                    <div
                        role='presentation'
                        className='navigation-item cursor-pointer'
                        onClick={() => handleClick()}>
                        <span className='navigation-link navigation-link-pill'>
                            <span className='navigation-link-info'>
                                <Icon icon='Logout' className='navigation-icon' />
                                <span className='navigation-text'>
                                    {t('Logout') as ReactNode}
                                </span>
                            </span>
                        </span>
                    </div>
                </div>
            </nav>
        </>
    );
};

export default User;
